/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 11 */

/**
 * 所有提示
 */
const state = {
  success: false,
  money: 9999,
  experienceNum: 99999,
};

const getters = {
  // 交易 成功 失败
  success(state) {
    return state.success;
  },
  money(state) {
    return state.money;
  },
  experienceNum(state) {
    return state.experienceNum;
  },
};

const mutations = {
  success: (state, data) => {
    state.success = data;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
