<!-- nft兑换券 -->
<template>
  <div class="nftCoupon">
    <div class="nftCoupon-bg">
      <div @click="onClose()" style="position: absolute; top: 10px; right: 20px; z-index: 9999">
        <van-icon name="cross" size="25px" color="#fff" />
      </div>
      <p style="font-size: 24px; color: #cbcbcb; line-height: 32px; margin-top: 30px">输入兑换码</p>
      <van-field
        clearable
        :disabled="disabled"
        v-model="codeNum"
        maxlength="12"
        class="realname-tips-input"
        style="width: 315px; margin-top: 50px"
      />
      <van-button
        color="#6555FF"
        style="width: 315px; margin-top: 50px"
        @click="buttonIdx == 0 ? onExchange() : onBack()"
      >
        {{ buttonEnum[buttonIdx] }}
      </van-button>
    </div>
  </div>
</template>

<script>
/*  eslint-disable no-unused-vars  */
import { verificationCode } from "@/api/coupons";
import { getQueryStringByName, delay } from "@/utils/lib";

export default {
  name: "NftCoupon",
  components: {},
  props: {},
  data() {
    return {
      codeNum: "",
      disabled: false,
      buttonIdx: 0,
      buttonEnum: {
        0: "确认",
        1: "返回首页",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    async onExchange() {
      let pid = getQueryStringByName("pid");
      if (this.codeNum > 0) {
        try {
          const res = await verificationCode(
            this.$getters.userInfo.id,
            pid,
            this.codeNum,
            this.$getters.userInfo.mobile,
          );
          // this.$store.dispatch("change/hidden", "nftCoupon");
          this.disabled = true;
          this.buttonIdx = 1;

          window.transmitDataToVue({
            cmd: "rewardNFT",
            arg: { productId: pid },
          });

          delay(3).then(() => {
            const prizeType = getQueryStringByName("prizeType");
            // console.log("🚀 -> delay -> prizeType", prizeType);
            if (prizeType) {
              this.$store.dispatch("change/hidden", "rewardNFT");
              window.transmitDataToVue({
                cmd: "showAwardAndAfterAction",
                arg: [
                  {
                    actionType: "afterAction",
                    type: "prizeImage",
                    prizeType: getQueryStringByName("prizeType"),
                  },
                ],
              });
            }
          });
        } catch (error) {
          console.log("🚀 -> onExchange -> error", error);
          this.$yaojingToast(error.message, "warning");
        }
      } else {
        this.$yaojingToast("请输入兑换码", "warning");
      }
    },
    onBack() {
      this.$store.dispatch("change/hidden", "nftCoupon");
      this.$router.replace({ name: "home" });
    },
    onClose() {
      this.$store.dispatch("change/hidden", "nftCoupon");
    },
  },
  created() {
    this.disabled = false;
    this.buttonIdx = 0;
    this.codeNum = this.$route.query.rewardCode;
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.nftCoupon {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparentize($--color_primary_1, 0.2);

  ::v-deep .van-cell::after {
    border-bottom: 0;
  }
  ::v-deep .van-field__clear {
    color: $--color_text_4;
    position: absolute;
    right: 0px;
  }
  ::v-deep .van-field__control {
    color: $--color_text_1;
  }
  ::v-deep .van-field__control::-webkit-input-placeholder {
    color: $--color_text_6;
  }
}

.nftCoupon-bg {
  position: relative;
  width: 350px;
  height: 300px;
  background: transparentize(#121212, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
}

.realname-tips-input {
  //   margin: 10px 0 0 0;
  font-size: 18px;
  height: 45px;
  border: 1px solid #525252;
  //   padding-left: 30px;
  background-color: $--color_primary_1;
}
</style>
