<template>
  <div id="GameDiv">
    <div id="Cocos3dGameContainer">
      <!-- <cocos3dbtn v-if="$nodeEnv == 'development'"></cocos3dbtn> -->
      <canvas
        id="GameCanvas"
        oncontextmenu="event.preventDefault()"
        tabindex="0"
        width="100%"
        height="100%"
      ></canvas>
      <div v-if="!$getters['secne/cocosStart']" :class="$style.Cocos3dGame">
        <!-- 背景图 -->
        <img
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          :src="$landConfig[loadId].cocosBg"
        />
        <div :class="$style['load_title_img']">
          <!-- 标题图 -->
          <img
            :src="$landConfig[loadId].loadImg"
            :style="{
              width: $landConfig[loadId].isLoad ? '20%' : '100%',
              height: $landConfig[loadId].isLoad ? '20%' : '100%',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delay, getQueryStringByName } from "@/utils/lib";
import myCenterApi from "@/api/myCenter";
// import Cookies from "js-cookie";
// import cocos3dbtn from "./cocos3dbtn";
export default {
  name: "CocosWrapper",
  data() {
    return {
      queryData: {},
      progress: 0,
      isQYB: false,
      loadId: 1,
    };
  },

  methods: {
    async loadingtest() {
      window.transmitDataToVue({ cmd: "cocosReady" });
      await delay(2);
      window.transmitDataToVue({
        cmd: "loading-end",
        landId: "1",
        arg: {},
      });
    },
    // 获取浏览器参数
    urlLandId(src, name) {
      let result = src.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
      if (result == null || result.length < 1) {
        return "";
      }
      return result[1];
    },
  },
  components: {
    // cocos3dbtn,
  },
  mounted() {
    // 获取浏览器url
    const isEncode = this.urlLandId(window.location.search, "url");
    // 自动登录参数解密
    const src = myCenterApi.desDecode(isEncode);
    // 判断从哪跳转
    if (src) {
      // 自动登录过来
      const landId = this.urlLandId(src, "landId");
      this.loadId = landId || 1;
    } else {
      const landId = getQueryStringByName("landId");
      this.loadId = landId || 1;
    }

    // const qingLanID = this.$route.query.landId || this.$getters.landId || Cookies.get("landId");
    // console.log(
    //   "%c ----->-54",
    //   "font-size:13px; background:pink; color:#bf2c9f;",
    //   this.$landConfig[this.$getters.landId],
    // );
    // if (qingLanID == 538) {
    //   console.log("%c ----->-57", "font-size:13px; background:pink; color:#bf2c9f;", this.isQYB);
    //   this.isQYB = true;
    //   console.log("%c ----->-57", "font-size:13px; background:pink; color:#bf2c9f;", this.isQYB);
    // }
    // eslint-disable-next-line no-undef
    System.import(process.env.VUE_APP_COSOS_index).catch(function (err) {
      console.error(err);
    });
  },
};
</script>

<style lang="scss" scope>
#GameDiv {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;
}

#Cocos3dGameContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#GameCanvas {
  width: 100% !important;
  height: 100% !important;
  outline: none;
}
</style>

<style lang="scss" module>
.Cocos3dGame {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #000;
  // background-image: url("@/assets/tips/b_3.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  img {
    width: 100%;
    height: 100%;
    // object-fit: contain;
    // max-width: 420px;
  }
}

.load_title_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
  img {
    width: 100%;
    height: 100%;
    max-width: 420px;
    object-fit: contain;
  }
}
// .Cocos3dGameContainerBgQYB {
//   position: absolute;
//   left: 0;
//   top: 0;
//   z-index: 2;
//   width: 100%;
//   height: 100%;
//   // background-image: url("@/assets/tips/b_3.jpg");
//   background-color: #fff;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   padding: 32px;
//   img {
//     width: 20%;
//     height: 20%;
//     object-fit: contain;
//     max-width: 420px;
//   }
// }
</style>
