<!-- 照片分享 -->
<template>
  <div class="photo-share-tips">
    <div @click="onClick()" style="position: absolute; top: 10px; right: 20px; z-index: 9999">
      <van-icon name="cross" size="25px" color="#fff" />
    </div>
    <div style="position: relative; display: flex; flex-direction: column; align-items: center">
      <div class="photo-share-bg" id="shareOrigination">
        <div
          class="photo-image"
          :style="{
            'background-image': 'url(' + thumb_url + ')',
          }"
        ></div>
        <!-- <van-image
          fit="cover"
          :src="thumb_url"
          :show-loading="true"
          @load="imgLoaded()"
          style="width: 305px; height: 305px; background-color: #0d0c1d"
        >
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image> -->
        <div style="padding: 10px 15px 0 15px; display: flex; justify-content: space-between">
          <p style="font-size: 16px; color: #6555ff; margin-top: 10px">
            快来曜境元宇宙一起遨游、<br />看展吧！
          </p>
          <vue-qrcode :value="qrcode" :options="{ width: 60, margin: 1 }"></vue-qrcode>
        </div>
      </div>
      <div id="htmlCanvasWrapper" class="htmlCanvasWrapper"></div>

      <div style="position: relative; margin-top: 5px">
        <span style="font-size: 14px; color: #ffffff">{{
          $getters.desktop ? "请鼠标右键保存" : "请长按图片保存"
        }}</span>
      </div>
    </div>

    <loading-tips
      v-if="loading"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #0d0c1d;
      "
    ></loading-tips>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import imagesLoaded from "imagesloaded";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { delay, getImage } from "@/utils/lib";
import html2canvas from "html2canvas";
import LoadingTips from "@/components/tips/LoadingTips";
import _ from "lodash";

export default {
  name: "wxShareTips",
  components: { VueQrcode, LoadingTips },
  props: {},
  data() {
    return {
      thumb_url: "",
      // thumb_url: require("@/assets/shop/375-410-zw.png"), // 缩略图
      // thumb_url: require("@/assets/test/test1.png"), // 缩略图
      qrcode: process.env.VUE_APP_MAIN_BASE,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      productInfo: "shop/productInfo",
    }),
  },
  watch: {},
  methods: {
    onClick() {
      this.$store.dispatch("change/hidden", "photoShareTips");
    },
    setCanvas() {
      html2canvas(document.querySelector("#shareOrigination"), {
        width: 305,
        height: 390,
        allowTaint: false,
        useCORS: true,
        logging: true,
        backgroundColor: null,
        dpi: window.devicePixelRatio,
      }).then((canvas) => {
        const o = new Image();
        o.src = canvas.toDataURL();
        document.getElementById("htmlCanvasWrapper").appendChild(o);
        this.loading = false;
      });
    },
    async initValue() {
      this.thumb_url = this.productInfo.imagePath;

      // const n = this.productInfo.imagePath.startsWith("https://obs-helf.cucloud.cn");
      // if (n) {
      //   const str = this.productInfo.imagePath.replace(
      //     "https://obs-helf.cucloud.cn",
      //     "/shareImage",
      //   );
      //   this.thumb_url = str;
      // } else {
      // this.thumb_url = this.productInfo.imagePath;
      // }
      // await delay(1);
      imagesLoaded(".buy-image", { background: true }, (instance) => {
        delay(0.1).then(() => {
          this.setCanvas();
        });
      });
    },
  },
  created() {
    this.loading = true;
    this.initValue();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.photo-share-tips {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($color: #0d0c1d, $alpha: 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep .van-image__img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  ::v-deep .van-image__loading {
    background-color: $--color_primary_1;
  }
  ::v-deep .van-image__error {
    background-color: $--color_primary_1;
  }
}

.photo-share-bg {
  width: 305px;
  height: 390px;
  background: #0d0c1d;
  border-radius: 5px;
}

.photo-image {
  width: 305px;
  height: 305px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.htmlCanvasWrapper {
  position: absolute;
  top: 0;
  left: 0;
  ::v-deep img {
    width: 305px;
    height: 390px;
  }
}

html.mobile.landscape {
  // .wx-tips {
  //   position: absolute;
  //   top: 0;
  //   right: 65px;
  // }
}
</style>
