<!-- 弹窗提示 -->
<template>
  <div class="img-load-wrapper" ref="contentimgwrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "img-load-wrapper",
  data() {
    return {};
  },
  mounted() {
    this.$imagesLoaded(this.$refs.contentimgwrapper, () => {
      this.$emit("imgload");
    });
  },
};
</script>
