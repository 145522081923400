/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 11 */
import { shopQuestList, everydayAndNewbee } from "@/api/quest";
import sceneData from "./secne";
/**
 * 所有提示
 */
const state = {
  landQuests: [],
  doNullNumber: 0,
};

const getters = {
  landQuests: (state) => state.landQuests,
  doNullNumber: (state) => state.doNullNumber,
};

const mutations = {
  GET_LAND_QUESTS: (state, landQuests) => {
    state.landQuests = landQuests;
    state.doNullNumber = landQuests.filter((item) => {
      return item.done == 0;
    }).length;
  },
};

const actions = {
  async get_land_quests({ commit }, data) {
    try {
      // const everydayQuests = await everydayAndNewbee("everyday");
      const landQuests = await shopQuestList(sceneData.state.landId);
      commit("GET_LAND_QUESTS", landQuests || []);
      return landQuests || [];
    } catch (error) {
      commit("GET_LAND_QUESTS", []);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
