import { post } from "../utils/http";
// eslint-disable-next-line no-unused-vars
import _collection from "lodash/collection";
import { setGameAvatar, userSetAvatar } from "@/utils/cocosBridge";
import store from "../store";
import { landConfig } from "@/utils/landBaseInfo";
/**
 * getAvatarTypeList 获取装备类型
 * @param {*} phone
 * @returns
 */
export const getAvatarTypeList = () => {
  return post("/system/app/avatar/getAvatarTypeList");
};

/**
 * getAvatarList 获取装备列表
 * @param {*} params
 * @returns
 */
export const getAvatarList = (shopId = store.getters.landId) => {
  return post("/system/app/avatar/getAvatarList", { relatedShopId: shopId });
};

/**
 * getPlayerAvatar 获取用户当前的装备列表
 * @param {*} shopId
 * @returns
 */
export const getPlayerAvatar = (shopId = store.getters.landId) => {
  return post("/system/app/avatar/getPlayerAvatar", { shopId });
};

export const avatarTypeEmu = {
  hair: "发型",
  face: "脸型",
  top: "上装",
  badge: "徽章",
  misc: "道具",
  bottom: "下装",
  glass: "眼镜",
  hat: "帽子",
  necklace: "项链",
  shoes: "鞋",
  accessories: "配饰",
};

/**
 * savePlayAvatar 保存玩家Avatar信息
 * @param {*} shopId
 * @returns
 */
export const savePlayAvatar = (myAvatarId, equips = store.getters.myAvatars) => {
  return post("/system/app/avatar/insertPlayerAvatar", {
    id: myAvatarId,
    shopId: landConfig[store.getters.landId]["avatarId"],
    equips: JSON.stringify(equips),
  });
};

export const getDefaultAvatars = (avatarLists, bodyname) => {
  let result = {};
  avatarLists
    .find((item) => {
      return item.equipName == bodyname;
    })
    .defaultData.forEach((item) => {
      result[item.equipSubTypeKey] = item.equipName;
    });

  return result;
};

export const initAvatarData = () => {
  return getPlayerAvatar(landConfig[store.getters.landId]["avatarId"]).then((playerAvatar) => {
    const initavatars = playerAvatar.equips
      ? JSON.parse(playerAvatar.equips)
      : getDefaultAvatars(playerAvatar.defaults, playerAvatar.defaultBodyName);

    store.dispatch("secne/update_avatar_data", initavatars);
    setGameAvatar(initavatars);
  });
};

/**
 * 用户 个人中心的 Avatar 初始化显示
 */
export const initAvatarShowData = () => {
  getPlayerAvatar(store.getters.landId == 538 ? 538 : 1).then((playerAvatar) => {
    const initavatars = playerAvatar.equips
      ? JSON.parse(playerAvatar.equips)
      : getDefaultAvatars(playerAvatar.defaults, playerAvatar.defaultBodyName);
    userSetAvatar(initavatars);
  });
};
