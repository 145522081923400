/* jshint esversion: 6 */
import store from "../store";
import router from "../router";

export const sendToCocos = (cmd, arg = {}) => {
  return new Promise((resolve, reject) => {
    console.log("给cocos CMD发送消息：", cmd);
    console.log("给cocos ARG发送消息：", arg);
    if (window.cocosCmdHelper) {
      resolve(window.cocosCmdHelper.sendToCocos(cmd, arg));
    } else {
      reject("Cocos 内容还未加载完成");
    }
  });
};

/**
 * 开关地图
 */
export const switchMiniMap = () => {
  sendToCocos("switchMiniMap");
};
/**
 * 开启cocos渲染
 */
export const sceneEnabled = () => {
  sendToCocos("sceneEnabled");
};

/**
 * 停止cocos渲染
 */
export const sceneDisabled = () => {
  sendToCocos("sceneDisabled");
};

/**
 * 背景音乐开关
 */
export const muteBGM = (mute = true) => {
  sendToCocos("muteBGM", {
    mute: mute,
  });
};

/**
 * Avatar面板， 保存头像
 */
export const avatarShot = () => {
  sendToCocos("avatarShot", {});
};
/**
 * 游戏中设置 Avatar形象
 * @param {*} params
 */
export const setGameAvatar = (params) => {
  sendToCocos("setGameAvatar", params);
};

/**
 * 后置任务设置Avatar
 * @param {*} params
 */
export const setRewardSetGameAvatar = (params) => {
  sendToCocos("rewardSetGameAvatar", params);
};

/**
 * 后置任务 —— 山在 打开抽奖页面
 * @param {*} params
 */
export const openLuckyDraw = (landId) => {
  sendToCocos("openLuckyDraw", { landId: landId });
};

/**
 * 加载岛屿
 * @param {*} landId
 */
export const cocosLoadScene = (landId) => {
  sendToCocos("loadScene", { landId: landId });
};

/**
 * 设置cocos的渲染区域
 * @param {*} width
 * @param {*} height
 */
export const renderCocosRect = (width, height) => {
  const innerWidth = window.innerWidth;
  const innerHeight = window.innerHeight;

  const cocosWidth = store.getters.portrait ? innerWidth : innerWidth - width;
  const cocosHeight = store.getters.portrait ? innerHeight - height : innerHeight;

  sendToCocos("setRenderRect", {
    screenSize: { x: innerWidth, y: innerHeight },
    rect: { x: 0, y: 0, width: cocosWidth, height: cocosHeight },
  });
};

/**
 *  设置cocos全屏
 */
export const cocosFullScreen = () => {
  sendToCocos("setRenderRect", {
    screenSize: { x: window.innerWidth, y: window.innerHeight },
  });
};

/**
 * 显示avatr
 */
export const openAvatar = (type = store.getters.landId) => {
  sendToCocos("openAvatar", { type: type });
};

/**
 * 关闭avatr
 */
export const closeAvatar = () => {
  sendToCocos("closeAvatar", {});
};

/**
 * 装备avatr数据
 */
export const userSetAvatar = (myAvatars = store.getters.myAvatars) => {
  sendToCocos("userSetAvatar", myAvatars);
};

/**
 * 关闭分岛简介
 */
export const releaseIslandCard = () => {
  sendToCocos("releaseIslandCard", {});
};

export const deviceOrientation = (orientation) => {
  const curRouteName = router.history.current.name;
  if (curRouteName == "home") {
    if (!store.getters["change/avatar"] && store.getters.cmd != 2) {
      cocosFullScreen();
    }
  } else {
    orientation == "landscape" && cocosFullScreen();
  }
};

/**
 * 传递用户信息给Cocos
 */
export const setUserInfo = () => {
  sendToCocos("setUserInfo", {
    id: store.getters.userInfo.id,
    nickName: store.getters.userInfo.nickName,
  });
};

/**
 * 购买装备回调
 */
export const equipBought = (details) => {
  sendToCocos("equipBought", details);
};

/**
 * 空间内绑定图片
 */
export const bindSpaceImg = (params) => {
  sendToCocos("bindSpaceImg", params);
};

/**
 * 打开空间
 */
export const loadSpace = (params) => {
  sendToCocos("loadSpace", params);
};

/**
 * 删除空间画册
 * spaceId:"1",//空间模板编号
 * placeKey:"1" //画框Key
 */
export const deleteSpaceImg = (params) => {
  sendToCocos("deleteSpaceImg", params);
};

/**
 * 吊起游戏内拍照
 */
export const showGameCamera = () => {
  sendToCocos("showGameCamera");
};
