/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 11 */
import shopApi from "@/api/shop";

/**
 * 商城和商品
 */
const state = {
  // 分岛列表搜索条件
  island: {
    title: "",
    arg: {},
  },
  // 商户信息
  shopInfo: {},
  // 订单关闭时间
  orderCloseMinute: 10,
  // 商品信息
  productInfo: {},
  nftAssetId: "",
};

const getters = {
  island(state) {
    return state.island;
  },
  shopInfo(state) {
    return state.shopInfo;
  },
  orderCloseMinute(state) {
    return state.orderCloseMinute;
  },
  productInfo(state) {
    return state.productInfo;
  },
  nftAssetId(state) {
    return state.nftAssetId;
  },
};

const mutations = {
  island: (state, data) => {
    state.island = data;
  },
  shopInfo: (state, data) => {
    state.shopInfo = data;
  },
  orderCloseMinute: (state, data) => {
    state.orderCloseMinute = data;
  },
  productInfo: (state, data) => {
    state.productInfo = data;
  },
  nftAssetId: (state, data) => {
    state.nftAssetId = data;
  },
};

const actions = {
  /**
   * 分岛商品搜索条件
   * @param {*} { commit }
   * @param {*} data
   */
  setIsland({ commit }, data) {
    commit("island", data);
  },
  /**
   * rest
   * @param {*} { commit }
   * @param {*} data
   */
  reset({ commit }, data) {
    commit("island", { title: "", arg: {} });
  },
  /**
   * 订单倒计时
   * @param {*} { commit }
   * @param {*} data
   */
  getOrderCloseMinute({ commit }, data) {
    shopApi.orderCloseMinute().then((res) => {
      commit("orderCloseMinute", res);
    });
  },
  /**
   * 商城浏览量
   * @param {*} { commit }
   * @param {*} data
   */
  async shopView({ commit }, data) {
    try {
      if (data.id) await shopApi.shopView(data.id);
    } catch (error) {
      throw new Error(error);
    }
  },
  /**
   * 商品详情浏览量
   * @param {*} { commit }
   * @param {*} data
   */
  async productView({ commit }, data) {
    try {
      if (data.id) await shopApi.productView(data.id);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
