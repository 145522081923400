/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import messageApi from "@/api/message";

/**
 * 后台消息统计
 */
const state = {
  msgTotal: 0,
};

const getters = {
  msgTotal(state) {
    if (state.msgTotal > 99) return "99+";
    return state.msgTotal;
  },
};

const mutations = {
  //   userMsgList: (state, data) => {
  //     state.userMsgList = data;
  //   },
};

const actions = {
  /**
   * 统计消息数量
   * @param {*} { commit }
   * @param {*} data
   */
  async getAllMsgCount({ commit }, data) {
    try {
      const res1 = (await messageApi.messageList(1, 9999, 1, 0)) || { total: 0 };
      const res2 = (await messageApi.messageList(1, 9999, 2, 0)) || { total: 0 };
      state.msgTotal = res1.total + res2.total;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
