<!-- 任务 礼物 -->
<template>
  <reward-item
    @maskClose="handleCloseTip"
    @close="handleCloseTip"
    @tapBtn="handleCloseTip"
    :title="$getters['change/rewardFeature']['title']"
    :description="$getters['change/rewardFeature']['description']"
    :img="
      $getters['change/rewardFeature']['img'] ||
      'https://obs-helf.cucloud.cn/bucket/assets/rewardFeature.png'
    "
  ></reward-item>
</template>

<script>
import RewardItem from "./RewardItem";
import { saveFeatureFunctionData, loadFeatureFunctionData } from "@/api/land";

export default {
  name: "reward-feature",
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/rewardFeature"]) {
        this.$store.dispatch("change/hidden", "rewardFeature");
      }
    },

    handleSaveFeatureFunctionData() {
      let updateObj = {};
      updateObj[this.$getters["change/rewardFeature"]["featurekey"]] = {
        islock: false,
      };

      const updateJson = Object.assign(this.$getters["landBaseInfo/landBaseInfo"], updateObj);
      saveFeatureFunctionData(updateJson).then(() => {
        this.handleLoadFeatureFunctionData();
      });
    },

    handleLoadFeatureFunctionData() {
      loadFeatureFunctionData(this.$getters["change/rewardFeature"]["shopid"]).then((res) => {
        this.$store.dispatch("landBaseInfo/get_land_base_info", res);
      });
    },
  },
  created() {
    this.handleSaveFeatureFunctionData();
  },
  components: {
    RewardItem,
  },
};
</script>
