import { landConfig } from "@/utils/landBaseInfo";
import { post } from "../utils/http";
import store from "../store";

/**
 * 获取分岛的基本信息
 * @param {*} shopId
 * @returns
 */
export const loadFeatureFunctionData = (shopId = store.getters.landId) => {
  return post("/system/app/player/loadFeatureFunctionData", {
    shopId,
  }).then((res) => {
    return res ? JSON.parse(res.functionData) : landConfig[store.getters.landId]["feature"];
  });
};

/**
 * 保存分岛的基本信息
 * @param {*} questPublisher
 * @returns
 */
export const saveFeatureFunctionData = (functionData, shopId = store.getters.landId) => {
  return post("/system/app/player/saveFeatureFunctionData", {
    functionData: JSON.stringify(functionData),
    shopId: shopId,
  });
};
