/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 11 */
import store from "@/store";
import Cookies from "js-cookie";
const isSave = false;
/**
 * 空间
 */
const state = {
  // 选择空间类型
  spaceInfo: null,
  // 推荐关键字
  recommendationList: [],
  // 搜索历史记录
  historyList: [],
  // 自定义空间详情
  mySpaceInfo: {},
  // 图片或者数字藏品选中
  isChoose: false,
  // 创建或修改
  isCreate: false,
  // 临时封面地址
  createCoverUrl: "",
};

const getters = {
  spaceInfo(state) {
    return state.spaceInfo;
  },
  mySpaceInfo(state) {
    return state.mySpaceInfo;
  },
  isChoose(state) {
    return state.isChoose;
  },
  isCreate(state) {
    return state.isCreate;
  },
  createCoverUrl(state) {
    return state.createCoverUrl;
  },
  recommendationList(state) {
    return state.recommendationList;
  },
  historyList(state) {
    if (isSave) {
      const str = Cookies.get("yaoland-history");
      if (str != undefined) {
        const temp = str.split(",");
        if (state.historyList.length == 0) {
          state.historyList = temp;
        }
      }
    }
    return state.historyList;
  },
};

const mutations = {
  spaceInfo: (state, data) => {
    state.spaceInfo = data;
  },
  mySpaceInfo: (state, data) => {
    state.mySpaceInfo = data;
  },
  isChoose: (state, data) => {
    state.isChoose = data;
  },
  isCreate: (state, data) => {
    state.isCreate = data;
  },
  createCoverUrl: (state, data) => {
    state.createCoverUrl = data;
  },
  recommendationList: (state, data) => {
    state.recommendationList = data;
  },
  historyList: (state, data) => {
    state.historyList = data;
  },
};

const actions = {
  /**
   * reset
   * @param {*} { commit }
   * @param {*} data
   */
  reset({ commit }, data) {
    commit("spaceInfo", null);
  },
  /**
   * 实时推荐
   * @param {*} { commit }
   * @param {*} data
   */
  searchByRecommendation({ commit }, data) {
    commit("recommendationList", ["你吃了吗", "没有", "吃"]);
  },
  /**
   * 清除推荐结果
   * @param {*} { commit }
   * @param {*} data
   */
  clearRecommendation({ commit }, data) {
    commit("recommendationList", []);
  },
  /**
   * 保存历史记录
   * @param {*} param0
   * @param {*} data
   */
  saveHistory({ commit }, data) {
    if (state.historyList.length < 20 && data != "") {
      if (state.historyList.indexOf(data) != -1) {
        return;
      }
      state.historyList.unshift(data);
      if (isSave) Cookies.set("history", state.historyList, { expires: 1 });
    }
  },
  /**
   * 清楚历史记录
   * @param {*} param0
   * @param {*} data
   */
  clearHistory({ commit }, data) {
    commit("historyList", []);
    Cookies.remove("history");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
