<!-- 任务 礼物 -->
<template>
  <div :class="$style['null-fragment-tips-page']">
    <div :class="$style['null-fragment-wrapper']">
      <span :class="$style['null-fragment-close-icon']" @click="handleCloseTip">
        <van-icon name="cross" :size="14" />
      </span>
      <div :class="$style['null-fragment-title']">谢谢参与，请继续努力～</div>

      <div :class="$style['null-fragment-content']">
        <img src="@/assets/home/msg_empty.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { mapGetters } from "vuex";
import { delay } from "@/utils/lib";

export default {
  name: "giftTipsComponent",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      this.$store.dispatch("change/hidden", "getNullFragment");
    },
  },
  created() {
    // delay(1.2).then(() => {
    //   this.handleCloseTip();
    // });
  },
  mounted() {
    delay(2).then(() => {
      this.handleCloseTip();
    });
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.null-fragment-close-icon {
  width: 25px;
  height: 25px;
  padding: 5px;
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  cursor: pointer;
}

.null-fragment-tips-page {
  position: absolute;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.null-fragment-wrapper {
  position: absolute;
  width: 233px;
  height: 230px;
  background: transparentize(#121212, 0.2);
  border-radius: 5px;
  padding: 45px 0 40px 0;
  text-align: center;
  color: white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.null-fragment-title {
  font-size: 14px;
  font-family: MicrosoftYaHeiUISemibold;
  color: #ffffff;
  line-height: 19px;
}

.null-fragment-content {
  padding-top: 33px;
  img {
    width: 130px;
  }
}
</style>
