/* eslint-disable prettier/prettier */
/* jshint esversion: 9 */
// import { ERROR, LIFE_CYCLE, SOURCE, UI, WINDOWS } from "@/utils/event";
export const ERROR = {
  ERROR_NO_MEDIA: "ERROR_NO_MEDIA",
  ERROR_AUTO_PLAY: "ERROR_AUTO_PLAY",
};

export const LIFE_CYCLE = {
  LIFECYCLE_INITING: "lifecycleIniting",
  LIFECYCLE_INITED: "lifecycleInited",
  LIFECYCLE_PARSED: "lifecycleParse",
  LIFECYCLE_STOP: "lifecycleStop",
};

export const SOURCE = {
  SOURCE_UPDATED: "sourceUpdated",
};

export const UI = {
  UI_DASHBOARD_SHOW: "uiDashboardShow",
  UI_DASHBOARD_HIDE: "uiDashboardHide",
  UI_PLAY: "uiPlay",
  UI_PAUSE: "uiPause",
  UI_INIT: "uiInit",
  UI_UPDATE: "uiUpdate",
};

export const WINDOWS = {
  RESIZE: "resize",
  CLICK: "click",
  DBLCLICK: "dblclick",
  MOUSEDOWN: "mousedown",
  MOUSEMOVE: "mousemove",
  MOUSEUP: "mouseup",
  MOUSEOVER: "mouseover",
  MOUSEOUT: "mouseout",
  GLOBALOUT: "globalout",
  CONTEXTMENU: "contextmenu",
};

/**
 * 自定义事件
 * import { Event } from "@/utils/event";
 */
export const Event = {
  ...WINDOWS,
  ...ERROR,
  ...LIFE_CYCLE,
  ...SOURCE,
  ...UI,
  // hls events docs: https://github.com/video-dev/hls.js/blob/master/src/events.js
  // ...HLS_EVENTS,
  //自定义
  OPEN: "open",
  CLOSE: "close",
  RESET: "reset",
  START: "start",
  STOP: "stop",
  QUERY_GAME_DATA: "queryGameData",
  ALL_GAME_USERS: "allGameUsers",
  GAME_OVER: "gameOver",
  UPDATE_MYSPACE: "updateMySpace",
  REFRESH: "refresh",
  REFRESH_MYSPACE_COVER: "refreshMyspaceCover",
  REFRESH_MYSPACE_PHOTO: "refreshMySpacePhoto",
  REFRESH_MYSPACE_NFT: "refreshMySpaceNft",
  DELETE_PHOTO: "deletePhoto",
  //
  SERVICE_LOADING: "service_loading",
  SERVICE_ENDED: "service_ended",
  LOADING_START: "loading_start",
  LOADING_END: "loading_end",
  SELECT_CALENDAR: "selectCalendar",
  UPDATE_SWIPER: "updateSwiper",
  TOGGLE_SOUND: "toggleSound",
  VOLUME_DOWN: "volumeDown",
  VOLUME_UP: "volumeUp",
  ZOOM_IN: "zoomIn",
  ZOOM_OUT: "zoomOut",
  // PLUGIN AND CORE
  CORE_TO_MP4: "core_to_mp4",
  // media events
  PLAY: "play",
  PAUSE: "pause",
  LOADEDDATA: "loadeddata",
  CANPLAY: "canplay",
  CANPLAYTHROUGH: "canplaythrough",
  ERROR: "error",
  DURATIONCHANGE: "durationchange",
  ENDED: "ended",
  TIMEUPDATE: "timeupdate",
  LOADEDMETADATA: "loadedmetadata",
  WAITING: "waiting",
  PLAYING: "playing",
  RETRY: "retry",
  SEEKED: "seeked",
  SEEKING: "seeking",
  VOLUMECHANGE: "volumechange",
  EXIT: "exit",
  PROGRESS: "progress",
  STALLED: "stalled",
  LOADSTART: "loadstart",
  RESOLUTION_UPDATE: "resolution_update",
};

export const HLS_EVENTS = {
  // fired before MediaSource is attaching to media element - data: { media }
  MEDIA_ATTACHING: "hlsMediaAttaching",
  // fired when MediaSource has been succesfully attached to media element - data: { }
  MEDIA_ATTACHED: "hlsMediaAttached",
  // fired before detaching MediaSource from media element - data: { }
  MEDIA_DETACHING: "hlsMediaDetaching",
  // fired when MediaSource has been detached from media element - data: { }
  MEDIA_DETACHED: "hlsMediaDetached",
  // fired when we buffer is going to be reset - data: { }
  BUFFER_RESET: "hlsBufferReset",
  // fired when we know about the codecs that we need buffers for to push into - data: {tracks : { container, codec, levelCodec, initSegment, metadata }}
  BUFFER_CODECS: "hlsBufferCodecs",
  // fired when sourcebuffers have been created - data: { tracks : tracks }
  BUFFER_CREATED: "hlsBufferCreated",
  // fired when we append a segment to the buffer - data: { segment: segment object }
  BUFFER_APPENDING: "hlsBufferAppending",
  // fired when we are done with appending a media segment to the buffer - data : { parent : segment parent that triggered BUFFER_APPENDING, pending : nb of segments waiting for appending for this segment parent}
  BUFFER_APPENDED: "hlsBufferAppended",
  // fired when the stream is finished and we want to notify the media buffer that there will be no more data - data: { }
  BUFFER_EOS: "hlsBufferEos",
  // fired when the media buffer should be flushed - data { startOffset, endOffset }
  BUFFER_FLUSHING: "hlsBufferFlushing",
  // fired when the media buffer has been flushed - data: { }
  BUFFER_FLUSHED: "hlsBufferFlushed",
  // fired to signal that a manifest loading starts - data: { url : manifestURL}
  MANIFEST_LOADING: "hlsManifestLoading",
  // fired after manifest has been loaded - data: { levels : [available quality levels], audioTracks : [ available audio tracks], url : manifestURL, stats : { trequest, tfirst, tload, mtime}}
  MANIFEST_LOADED: "hlsManifestLoaded",
  // fired after manifest has been parsed - data: { levels : [available quality levels], firstLevel : index of first quality level appearing in Manifest}
  MANIFEST_PARSED: "hlsManifestParsed",
  // fired when a level switch is requested - data: { level : id of new level }
  LEVEL_SWITCHING: "hlsLevelSwitching",
  // fired when a level switch is effective - data: { level : id of new level }
  LEVEL_SWITCHED: "hlsLevelSwitched",
  // fired when a level playlist loading starts - data: { url : level URL, level : id of level being loaded}
  LEVEL_LOADING: "hlsLevelLoading",
  // fired when a level playlist loading finishes - data: { details : levelDetails object, level : id of loaded level, stats : { trequest, tfirst, tload, mtime} }
  LEVEL_LOADED: "hlsLevelLoaded",
  // fired when a level's details have been updated based on previous details, after it has been loaded - data: { details : levelDetails object, level : id of updated level }
  LEVEL_UPDATED: "hlsLevelUpdated",
  // fired when a level's PTS information has been updated after parsing a fragment - data: { details : levelDetails object, level : id of updated level, drift: PTS drift observed when parsing last fragment }
  LEVEL_PTS_UPDATED: "hlsLevelPtsUpdated",
  // fired to notify that levels have changed after removing a level - data: { levels : [available quality levels] }
  LEVELS_UPDATED: "hlsLevelsUpdated",
  // fired to notify that audio track lists has been updated - data: { audioTracks : audioTracks }
  AUDIO_TRACKS_UPDATED: "hlsAudioTracksUpdated",
  // fired when an audio track switching is requested - data: { id : audio track id }
  AUDIO_TRACK_SWITCHING: "hlsAudioTrackSwitching",
  // fired when an audio track switch actually occurs - data: { id : audio track id }
  AUDIO_TRACK_SWITCHED: "hlsAudioTrackSwitched",
  // fired when an audio track loading starts - data: { url : audio track URL, id : audio track id }
  AUDIO_TRACK_LOADING: "hlsAudioTrackLoading",
  // fired when an audio track loading finishes - data: { details : levelDetails object, id : audio track id, stats : { trequest, tfirst, tload, mtime } }
  AUDIO_TRACK_LOADED: "hlsAudioTrackLoaded",
  // fired to notify that subtitle track lists has been updated - data: { subtitleTracks : subtitleTracks }
  SUBTITLE_TRACKS_UPDATED: "hlsSubtitleTracksUpdated",
  // fired when an subtitle track switch occurs - data: { id : subtitle track id }
  SUBTITLE_TRACK_SWITCH: "hlsSubtitleTrackSwitch",
  // fired when a subtitle track loading starts - data: { url : subtitle track URL, id : subtitle track id }
  SUBTITLE_TRACK_LOADING: "hlsSubtitleTrackLoading",
  // fired when a subtitle track loading finishes - data: { details : levelDetails object, id : subtitle track id, stats : { trequest, tfirst, tload, mtime } }
  SUBTITLE_TRACK_LOADED: "hlsSubtitleTrackLoaded",
  // fired when a subtitle fragment has been processed - data: { success : boolean, frag : the processed frag }
  SUBTITLE_FRAG_PROCESSED: "hlsSubtitleFragProcessed",
  // fired when a set of VTTCues to be managed externally has been parsed - data: { type: string, track: string, cues: [ VTTCue ] }
  CUES_PARSED: "hlsCuesParsed",
  // fired when a text track to be managed externally is found - data: { tracks: [ { label: string, kind: string, default: boolean } ] }
  NON_NATIVE_TEXT_TRACKS_FOUND: "hlsNonNativeTextTracksFound",
  // fired when the first timestamp is found - data: { id : demuxer id, initPTS: initPTS, frag : fragment object }
  INIT_PTS_FOUND: "hlsInitPtsFound",
  // fired when a fragment loading starts - data: { frag : fragment object }
  FRAG_LOADING: "hlsFragLoading",
  // fired when a fragment loading is progressing - data: { frag : fragment object, { trequest, tfirst, loaded } }
  FRAG_LOAD_PROGRESS: "hlsFragLoadProgress",
  // Identifier for fragment load aborting for emergency switch down - data: { frag : fragment object }
  FRAG_LOAD_EMERGENCY_ABORTED: "hlsFragLoadEmergencyAborted",
  // fired when a fragment loading is completed - data: { frag : fragment object, payload : fragment payload, stats : { trequest, tfirst, tload, length } }
  FRAG_LOADED: "hlsFragLoaded",
  // fired when a fragment has finished decrypting - data: { id : demuxer id, frag: fragment object, payload : fragment payload, stats : { tstart, tdecrypt } }
  FRAG_DECRYPTED: "hlsFragDecrypted",
  // fired when Init Segment has been extracted from fragment - data: { id : demuxer id, frag: fragment object, moov : moov MP4 box, codecs : codecs found while parsing fragment }
  FRAG_PARSING_INIT_SEGMENT: "hlsFragParsingInitSegment",
  // fired when parsing sei text is completed - data: { id : demuxer id, frag: fragment object, samples : [ sei samples pes ] }
  FRAG_PARSING_USERDATA: "hlsFragParsingUserdata",
  // fired when parsing id3 is completed - data: { id : demuxer id, frag: fragment object, samples : [ id3 samples pes ] }
  FRAG_PARSING_METADATA: "hlsFragParsingMetadata",
  // fired when data have been extracted from fragment - data: { id : demuxer id, frag: fragment object, data1 : moof MP4 box or TS fragments, data2 : mdat MP4 box or null}
  FRAG_PARSING_DATA: "hlsFragParsingData",
  // fired when fragment parsing is completed - data: { id : demuxer id, frag: fragment object }
  FRAG_PARSED: "hlsFragParsed",
  // fired when fragment remuxed MP4 boxes have all been appended into SourceBuffer - data: { id : demuxer id, frag : fragment object, stats : { trequest, tfirst, tload, tparsed, tbuffered, length, bwEstimate } }
  FRAG_BUFFERED: "hlsFragBuffered",
  // fired when fragment matching with current media position is changing - data : { id : demuxer id, frag : fragment object }
  FRAG_CHANGED: "hlsFragChanged",
  // Identifier for a FPS drop event - data: { curentDropped, currentDecoded, totalDroppedFrames }
  FPS_DROP: "hlsFpsDrop",
  // triggered when FPS drop triggers auto level capping - data: { level, droppedlevel }
  FPS_DROP_LEVEL_CAPPING: "hlsFpsDropLevelCapping",
  // Identifier for an error event - data: { type : error type, details : error details, fatal : if true, hls.js cannot/will not try to recover, if false, hls.js will try to recover,other error specific data }
  ERROR: "hlsError",
  // fired when hls.js instance starts destroying. Different from MEDIA_DETACHED as one could want to detach and reattach a media to the instance of hls.js to handle mid-rolls for example - data: { }
  DESTROYING: "hlsDestroying",
  // fired when a decrypt key loading starts - data: { frag : fragment object }
  KEY_LOADING: "hlsKeyLoading",
  // fired when a decrypt key loading is completed - data: { frag : fragment object, payload : key payload, stats : { trequest, tfirst, tload, length } }
  KEY_LOADED: "hlsKeyLoaded",
  // fired upon stream controller state transitions - data: { previousState, nextState }
  STREAM_STATE_TRANSITION: "hlsStreamStateTransition",
  // fired when the live back buffer is reached defined by the liveBackBufferLength config option - data : { bufferEnd: number }
  LIVE_BACK_BUFFER_REACHED: "hlsLiveBackBufferReached",
};
