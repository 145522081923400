export const landConfig = {
  1: {
    id: 1,
    avatarId: 1,
    landName: "主岛", //https://www.yaoland.cn
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙一起数字藏品首发",
      desc: "各大品牌的元宇宙虚拟空间等你来探索",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {},
  },
  7: {
    id: 7,
    avatarId: 7,
    landName: "山在美术馆", //https://www.yaoland.cn/Land/?landId=7
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-山在美术馆",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/20/shanzaiicon_20220620111226A026.jpg",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      shop: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
      raffle: {
        islock: true,
      },
      avatar: {
        islock: false,
      },
      map: {
        islock: false,
      },
    },
  },
  8: {
    id: 8,
    avatarId: 1,
    landName: "隐藏·世界", //https://www.yaoland.cn/Land/?landId=8
    type: "cocos3d",
    rankId: 1, //投票ID
    shareJson: {
      title: "曜境元宇宙-隐藏·世界",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/20/WechatIMG94_20220620111337A028.jpg",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    poster: true,
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      shop: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
      program: {
        islock: false,
      },
      avatar: {
        islock: false,
      },
    },
  },
  9: {
    id: 9,
    avatarId: 1,
    landName: "有维青年", //https://www.yaoland.cn/Land/?landId=9
    type: "pano",
    url: "https://www.yaoland.net/vr/vitamin/", //"/testurl/vhall.html"
    shareJson: {
      title: "曜境元宇宙-有维青年",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/20/vitaparklogo__20220620111720A030.jpg",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    poster: true,
    feature: {
      home: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
    },
  },
  10: {
    id: 10,
    avatarId: 1,
    landName: "英国国家美术馆", //https://www.yaoland.cn/Land/?landId=10
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-英国国家美术馆",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/28/WechatIMG2910_20220628204839A074.png",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
      shop: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
    },
  },
  11: {
    id: 11,
    avatarId: 1,
    landName: "虎岛世界", //https://www.yaoland.cn/Land/?landId=11
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-虎岛世界",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/28/WechatIMG2908_20220628204818A072.jpeg",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      shop: {
        islock: false,
      },
      raffle: {
        islock: false,
      },
      avatar: {
        islock: false,
      },
      map: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
    },
  },
  12: {
    id: 12,
    avatarId: 1,
    landName: "城市第二空间", //https://www.yaoland.cn/Land/?landId=12
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙平台 — 城市第二空间岛",
      desc: "创意设计，让地铁“更有趣”！",
      img: process.env.VUE_APP_OSS_URL + "assets/city2space_20221120.jpg",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
      shop: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
    },
    open720: {
      1: {
        url: "https://c.yaoland.net/vr/xtd/index.html",
        type: "iframe",
      },
      2: {
        url: "https://c.yaoland.net/vr/sxnl/html/index.html",
        type: "iframe",
      },
    },
    openYaolive: {
      1: {
        url: "https://web3d.yaoland.net/yaolive/?roomKey=22111705111225167",
        type: "newlink",
      },
    },
  },
  13: {
    id: 13,
    avatarId: 1,
    landName: "大树夏奇妙岛", //https://www.yaoland.cn/Land/?landId=13
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-大树夏奇妙岛",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/20/dashuxia112_20220620111108A023.jpg",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      shop: {
        islock: false,
      },
      map: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
    },
  },
  14: {
    id: 14,
    avatarId: 1,
    landName: "曜HIGH", //https://www.yaoland.cn/Land/?landId=14
    type: "link",
    url: "https://shop92434186.m.youzan.com/v2/showcase/goodsfast?alias=2g0l8tkbojuoymy&detail_type=goods&kdt_id=92242018", //"/testurl/vhall.html"
    shareJson: {
      title: "曜境元宇宙-曜HIGH",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
    },
  },
  15: {
    id: 15,
    avatarId: 1,
    landName: "曜境国际会展中心",
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-曜境国际会展中心",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
      yaocoin: {
        islock: false,
      },
    },
  },
  529: {
    id: 529,
    avatarId: 1,
    landName: "王小慧艺术馆", //https://www.yaoland.cn/Land/?landId=14
    type: "link",
    url: "https://shop92434186.m.youzan.com/v2/showcase/goodsfast?alias=2g0l8tkbojuoymy&detail_type=goods&kdt_id=92242018", //"/testurl/vhall.html"
    shareJson: {
      title: "曜境元宇宙-王晓慧艺术馆",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
    },
  },
  530: {
    id: 530,
    avatarId: 1,
    landName: "一江一河", //https://www.yaoland.cn/Land/?landId=14
    type: "link",
    shareJson: {
      title: "曜境元宇宙-点亮一江一河",
      desc: "曜境 x 上海66夜生活节",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  532: {
    id: 532,
    avatarId: 1,
    landName: "世界设计之都大会", //https://www.yaoland.cn/Land/?landId=14
    type: "link",
    shareJson: {
      title: "曜境元宇宙-世界设计之都大会",
      desc: "曜境 x 世界设计之都大会",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  533: {
    id: 533,
    avatarId: 1,
    landName: "曜LIVE", //https://www.yaoland.cn/Land/?landId=14
    type: "link",
    url: "https://shop92434186.m.youzan.com/v2/showcase/goodsfast?alias=2g0l8tkbojuoymy&detail_type=goods&kdt_id=92242018", //"/testurl/vhall.html"
    shareJson: {
      title: "曜境元宇宙-曜LIVE",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  534: {
    id: 534,
    avatarId: 1,
    landName: "意大利岛", //https://www.yaoland.cn/Land/?landId=14
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-意大利",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  535: {
    id: 535,
    avatarId: 1,
    landName: "彩虹岛", //https://www.yaoland.cn/Land/?landId=14
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-彩虹岛",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      shop: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  537: {
    id: 537,
    avatarId: 537,
    landName: "笔克元宇宙", //https://www.yaoland.cn/Land/?landId=7
    type: "replaceLink",
    link: process.env.VUE_APP_PICO_URL,
    shareJson: {
      title: "曜境元宇宙-笔克元宇宙",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      avatar: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  538: {
    id: 538,
    avatarId: 538,
    landName: "青年艺术博览会", //https://www.yaoland.cn/Land/?landId=538
    type: "cocos3d",
    shareJson: {
      title: "曜境元宇宙-青年艺术博览会",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/28/WechatIMG2910_20220628204839A074.png",
    },
    feature: {
      userInfo: {
        islock: false,
      },
      thirdMy: {
        isLock: false,
      },
      quest: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/qingyibo.png"),
    cocosBg: require("@/assets/tips/thirdbg.jpg"),
    isLoad: 1,
  },
  539: {
    id: 539,
    avatarId: 1,
    landName: "世博淞园", //https://www.yaoland.cn/Land/?landId=14
    type: "link",
    shareJson: {
      title: "曜境元宇宙-世博淞园",
      desc: "曜境 x 世博淞园",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
      shop: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
  540: {
    id: 540,
    avatarId: 1,
    landName: "2023国际计算机图形学年会", //https://www.yaoland.cn/Land/?landId=538
    type: "cocos3d",
    shareJson: {
      title: "2023国际计算机图形学年会",
      desc: "Computer Graphics International 2023",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/28/WechatIMG2910_20220628204839A074.png",
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
    feature: {
      home: {
        islock: false,
      },
    },
  },
  541: {
    id: 541,
    avatarId: 537,
    landName: "元上东体",
    type: "replaceLink",
    link: process.env.VUE_APP_DONGTI_URL,
    shareJson: {
      title: "曜境元宇宙-元上东体",
      desc: "玩转数字藏品+虚拟空间",
      img: process.env.VUE_APP_OSS_URL + "system/2022/06/19/yaojinglogo_20220619231418A011.png",
    },
    feature: {
      home: {
        islock: false,
      },
      quest: {
        islock: false,
      },
      avatar: {
        islock: false,
      },
    },
    loadImg: require("@/assets/tips/c_1.png"),
    cocosBg: require("@/assets/tips/b_3.jpg"),
    isLoad: 0,
  },
};
