<!-- 下载共享 -->
<template>
  <div class="download-share-tips">
    <div @click="onClick()" style="position: absolute; top: 10px; right: 20px; z-index: 9999">
      <van-icon name="cross" size="25px" color="#fff" />
    </div>

    <div style="position: relative">
      <img src="@/assets/share/bg1.svg" alt="" />

      <div
        style="
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          top: 0;
          left: 0;
          width: 100%;
          padding: 15px 20px 0 20px;
        "
      >
        <img src="@/assets/share/logo1.png" alt="" style="width: 78px; height: 45px" />
        <p style="font-size: 16px; color: #ffffff; width: 100%; margin-top: 45px">
          复制以下链接，在浏览器内下载数字藏品源文件，建议使用PC浏览器。部分模型格式需要用专业软件打开，敬请理解。
        </p>

        <div
          style="
            width: 100%;
            height: 90px;
            background: #030308;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            padding: 0px 15px 0 15px;
          "
        >
          <p
            style="
              font-size: 14px;
              color: #8e8895;
              width: 100%;
              text-align: center;
              overflow: hidden;
              word-break: break-all;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            "
          >
            {{ url }}
          </p>
        </div>
        <van-button
          color="#6555ff"
          style="width: 220px; height: 42px; margin-top: 25px"
          @click="onCopy()"
          v-clipboard:copy="url"
          v-clipboard:success="onSuccessCopy"
          >复制</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import shopApi from "@/api/shop";

export default {
  name: "downloadShare",
  components: {},
  props: {},
  data() {
    return {
      url: "",
    };
  },
  computed: {
    ...mapGetters({
      nftAssetId: "shop/nftAssetId",
    }),
  },
  watch: {},
  methods: {
    onSuccessCopy(e) {
      this.$yaojingToast("复制成功", "success");
    },
    onClick() {
      this.$store.dispatch("change/hidden", "downloadShareTips");
    },
    onCopy() {},

    async queryAsset(id) {
      try {
        const res = await shopApi.queryAsset(id);
        if (res.meta.assetUrl[0] != null || res.meta.assetUrl[0] != "") {
          this.url = res.meta.assetUrl[0];
        } else {
          this.url = "数字藏品交易确认中";
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  created() {},
  mounted() {
    this.queryAsset(this.nftAssetId);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.download-share-tips {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($color: #0d0c1d, $alpha: 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep .van-image__loading {
    background-color: $--color_primary_1;
  }
  ::v-deep .van-image__error {
    background-color: $--color_primary_1;
  }
}
</style>
