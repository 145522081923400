<!-- 云相册 -->
<template>
  <div class="cloud-album-page">
    <empty-tips v-if="isEmpty" />
    <loading-tips v-if="loading" />
    <div
      style="position: relative; height: 100%"
      v-if="!isEmpty && !loading"
      class="animate__animated animate__fadeIn"
    >
      <content-scroll
        ref="myContentScroll"
        :pageNum="pageNum"
        :pageSize="pageSize"
        :totalPage="totalPage"
        @scrollRefresh="scrollRefresh()"
        @scrollLoad="scrollLoad()"
      >
        <div class="cloud-wrapper" v-if="!isCover">
          <template v-for="(item, index) in cloudList">
            <cloud-album-item class="thumbs cloud-images" :key="`cloud_${index}`" :value="item" />
          </template>
        </div>

        <div class="cloud-wrapper" v-if="isCover">
          <template v-for="(item, index) in cloudList">
            <space-cover-item class="thumbs cloud-images" :key="`cloud_${index}`" :value="item" />
          </template>
        </div>
      </content-scroll>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import ContentScroll from "@/components/ContentScroll";
import CloudAlbumItem from "@/components/space/thumbnail/CloudAlbumItem";
import SpaceCoverItem from "@/components/space/thumbnail/SpaceCoverItem";
import EmptyTips from "@/components/tips/EmptyTips";
import LoadingTips from "@/components/tips/LoadingTips";
import spaceApi from "@/api/space";

export default {
  name: "cloudAlbumComponent",
  components: { CloudAlbumItem, EmptyTips, LoadingTips, ContentScroll, SpaceCoverItem },
  props: {
    // 封面相册或者分岛相册
    isCover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cloudList: [],
      isEmpty: false,
      loading: false,
      pageNum: 1,
      pageSize: 12,
      totalPage: 1,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 刷新
    async scrollRefresh() {
      this.getCloudList();
    },
    // 分页
    async scrollLoad() {
      try {
        if (this.pageNum < this.totalPage) {
          this.pageNum++;
          const res = await spaceApi.getPlayerPhotoList(this.pageNum, this.pageSize);
          this.cloudList = this.cloudList.concat(res.rows);

          this.$nextTick(() => {
            if (this.pageNum >= this.totalPage) {
              try {
                this.$refs["myContentScroll"].stopPushLoad();
                this.$refs["myContentScroll"].noMore();
              } catch (error) {
                console.log("error: ", error);
              }
            }
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    // 数据
    async getCloudList() {
      try {
        this.loading = true;
        this.pageNum = 1;
        this.cloudList = [];
        const res = await spaceApi.getPlayerPhotoList(this.pageNum, this.pageSize, "");
        this.totalPage = Math.floor((res.total + this.pageSize - 1) / this.pageSize);
        this.cloudList = res.rows;
        this.loading = false;
        if (res.total == 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
          this.$nextTick(() => {
            try {
              this.$refs["myContentScroll"].checkContentHeight();
              this.$refs["myContentScroll"].imageLoadedRefreshScroll();
            } catch (error) {
              console.log("error: ", error);
            }
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  created() {
    this.getCloudList();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.cloud-album-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.cloud-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px 0px 5px;
}

.thumbs {
  width: 33.33%;
  padding: 0 5px 10px 5px;
}
</style>
