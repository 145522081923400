<!-- eslint-disable no-unused-vars -->
<!-- 263 直播 -->
<template>
  <div :class="$style['live-stream-wrapper']">
    <span :class="$style['live-stream-wrapper-close-icon']" @click="handleCloseLive">
      <img style="width: 100%" src="@/assets/land/menu/close.svg" alt="" />
    </span>
    <!-- $getters['change/liveStream263']['url'] -->
    <div data-iframe-wrapper v-if="liveurl">
      <iframe
        :src="liveurl"
        frameborder="0"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import liveMenu from "@/utils/livemenu";
import { muteBGM } from "@/utils/cocosBridge"; //sceneEnabled, sceneDisabled
export default {
  name: "live-stream-263",
  data() {
    return {
      liveurl: "",
    };
  },
  created() {
    muteBGM(true);
    // sceneDisabled();
    this.initLive();
  },
  methods: {
    handleCloseLive() {
      this.$store.dispatch("change/hidden", "liveStream263");
    },

    initLive() {
      var appId = process.env.VUE_APP_LIVE263_APPID;
      var appkey = process.env.VUE_APP_LIVE263_APPKEY;
      var sign = "";
      var signdata = "";
      var timestamp = new Date().valueOf();
      const webcast_id = liveMenu[this.$getters["change/liveStream263"]["webcast_id"]];
      const nick_name = this.$getters.userInfo.nickName;
      const user_id = this.$getters.userId;
      const third_party_remark = "vip=true";
      var data = {
        webcast_id: webcast_id,
        nick_name: nick_name,
        user_id: user_id,
        third_party_remark: third_party_remark,
        type: 0,
        timestamp: timestamp,
      };
      var data1 = Object.keys(data).sort(); //根据对象属性排序
      for (var i = 0; i < data1.length; i++) {
        var e = data1[i];
        signdata = signdata + data1[i] + data[e];
      }
      var signcode = appkey + signdata + appkey;
      //signcode="1d9036a0d8fa8a178736e0322dc64822head_img_urlhttps://livetest.263cv.net/clm/assets/pc.65c3b0eb.pngnick_namech测试人员third_party_remarkvip=truetimestamp1648884634870type0user_id98765432106webcast_id15086900804394721951d9036a0d8fa8a178736e0322dc64822"
      // eslint-disable-next-line no-undef
      sign = md5(signcode, 32);

      axios
        .post(
          "https://www.263live.net/openApi/v1/webcast/apply/url" +
            "?appId=" +
            appId +
            "&sign=" +
            sign +
            "&timestamp=" +
            timestamp,
          {
            webcast_id: webcast_id,
            nick_name: nick_name,
            user_id: user_id,
            third_party_remark: third_party_remark,
            type: 0,
          },
        )
        .then((response) => {
          console.log("%c ----->-88", "font-size:13px; background:pink; color:#bf2c9f;", response);
          // 直播连接
          // this.liveurl = response.data.url;
          this.liveurl = "https://www.fintechweek.hk/speakers";
        });
      // $.ajax(settings).done(function (response) {
      //   console.log(response);
      //   $("#ifm").attr("src", response.data.url);
      // });
    },
  },
  beforeDestroy() {
    muteBGM(false);
    // sceneEnabled();
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.live-stream-wrapper-close-icon {
  padding: 12px;
  position: fixed;
  display: inline-block;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  z-index: 99999;
  color: white;
  cursor: pointer;
  pointer-events: auto;
}

.live-stream-wrapper {
  position: absolute;
  z-index: 1000;
  background-color: #0d0c1d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  [data-iframe-wrapper] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    iframe {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      outline: none;
      border: none;
    }
  }
}
</style>
