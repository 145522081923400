import router from "../router";
import store from "../store";
import { getPlayerInfo } from "@/api/login";
import { getQueryStringByName, isIso15_4 } from "@/utils/lib";
import Cookies from "js-cookie";

isIso15_4().then((res) => {
  alert(res.msg);
});

const landId =
  getQueryStringByName("landId") || getQueryStringByName("shopId") || Cookies.get("landId") || 1;
store.dispatch("secne/update_landid", landId, { expires: 365 });

Cookies.get("yaojing_token") &&
  store.dispatch("secne/update_access_token", Cookies.get("yaojing_token"));

getQueryStringByName("loginChannelCode") &&
  Cookies.set("loginChannelCode", getQueryStringByName("loginChannelCode"), { expires: 1 });

getQueryStringByName("payChannelCode") &&
  Cookies.set("payChannelCode", getQueryStringByName("payChannelCode"), { expires: 1 });

// import { routeDatas } from "@/utils/routeData";

const whiteList = ["login", "autologin", "payment", "paySuccess", "clearCookie", "ngCollections"]; // no redirect whitelist

const initBaseInfo = async () => {
  return getPlayerInfo();
};

// 全局钩子
router.beforeEach((to, from, next) => {
  console.log(" ------------ to.fullPath -------------", to.fullPath);

  // eslint-disable-next-line no-undef
  _hmt.push(["_trackPageview", to.fullPath]);
  store.dispatch("secne/update_history_router", from.fullPath);
  window.document.documentElement.setAttribute("data-route-name", to.name);

  if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
    window.document.documentElement.style.setProperty("--app-height", window.innerHeight + "px");
  } else {
    window.document.documentElement.style.setProperty("--app-height", "100vh");
  }

  setTimeout(() => {
    if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
      window.document.documentElement.style.setProperty("--app-height", window.innerHeight + "px");
    } else {
      window.document.documentElement.style.setProperty("--app-height", "100vh");
    }
  }, 1000);

  store.dispatch("routerHistory/addHistory", to);

  if (whiteList.indexOf(to.name) > -1) {
    // 在免登陆白名单，直接进入
    next();
  } else {
    // 若是有token
    if (Cookies.get("yaojing_token")) {
      // determine if there has token
      // 登陆后进入登陆页
      if (to.name === "login") {
        next({ path: "/", replace: true });
      } else {
        if (store.getters.userId) {
          next();
        } else {
          initBaseInfo()
            .then(() => {
              // 订单倒计时
              store.dispatch("shop/getOrderCloseMinute");
              // router.addRoutes(routeDatas);
              next({ ...to, replace: true });
            })
            .catch(() => {
              next({
                path: "/Login",
                query: {
                  redirect: to.fullPath,
                },
                replace: true,
              });
            });
        }
      }
    } else {
      next({
        path: "/Login",
        query: {
          redirect: to.fullPath,
        },
        replace: true,
      });
    }
  }
});
