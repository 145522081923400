export default {
  color_primary_1: "#0D0C1D",
  color_primary_2: "#665CFF",

  color_prink: "#F521CB",
  color_red: "#FF3800",
  color_blue: "#0000FE",
  color_green: "#00FF01",
  color_orange: "#FE9636",
  color_yellow: "#FEFF01",

  color_text_1: "#FFFFFF",
  color_text_2: "#A7A7A7",
  color_text_3: "#7774A1",
  color_text_4: "#665CFF",

  color_bg_line_1: "#31303F",
  color_bg_line_2: "#665CFF",
  color_bg_line_3: "#FFFFFF",

  font_size_18: "9px",
  font_size_2: "10px",
  font_size_24: "12px",
  font_size_26: "13px",
  font_size_28: "14px",
  font_size_32: "16px",
  font_size_36: "18px",
  font_size_4: "20px",
  font_size_44: "22px",
  font_size_48: "24px",
  font_size_52: "26px",
  font_size_68: "34px",

  //老颜色-等待替换
  color_orange_1: "#FFCB5B",
  color_orange_2: "#FF9838",

  color_prink_1: "#F473FF",
  color_prink_2: "#F85BFE",

  color_blue_1: "#62DDFF",
  color_blue_2: "#40B2FF",

  color_red_1: "#FF5FB0",
  color_red_2: "#FF4288",

  color_black_1: "#C7C4CC",
  color_black_2: "#ABAAAB",
  color_black_3: "#8E8895",
  color_black_4: "#696173",
  color_black_5: "#554C5F",

  color_other_1: "#8B5CFF",
  color_other_2: "#FFFFFF",
  color_other_3: "#F1F0F3",
};
