import { easyarvrApiPost } from "../../utils/http";
import Cookies from "js-cookie";
/**
 * 点击展品时 数据埋点 参数全部必填
 * @param {params} object 参数对象
 * @param {exhibitID} int 展品ID
 * @param {exhibitName} string 展品名称
 * @param {exhibitAuthorID} int 展品对应的作者id
 * @param {exhibitAuthorName} string 展品对应的作者名字
 * @param {timestamp} string 时间戳（秒）
 * @param {signature} string signature = md5(token+timestamp)
 * @returns
 */
export const clickExhibit = (exhibitID, exhibitName, exhibitAuthorName, timestamp, signature) => {
  return easyarvrApiPost("/exhibit/click", {
    exhibitID,
    exhibitName,
    exhibitAuthorName,
    timestamp,
    signature,
    token: Cookies.get("qing_token"),
    exhibitAuthorID: "oJC8NLHzxf0=",
  });
};

/**
 * 增加任务积分 参数全部必填
 * @param {taskID} string 任务ID
 * @param {userID} string 用户ID
 * @param {points} int 积分值
 * @param {timestamp} int 时间戳 1681032827
 * @param {signature} string signature=md5(token+timestamp)
 * @param {token} string token MOxinrui
 * @returns
 */
export const getThirdPoints = (taskID, userID, points, timestamp, signature, token) => {
  return easyarvrApiPost("/task/earnPoints", {
    taskID: taskID,
    userID: userID,
    points: points,
    timestamp: timestamp,
    signature: signature,
    token: token,
  });
};

/**
 * 获取积分变动列表 参数全部必填
 * @param {userID} string 用户ID
 * @param {period} string 日期 该参数不是必填
 * @param {timestamp} int 时间戳 1681032827
 * @param {signature} string signature=md5(token+timestamp)
 * @param {token} string token MOxinrui
 * @returns
 */
export const getThirdPointList = (userID, period, timestamp, signature, token) => {
  return easyarvrApiPost("/task/points", {
    userID: userID,
    period: period,
    timestamp: timestamp,
    signature: signature,
    token: token,
  });
};
