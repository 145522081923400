import { receiveQuest } from "@/api/quest";
import { yaojingToast } from "@/components/global";
import { clearCurRewardAction } from "@/utils/taskfactory.js";
import { delay } from "@/utils/lib";
export default (params) => {
  if (params.questId) {
    receiveQuest(params.questId)
      .then(() => {
        yaojingToast("成功领取了新任务", "success");
        delay(2).then(() => {
          clearCurRewardAction();
        });
      })
      .catch(() => {
        delay(2).then(() => {
          clearCurRewardAction();
        });
      });
  } else {
    delay(2).then(() => {
      clearCurRewardAction();
    });
  }
};
