const state = {
  doNullQuestNumber: 0,
};

const getters = {
  doNullQuestNumber: (state) => state.doNullQuestNumber,
};

const mutations = {
  UPDATE_DONULL_QUEST_NUMBER: (state, number) => {
    state.doNullQuestNumber = number;
  },
};

const actions = {
  update_donull_quest_number({ commit }, number) {
    commit("UPDATE_DONULL_QUEST_NUMBER", number);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
