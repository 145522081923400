<!-- 云相册缩略图 -->
<template>
  <div class="cloud-album-item-page">
    <div class="cloud-album-item-img" @click="onClick()">
      <img src="@/assets/space/110-110.png" style="width: 100%; height: 100%" alt="" />
      <van-image
        fit="cover"
        :src="url"
        :show-loading="true"
        :style="locked ? { filter: 'brightness(30%)' } : { filter: 'brightness(100%)' }"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #0d0c1d;
        "
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>

      <div v-if="locked" class="img-lock">
        <p
          style="
            width: 100%;
            height: 20px;
            font-size: 15px;
            color: #cbcbcb;
            line-height: 20px;
            text-align: center;
          "
        >
          已选用
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import PubSub from "pubsub-js";
import { Event } from "@/utils/event";
import { MySpaceItemMixin } from "@/mixin/space/mySpaceItemMixin";

export default {
  name: "cloudAlbumItemComponent",
  mixins: [MySpaceItemMixin],
  components: {},
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      url: require("@/assets/space/110-110-zw.png"),
      locked: true,
      pubsub: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    initPubSub() {
      // 选择照片刷新，其他恢复
      this.pubsub[0] = PubSub.subscribe(Event.REFRESH_MYSPACE_PHOTO, (msg, data) => {
        this.spaceIsUsed(this.value.id);
      });
    },
    destroyPubSub() {
      this.pubsub.forEach((element) => {
        PubSub.unsubscribe(element);
      });
    },
    onClick() {
      if (this.locked) {
        this.$yaojingToast("已选用，请重新选择", "warning");
      } else {
        if (this.isChoose == false) {
          this.$yaojingConfirm({
            title: "确认更新？",
            text: "",
            cancelButtonText: "取消",
            confirmButtonText: "确定",
          })
            .then((res) => {
              this.update("photo");
              this.$store.commit("space/isChoose", true);
            })
            .catch((err) => {});
        } else {
          this.$yaojingToast("选取已更新，重新选取对象", "warning");
        }
      }
    },

    // 初始化数据
    initValue(arg) {
      if (arg.imagePath != "" && arg.imagePath != null) this.url = arg.imagePath;
      // this.url = require("@/assets/space/test4.png");

      this.spaceIsUsed(arg.id);
    },
  },
  created() {
    this.initPubSub();
  },
  mounted() {
    this.initValue(this.value);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.destroyPubSub();
  },
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.cloud-album-item-page {
  position: relative;
  width: 100%;
  height: 100%;
  // padding-bottom: 10px;
}

.cloud-album-item-img {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0;
  background-color: $--color_primary_1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ::v-deep .van-image__loading {
    background-color: $--color_primary_1;
  }
  ::v-deep .van-image__error {
    background-color: $--color_primary_1;
  }
}

.cloud-album-item-title {
  // position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  span:nth-child(1) {
    font-size: $--font_size_28;
    color: $--color_text_2;
  }
}

.img-lock {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
}
</style>
