<!-- 弹窗提示 -->
<template>
  <div :class="$style['confirm-page-wrapper']" v-if="show">
    <div data-confirm-mask></div>
    <div :class="$style['confirm-page-container']">
      <p v-if="title" style="width: 100%; font-size: 22px; color: #ffffff">{{ title }}</p>
      <p v-if="text" style="width: 100%; font-size: 14px; color: #abaaab; margin-top: 10px">
        {{ text }}
      </p>
      <div data-confirm-btnwrapper>
        <van-button
          v-if="showConfirmButton == 'show'"
          color="#665CFF"
          style="width: 100px; height: 35px; background-color: #665cff"
          @click="handleConfirm"
        >
          {{ confirmButtonText }}
        </van-button>
        <van-button
          v-if="showCancelButton == 'show'"
          color="#665CFF"
          plain
          style="width: 100px; height: 35px; background: transparent"
          @click="handleCancel"
        >
          {{ cancelButtonText }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "janiseConfirm",
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.confirm-page-wrapper {
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparentize(#0d0c1d, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  [data-confirm-mask] {
  }
}

.confirm-page-container {
  position: relative;
  min-width: 250px;
  max-width: 320px;
  padding: 25px 20px 20px 20px;
  background: #383838;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  [data-confirm-btnwrapper] {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}
</style>
