<!-- 任务 礼物 -->
<template>
  <div :class="$style['fragment-tips-page']" @click="handleCloseTip">
    <div :class="$style['fragment-wrapper']">
      <imgload @imgload="imgloaded">
        <span :class="$style['fragment-close-icon']">
          <van-icon name="cross" :size="14" />
        </span>
        <div :class="$style['fragment-title']" class="ellipsis2">
          {{ $getters["change/getFragment"]["title"] }}
        </div>

        <div :class="$style['fragment-content']">
          <div
            :class="$style['fragment-img-one']"
            v-if="$getters['change/getFragment']['fragments'].length < 2"
          >
            <div
              data-img
              v-for="(item, index) in $getters['change/getFragment']['fragments']"
              :key="'fragments' + index"
            >
              <img :src="item.img" style="width: 100%; height: 100%; object-fit: contain" alt="" />
              <p data-text>{{ item.text }}</p>
            </div>
          </div>
          <vue-scroll :ops="opstion" v-else>
            <div :class="$style['fragment-img-multi-wrapper']" data-fragment-img-multi-wrapper>
              <div
                data-img
                v-for="(item, index) in $getters['change/getFragment']['fragments']"
                :key="'fragments' + index"
              >
                <img :src="item.img" style="width: 100%; height: 100%" alt="" />
                <p data-text>{{ item.text }}</p>
              </div>
            </div>
          </vue-scroll>
        </div>

        <div
          v-if="$getters['change/getFragment']['description']"
          :class="$style['fragment-bottom']"
          class="ellipsis2"
        >
          {{ $getters["change/getFragment"]["description"] }}
        </div>
      </imgload>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { delay } from "@/utils/lib";
import imgload from "@/components/global/imgload/imgload";
export default {
  name: "giftTipsComponent",
  data() {
    return {
      opstion: {
        scrollPanel: {
          speed: 100,
          scrollingX: true,
          scrollingY: false,
        },
      },
    };
  },
  watch: {},
  methods: {
    handleCloseTip() {
      if (this.$getters["change/getFragment"]) {
        this.$store.dispatch("change/hidden", "getFragment");
      }
    },

    imgloaded() {
      delay(5).then(() => {
        this.handleCloseTip();
      });
    },
  },

  components: {
    imgload,
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.fragment-close-icon {
  width: 25px;
  height: 25px;
  padding: 5px;
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  cursor: pointer;
}

.fragment-tips-page {
  position: absolute;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &[data-type="1"] {
    .fragment-img-multi-wrapper {
      [data-text] {
        bottom: 0 !important;
        transform: translateY(10%) !important;
      }
    }
  }
}

.fragment-wrapper {
  position: absolute;
  width: 233px;
  height: 230px;
  background: transparentize(#121212, 0.2);
  border-radius: 5px;
  padding: 68px 0 40px 0;
  text-align: center;
  color: white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  :global(.__vuescroll) {
    overflow: visible !important;
  }
}

.fragment-title {
  position: absolute;
  top: 22px;
  left: 0;
  width: 100%;
  font-size: 14px;
  padding: 0 16px;
}
.fragment-bottom {
  position: absolute;
  bottom: 0;
  height: 35px;
  left: 0;
  width: 100%;
  font-size: 10px;
  padding: 0 16px;
}

.fragment-content {
  width: 100%;
  height: 100%;
}

.fragment-img-one {
  width: 203px;
  height: 203px;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-image: url("@/assets/tips/lightbg.png");
  left: 15px;
  right: 15px;
  bottom: 0px;
  [data-img] {
    font-size: 0;
    width: 116px;
    height: 90px;
    position: absolute;
    top: 43px;
    left: 50%;
    transform: translateX(-50%);
  }
  [data-text] {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    font-size: 10px;
    transform: translateY(100%);
  }
}

.fragment-img-multi-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  [data-img] {
    width: 116px;
    height: 100px;
    flex-shrink: 0;
    padding: 18px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-image: url("@/assets/tips/lightbg.png");
  }
  [data-text] {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    font-size: 10px;
    transform: translateY(100%);
  }
}

.fragment-button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 14px;
  cursor: pointer;
}
.fragment-button-text {
  height: 14px;
  font-size: 10px;
  color: #665cff;
  line-height: 14px;

  position: absolute;
  right: 12px;
  bottom: 10px;
  cursor: pointer;
}
</style>
