import store from "../store";
import { getQueryStringByName } from "@/utils/lib";
import Cookies from "js-cookie";
import { post, yaolandMiniServerPost } from "../utils/http";
import axios from "axios";
/**
 * 获取登录 验证码
 * @param {*} phone
 * @returns
 */
export const sendSms = (phone) => {
  return post("/auth/sendSms", {
    phone: phone,
  });
};

/**
 * 登录
 * @param {*} phone
 * @param {*} code
 * @returns
 */
export const appLogin = (phone, code) => {
  return post("/auth/appLogin", {
    phone,
    code,
    channelCode: getQueryStringByName("loginChannelCode") || Cookies.get("loginChannelCode") || "",
  });
};

/**
 * 获取玩家信息
 */
export const getPlayerInfo = () => {
  return post("/system/app/player/getPlayerInfo").then((res) => {
    store.dispatch("secne/set_user_info", res);
    if (!Cookies.get("yaolandphone")) {
      Cookies.set("yaolandphone", res.mobile, { expires: 31 });
    }
    // if (!Cookies.get("yaojing_miniserver_token")) {
    //   yaolandMiniServerPost("/miniServerLogin", {
    //     playerId: res.id,
    //     appKey: "O583Q4098F66P87I",
    //     appSecret: "TQGB582WCK7OX9I6X8QEDA4OY8LG420K",
    //   }).then((res2) => {
    //     Cookies.set("yaojing_miniserver_token", res2.token, { expires: 31 });
    //   });
    // }
    return res;
  });
};

/**
 * 修改玩家的头像和昵称
 * @param {id:String, nickName: String, profilePhoto:Sting} params
 * @returns
 */
export const updatePlayerInfo = (params) => {
  return post("/system/app/player/updatePlayerInfo", params);
};

/**
 * 完成首页指引
 */
export const completeHomeGuide = () => {
  return post("/system/app/player/homeGuide");
};

/**
 * 完成分岛指引
 */
export const completeLandGuide = () => {
  return post("/system/app/player/landGuide");
};

/**
 * @param {*} playerId //玩家ID
 * @returns
 */
export const yaolandMiniServerLogin = (playerId) => {
  return yaolandMiniServerPost("/miniServerLogin", {
    playerId,
    appKey: "O583Q4098F66P87I",
    appSecret: "TQGB582WCK7OX9I6X8QEDA4OY8LG420K",
  }).then((res) => {
    Cookies.set("yaojing_miniserver_token", res.token, { expires: 31 });
  });
};
/**
 * 获取小程序码
 * @param {*} phone
 * @param {*} code
 * @returns
 */
export const appWxAppQrCode = (deviceId) => {
  return axios.post(
    "https://cps.yaoland.cn:5566/esportapi/wxGenerateQrcode",
    { deviceId: deviceId },
    {
      headers: {
        "e-api-key": "59ca3b5cc98041ebb4f5f34a6fcb842b",
        "e-api-secret": "73f9d460d3fc49de8d744ef614e1eea8",
      },
    },
  );
};
