<!-- 任务 礼物 -->
<template>
  <div :class="$style['img-tips-wrapper']">
    <span :class="$style['after-action-close-icon']" @click="handleCloseTip">
      <img style="width: 100%" src="@/assets/land/menu/close.svg" alt="" />
    </span>

    <div data-img-wrapper>
      <img :src="imgurl" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "img-tips-wrapper",
  data() {
    return {};
  },
  props: {
    imgurl: {
      default: "https://obs-helf.cucloud.cn/bucket/assets/20220804181720.png",
      type: String,
    },
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/imgTipsView"]) {
        this.$store.dispatch("change/hidden", "imgTipsView");
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.after-action-close-icon {
  padding: 12px;
  position: fixed;
  display: inline-block;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  z-index: 99999;
  color: white;
  cursor: pointer;
  pointer-events: auto;
}

.img-tips-wrapper {
  position: absolute;
  z-index: 1;
  background-color: #0d0c1d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  [data-img-wrapper] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    font-size: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 1;
    }
  }
}
</style>
