<!-- 任务 礼物 -->
<template>
  <div :class="$style['after-action-tips-page']">
    <span :class="$style['after-action-close-icon']" @click="handleCloseTip">
      <van-icon name="cross" :size="20" />
    </span>
    <video :src="$getters['change/afterActionPlayVideo']['url']" controls autoplay></video>
  </div>
</template>

<script>
// import { delay } from "@/utils/lib";

export default {
  name: "reward-after-action-play-video",
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/afterActionPlayVideo"]) {
        this.$store.dispatch("change/hidden", "afterActionPlayVideo");
        this.$clearCurRewardAction();
      }
    },
  },
  beforeDestroy() {
    this.$clearCurRewardAction();
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.after-action-close-icon {
  padding: 12px;
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  z-index: 2;
  color: white;
  cursor: pointer;
}

.after-action-tips-page {
  position: absolute;
  z-index: 1;
  background-color: #0d0c1d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
  }
}
</style>
