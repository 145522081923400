<!-- 任务 礼物 -->
<template>
  <div :class="$style['da-call-wrapper']">
    <div :class="$style['da-call-wrapper-mask']" @click="handleCloseTip"></div>
    <div :class="$style['da-call-wrapper-tips']" v-if="showtips">
      <span data-yyyag-close @click="showtips = false">
        <van-icon name="cross" :size="24" />
      </span>
      <p data-yyyag-title>活动规则</p>

      <div data-yyyag-content>
        <p>1.点赞排行榜前三名的明星照片可显示在明星照片墙</p>
        <p>2.每天24点前统计点赞数量，第二天更新照片墙明星头像</p>
        <p>3.排行榜只显示9个Hydeout热门的DJ</p>
      </div>
    </div>
    <div :class="$style['da-call-wrapper-item']">
      <div :class="$style['da-call-wrapper-item-title']">
        <span data-item-wenhao>
          <!-- <van-icon name="question-o" :size="24" /> -->
        </span>
        <p style="font-size: 16px; color: #6555ff">打CALL榜</p>
        <span data-item-wenhao-close @click="handleCloseTip">
          <van-icon name="cross" :size="24" />
        </span>
      </div>

      <div :class="$style['da-call-wrapper-item-content']">
        <vue-scroll ref="callvuescrollwrapper">
          <div
            :class="$style['da-call-useritem']"
            :key="'ugasdqa' + item"
            v-for="item in resultLists"
          >
            <div :class="$style['da-call-wrapper-item-content-img']">
              <p data-img>
                <img
                  :src="item.image"
                  style="width: 100%; height: 100%; object-fit: cover"
                  alt=""
                />
              </p>

              <p data-name class="ellipsis">{{ item.name }}</p>
            </div>

            <div>
              <p
                style="
                  font-size: 0px;
                  display: inline-block;
                  vertical-align: middle;
                  line-height: 1;
                "
              >
                <img src="@/assets/icon/like-2.svg" alt="" />
              </p>
              <p data-aou-number>{{ item.count }}</p>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import { getSurveyResult } from "@/api/ticket";
export default {
  name: "da-call",
  data() {
    return {
      resultLists: [],
      showtips: false,
    };
  },
  created() {
    this.handleGetSurveyResult();
  },
  methods: {
    handleGetSurveyResult() {
      getSurveyResult(this.$landConfig[this.$getters.landId]["rankId"]).then((res) => {
        this.resultLists = res;
      });
    },

    handleCloseTip() {
      this.$store.dispatch("change/hidden", "hydeoutRank");
    },
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.da-call-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.da-call-wrapper-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}

.da-call-wrapper-item {
  width: 310px;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  background-color: #0d0b1d;
  padding-top: 44px;
}

.da-call-wrapper-item-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  [data-item-wenhao] {
    padding: 10px;
  }
  [data-item-wenhao-close] {
    padding: 10px;
  }
}

.da-call-wrapper-item-content {
  position: relative;
  height: 100%;
  color: white;
}

.da-call-useritem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 50px 8px 40px;
  [data-aou-number] {
    display: inline-block;
    line-height: 1;
    padding-left: 2px;
  }
}

.da-call-wrapper-item-content-img {
  width: 30px;
  height: 30px;
  position: relative;
  [data-name] {
    position: absolute;
    line-height: 30px;
    right: -10px;
    top: 0;
    white-space: nowrap;
    transform: translateX(100%);
    width: 120px;
  }
  [data-img] {
    width: 30px;
    height: 30px;
    position: absolute;
    overflow: hidden;
    border-radius: 30px;
  }
}

.da-call-wrapper-tips {
  position: absolute;
  z-index: 10;
  width: 500px;
  height: 240px;
  background-color: #0d0c1d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 80px 30px;
  overflow: auto;
  [data-yyyag-title] {
    font-size: 16px;
    color: #6555ff;
    line-height: 21px;
    padding-bottom: 20px;
    text-align: center;
  }
  [data-yyyag-content] {
    font-size: 13px;
    color: #cbcbcb;
    line-height: 16px;
  }
  [data-yyyag-close] {
    padding: 24px;
    top: 0px;
    right: 0px;
    position: absolute;
    color: white;
  }
}
</style>
