import { post } from "../utils/http";
import store from "../store";

/**
 * 保存到云相册
 */
export const insertPlayerPhoto = (imagePath, shopId = store.getters.landId) => {
  return post("/system/app/player/insertPlayerPhoto", { imagePath, shopId });
};

/**
 * 上传到OSS
 */
export const uploadOss = (file) => {
  return post("/file/upload", file);
};
