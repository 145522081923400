import RewardDialog from "./rewardDialog";
import { delay } from "@/utils/lib";
export default (Vue) => {
  let instClass = Vue.extend(RewardDialog);
  function showFun(params) {
    return new Promise((resolve, reject) => {
      let instanceDom = new instClass({
        data() {
          return {
            show: true,
            title: params.title,
            description: params.description,
            subDescription: params.subDescription,
            img: params.img,
            cancelButtonText: params.cancelButtonText,
            confirmButtonText: params.confirmButtonText,
            autoCloseTime: params.autoCloseTime || 0,
            cancelConfirmLabel: params.cancelConfirmLabel,
          };
        },
        methods: {
          handleCancel() {
            console.log("this.cancelConfirmLabel", this.cancelConfirmLabel);
            if (this.cancelConfirmLabel == "") {
              this.show = false;
              reject("cancel");
            } else {
              this.$yaojingConfirm({
                title: this.cancelConfirmLabel || "确定关闭？",
                cancelButtonText: "取消",
                confirmButtonText: "确认",
              }).then(() => {
                this.show = false;
                reject("cancel");
              });
            }
          },
          handleConfirm() {
            this.show = false;
            resolve("confirm");
          },

          close() {
            this.show = false;
          },
        },
        mounted() {
          if (this.autoCloseTime) {
            delay(this.autoCloseTime).then(() => {
              this.show = false;
            });
          }
        },
      }).$mount();
      document.body.appendChild(instanceDom.$el);
    });
  }
  Vue.prototype.$rewardDialog = showFun;
};
