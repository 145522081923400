import store from "../../store";
import { landConfig } from "@/utils/landBaseInfo";
export default (params = {}) => {
  const urlJson = landConfig[params.landId]["open720"][params.id];

  if (urlJson.type == "iframe") {
    store.dispatch("change/show", {
      name: "afterActionOpenIframe",
      params: { url: urlJson.url },
    });
  } else {
    location.replace(urlJson.url + "&backurl=" + encodeURIComponent(location.href));
  }
};
