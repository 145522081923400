/* eslint-disable no-undef */
const state = {
  desktop: device.desktop(),
  mobile: device.mobile(),
  tablet: device.tablet(),
  landscape: device.landscape(),
  portrait: device.portrait(),
};

const mutations = {
  SET_DEVICE_LP: (state, data) => {
    if (data == "landscape") {
      state.landscape = true;
      state.portrait = false;
    } else {
      state.landscape = false;
      state.portrait = true;
    }
  },
};

const actions = {
  /**
   * 更新横竖屏
   */
  set_device_lp({ commit }, data) {
    commit("SET_DEVICE_LP", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
