var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-scroll-page"},[_c('vue-scroll',{ref:"vs",attrs:{"id":"vs","ops":_vm.option},on:{"handle-scroll":_vm.handleScroll,"refresh-activate":_vm.handleActivate,"refresh-start":_vm.handleStart,"refresh-before-deactivate":_vm.handleBeforeDeactivate,"refresh-deactivate":_vm.handleDeactivate,"load-activate":_vm.handleActivate,"load-start":_vm.handleStart,"load-before-deactivate":_vm.handleBeforeDeactivate,"load-deactivate":_vm.handleDeactivate}},[_c('div',{staticStyle:{"width":"100%","height":"100%","position":"relative"},attrs:{"id":"vs-content"}},[_vm._t("default")],2),(_vm.isEnd)?_c('p',{staticStyle:{"text-align":"center","color":"#cbcbcb","font-size":"12px","padding-bottom":"10px"}},[_vm._v(" 没有更多了 ")]):_vm._e()]),_c('transition',{attrs:{"name":"van-slide-up"}},[(_vm.isShowUp)?_c('div',{style:({
        position: 'absolute',
        right: '15px',
        bottom: _vm.upgradeBottom,
        backgroundColor: '#0d0c1d',
        borderRadius: '50%',
        width: '35px',
        height: '35px',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
      }),on:{"click":function($event){return _vm.gotoTop()}}},[_c('van-icon',{attrs:{"color":"#665cff","name":"back-top","size":"25px"}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }