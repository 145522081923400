<!-- 任务 礼物 -->
<template>
  <div :class="$style['landimg-tips-wrapper']">
    <div
      :class="$style['landimg-tips-main-wrapper']"
      :data-model="$getters['change/openLandImg']['model'] || 'center'"
    >
      <span :class="$style['landimg-close-icon']" @click="handleCloseTip">
        <img style="width: 100%" src="@/assets/land/menu/close.svg" alt="" />
      </span>

      <div :class="$style['landimg-tips-imgwrapper']">
        <vue-scroll ref="scrollwrapper">
          <imgload @imgload="$refs.scrollwrapper.refresh()">
            <img style="width: 100%" :src="$getters['change/openLandImg']['imgUrl']" alt=""
          /></imgload>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import imgload from "@/components/global/imgload/imgload.vue";

export default {
  name: "landimg-tips-wrapper",
  data() {
    return {};
  },
  props: {
    imgurl: {
      default: "https://obs-helf.cucloud.cn/bucket/assets/20220804181720.png",
      type: String,
    },
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/openLandImg"]) {
        this.$store.dispatch("change/hidden", "openLandImg");
      }
    },

    imgload() {
      this.$refs.scrollwrapper.refresh();
    },
  },
  components: {
    imgload,
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.landimg-close-icon {
  padding: 12px;
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  z-index: 99999;
  color: white;
  cursor: pointer;
  pointer-events: auto;
}

.landimg-tips-wrapper {
  position: absolute;
  z-index: 1;
  background-color: rgba(13, 12, 29, 0.38);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landimg-tips-main-wrapper {
  position: absolute;
  z-index: 1;
  background-color: #0d0c1d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &[data-model="right"] {
    width: 414px !important;
    left: auto !important;
    right: 0;
  }
  &[data-model="left"] {
    width: 414px !important;
  }
}

.landimg-tips-imgwrapper {
  height: 100%;
  [data-img-wrapper] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    font-size: 0;
  }
}
</style>
