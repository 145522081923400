<!-- 购买提示 -->
<template>
  <div :class="$style.takePhotoWrapper">
    <span :class="$style['takePhotoWrapperClose']" @click="handleClose">
      <img style="width: 100%" src="@/assets/land/menu/close.svg" alt="" />
    </span>

    <div :class="$style.takePhotoWrapperImg">
      <img style="width: 100%" :src="$getters['change/takePhoto']['localImg']" alt="" />
      <div :class="$style.takePhotoBtnWrapper">
        <div :class="$style.takePhotoSaveCloudBtn" @click="handleSavePhoto">
          <img data-photo-btnicon src="@/assets/land/menu/saveCloud.svg" alt="" />
          <span>保存到云相册</span>
        </div>
        <div :class="$style.takePhotoSaveLocalBtn">
          <img data-photo-btnicon src="@/assets/land/menu/saveLocal.svg" alt="" />
          <span>{{ $getters.desktop ? "右键保存" : "长按图片保存" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { insertPlayerPhoto } from "@/api/photo";
export default {
  name: "take-photo",
  data() {
    return {
      isSave: false,
    };
  },
  created() {
    this.$store.dispatch("change/hidden", "landUiShowStatus");
  },
  methods: {
    handleClose() {
      this.$store.dispatch("change/hidden", "takePhoto");
    },

    handleSavePhoto() {
      if (this.isSave) {
        this.$yaojingToast("照片已经保存过了", "warning");
        return;
      }
      insertPlayerPhoto(this.$getters["change/takePhoto"]["localImg"]).then(() => {
        this.$yaojingToast("保存成功", "success");
        this.isSave = true;
        // this.$store.dispatch("change/hidden", "takePhoto");
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.takePhotoWrapper {
  position: fixed;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 160px;
}

.takePhotoWrapperImg {
  width: 100;
  max-width: 700px;
  position: relative;
}

.takePhotoBtnWrapper {
  position: absolute;
  bottom: 0;
  right: -20px;
  transform: translateX(100%);
}

.takePhotoWrapperClose {
  width: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.takePhotoSaveCloudBtn {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 20px;
  [data-photo-btnicon] {
    width: 30px;
    margin-right: 10px;
  }
}
.takePhotoSaveLocalBtn {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  [data-photo-btnicon] {
    width: 30px;
    margin-right: 10px;
  }
}
</style>
