<!--  -->
<template>
  <div :class="$style['buyreward-tips-page']">
    <span :class="$style['buyreward-close-icon']" @click="handleCloseTip">
      <van-icon name="cross" :size="20" />
    </span>

    <div :class="$style['buyreward-wrapper']" v-if="detail">
      <div data-img-wrapper>
        <vue-scroll ref="scrollwrapper">
          <p style="padding-bottom: 12px">
            <imgload @imgload="$refs.scrollwrapper.refresh()">
              <img style="width: 100%" :src="detail.smallImage" alt=""
            /></imgload>
          </p>
          <p data-img-title>藏品介绍</p>
          <div data-img-text>
            {{ detail.description }}
          </div>
        </vue-scroll>
      </div>
      <div data-content>
        <div>
          <p data-content-title>{{ $getters["change/rewardCouponBuy"]["title"] }}</p>
          <p data-content-subtitle>{{ detail.productName }}</p>
          <div data-content-type-content>
            <p data-content-type>{{ rarityEmu[detail.rarity] }}</p>
          </div>

          <div data-content-land>
            <van-image
              width="25"
              height="25"
              round
              fit="cover"
              style="background-color: #0d0c1d"
              :src="detail.publisherImage"
            >
            </van-image>
            {{ detail.publisher }}
          </div>
        </div>

        <div>
          <div data-content-money>
            ¥
            {{
              Math.round(detail.unitPrice * 1 * $getters["change/rewardCouponBuy"]["num"] * 100) /
              100
            }}<span data-content-origin-money
              >原价: <span>{{ detail.unitPrice }}</span>
            </span>
          </div>
          <div data-content-btn>
            <van-button
              data-buy
              color="#665CFF"
              :disabled="detail.stockQuantity == 0"
              @click="handleGoToOrderDetail"
            >
              {{ detail.stockQuantity == 0 ? "无库存" : "购买" }}
            </van-button>
            <!-- <span data-arrow></span> -->
            <!-- <div data-limt>
              <p><van-icon name="underway-o" size="10" />限时仅剩</p>
              <count-down></count-down>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { getIsWxClient } from "@/utils/lib";
import imgload from "@/components/global/imgload/imgload.vue";
import CountDown from "@/components/global/countdown/CountDown.vue";
import shopApi from "@/api/shop";
export default {
  name: "buyreward-page",
  props: {},
  data() {
    return {
      detail: "",
      rarityEmu: {
        0: "普通",
        1: "精良",
        2: "稀有",
        3: "传说",
      },
    };
  },
  watch: {},
  methods: {
    imgload() {
      this.$refs.scrollwrapper.refresh();
    },
    handleCloseTip() {
      this.$store.dispatch("change/hidden", "rewardCouponBuy");
      this.$clearCurRewardAction();
    },
    handleGetProductDetail() {
      shopApi.detail(this.$getters["change/rewardCouponBuy"]["productId"]).then((res) => {
        this.detail = res;
      });
    },

    handleGoToOrderDetail() {
      if (this.$getters.userInfo.isVerified == 0) {
        this.$yaojingConfirm({
          title: "实名认证",
          text: "需实名认证后才可购买数字藏品",
          cancelButtonText: "取消",
          confirmButtonText: "认证",
        }).then(() => {
          this.$store.dispatch("change/show", "realName");
        });
        return;
      }

      const details = [
        {
          productId: this.$getters["change/rewardCouponBuy"]["productId"],
          quantity: 1,
        },
      ];
      shopApi
        .createCouponOrder(
          2,
          this.$getters.landId,
          this.$getters["change/rewardCouponBuy"]["questId"],
          details,
        )
        .then((orderNo) => {
          const wx = getIsWxClient() ? 1 : 0;
          this.$router.replace("/Payment/?orderNo=" + orderNo + "&wx=" + wx);
        });
    },
  },
  components: {
    imgload,
    CountDown,
  },
  created() {
    this.handleGetProductDetail();
    // delay(1.2).then(() => {
    //   this.handleCloseTip();
    // });
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.buyreward-close-icon {
  width: 35px;
  height: 35px;
  padding: 15px 15px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.buyreward-tips-page {
  position: absolute;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  padding: 25px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buyreward-wrapper {
  position: relative;
  width: auto;
  margin: 0 auto;
  height: 100%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  [data-img-wrapper] {
    height: 100%;
    width: 244px;
    position: relative;
    [data-img-title] {
      font-size: 13px;
      color: $--color_primary_2;
      line-height: 20px;
    }

    [data-img-text] {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: $--color_text_1;
      line-height: 20px;
    }
  }
  [data-content] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 14px;
    padding-left: 30px;
    max-width: 280px;
    [data-content-title] {
      font-size: 24px;
      color: #f802cd;
      line-height: 32px;
      padding-bottom: 6px;
      font-weight: bold;
    }
    [data-content-subtitle] {
      font-size: 16px;
      line-height: 21px;
      padding-bottom: 5px;
    }
    [data-content-type-content] {
      padding-bottom: 18px;
    }
    [data-content-type] {
      border-radius: 7px;
      border: 1px solid #00ff01;
      font-size: 9px;
      color: #00ff01;
      line-height: 14px;
      padding: 0 6px;
      display: inline-block;
    }
    [data-content-land] {
      position: relative;
      padding-left: 30px;
      line-height: 25px;
      :global(.van-image) {
        position: absolute;
        left: 0;
      }
    }
    [data-content-money] {
      font-size: 30px;
      color: #fe9636;
      line-height: 40px;
      padding-bottom: 8px;
      [data-content-origin-money] {
        font-size: 12px;
        padding-left: 4px;
        color: $--color_text_1;
        line-height: 16px;
        text-decoration: line-through;
        span {
          font-size: 10px;
        }
      }
    }
    [data-content-btn] {
      position: relative;
      width: 148px;
      [data-arrow] {
        display: inline-block;
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 0;
        height: 0;
        border: 6px solid;
        border-color: transparent transparent transparent #665cff;
      }
      [data-buy] {
        height: 36px;
        width: 148px;
        font-size: 14px;
        line-height: 19px;
        position: relative;
      }
      [data-more] {
        margin-left: 20px;
        height: 36px;
        width: 120px;
        font-size: 14px;
        line-height: 19px;
        border-color: #665cff !important;
        color: #665cff !important;
      }
      [data-limt] {
        height: 36px;
        padding: 4px 14px 4px 12px;
        background: #f521cb;
        width: 90px;
        font-size: 10px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        & > p {
          padding-bottom: 3px;
        }
        :global(.van-icon) {
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
