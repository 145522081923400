/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import { fetch, post, pickCodeFetch, pickCodePost } from "@/utils/http";
import { delay, objKeySort, objToString, signKey, getRandomString } from "@/utils/lib";
import _ from "lodash";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc"; // im
import * as timezone from "dayjs/plugin/timezone"; // im
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 我的个人中心
 */
export default {
  /**
   * 7.2 我的预约（分页）
   * @param {*} pageNum
   * @param {*} pageSize
   * @returns
   */
  myBookingList: (pageNum, pageSize) => {
    return fetch("/system/app/product/myBookingList", { pageNum, pageSize }).then((response) => {
      return response;
    });
  },

  /**
   * 7.4 订单（分页）
   * @param {*} pageNum
   * @param {*} pageSize
   * @param {*} shopId 可选   商户ID
   * @param {*} status 可选   订单状态 0代付款 1已完成 2已关闭
   * @param {*} currencyType 可选   货币类型 0Y币  1钻石 2人民币"
   * @returns
   */
  orderList: (pageNum, pageSize, shopId, status, currencyType) => {
    return fetch("/system/app/product/orderList", {
      pageNum,
      pageSize,
      shopId,
      status,
      currencyType,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 7.5 删除相册中的照片
   * @param {*} id 照片ID
   * @returns
   */
  removePlayerPhoto: (id) => {
    return post("/system/app/player/removePlayerPhoto", { id }).then((response) => {
      return response;
    });
  },

  /**
   * 7.6 我的优惠券
   * @param {*} status 状态 0 未使用 1已使用 2已过期
   * @returns
   */
  getCoupons: (status) => {
    return fetch("/system/app/player/getCoupons", { status }).then((response) => {
      return response;
    });
  },

  /**
   * 创建优惠券
   * @param {*} playerId
   * @param {*} shopId
   * @param {*} mobile
   * @param {*} pickCodeInfoId
   * @param {*} limitedDay
   * @returns
   */
  create: (playerId, shopId, mobile, pickCodeInfoId, limitedDay) => {
    const nonceStr = getRandomString(16);
    const timestamp = dayjs().tz("Asia/Shanghai").valueOf();
    // const timestamp = Number(new Date());
    const objVal = objKeySort({ playerId, shopId, mobile, pickCodeInfoId, limitedDay });
    const str = objToString(objVal);
    const sign_str = signKey(nonceStr, timestamp, str);
    const sign = CryptoJS.MD5(sign_str).toString().toUpperCase();
    return pickCodePost("/api/v1/coupons/create", {
      playerId,
      shopId,
      mobile,
      pickCodeInfoId,
      limitedDay,
      nonceStr,
      timestamp,
      sign,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 销毁优惠券
   * @param {*} pickCode
   * @returns
   */
  destroy: (pickCode) => {
    const nonceStr = getRandomString(16);
    const timestamp = dayjs().tz("Asia/Shanghai").valueOf();
    // const timestamp = Number(new Date());
    const objVal = objKeySort({ pickCode });
    const str = objToString(objVal);
    const sign_str = signKey(nonceStr, timestamp, str);
    const sign = CryptoJS.MD5(sign_str).toString().toUpperCase();
    return pickCodePost("/api/v1/coupons/destroy", {
      pickCode,
      nonceStr,
      timestamp,
      sign,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 模板列表兑换券
   * @returns
   */
  pickCodeTemplateList: () => {
    const nonceStr = getRandomString(16);
    const timestamp = dayjs().tz("Asia/Shanghai").valueOf();
    // const timestamp = Number(new Date());
    const objVal = objKeySort({});
    const str = objToString(objVal);
    const sign_str = signKey(nonceStr, timestamp, str);
    const sign = CryptoJS.MD5(sign_str).toString().toUpperCase();
    return pickCodeFetch("/api/v1/coupons/pickCodeTemplateList", {
      nonceStr,
      timestamp,
      sign,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 兑换券
   * @param {*} playerId
   * @param {*} state
   * @param {*} offset
   * @param {*} limit
   * @returns
   */
  listPickCode: (playerId, state, offset, limit) => {
    const nonceStr = getRandomString(16);
    const timestamp = dayjs().tz("Asia/Shanghai").valueOf();
    // const timestamp = Number(new Date());
    const objVal = objKeySort({ playerId, state, offset, limit });
    const str = objToString(objVal);
    const sign_str = signKey(nonceStr, timestamp, str);
    const sign = CryptoJS.MD5(sign_str).toString().toUpperCase();
    return pickCodeFetch("/api/v1/coupons/listPickCode", {
      playerId,
      state,
      offset,
      limit,
      nonceStr,
      timestamp,
      sign,
    }).then((response) => {
      return response;
    });
  },
  /**
   * MD5加密
   * @param {*} word
   */
  aesEncode: (word) => {
    const key = CryptoJS.enc.Utf8.parse("47FC4124CF94E070"); // 密钥
    const iv = CryptoJS.enc.Utf8.parse("ovOh2xYoRdfATob6"); // 密钥偏移量
    let srcs = CryptoJS.enc.Utf8.parse(word);
    // 对称加密算法主要有AES、DES、3DES / 非对称加密算法主要有RSA、DSA、RCC
    // iv(初始变量)
    // key(加密密钥)
    // mode(加密模式 主要有CBC(默认)、CFB、CTR、OFB、ECB)
    // padding(填充方式 主要有Pkcs7(默认)、Iso97971、AnsiX923、Iso10126、ZeroPadding)

    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return encodeURIComponent(encryptedBase64Data);
  },
  /**
   * MD5解密
   * @param {*} word
   */
  aesDecode: (word) => {
    const key = CryptoJS.enc.Utf8.parse("47FC4124CF94E070"); // 密钥
    const iv = CryptoJS.enc.Utf8.parse("ovOh2xYoRdfATob6"); // 密钥偏移量
    word = decodeURIComponent(word);
    let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  },
  /**
   * DES加密
   * @param {*} word
   */
  desEncode: (word) => {
    const key = CryptoJS.enc.Utf8.parse("MOxinrui"); // 密钥
    const iv = CryptoJS.enc.Utf8.parse("MOxinrui"); // 密钥偏移量
    let srcs = CryptoJS.enc.Utf8.parse(word);
    // 对称加密算法主要有AES、DES、3DES / 非对称加密算法主要有RSA、DSA、RCC
    // iv(初始变量)
    // key(加密密钥)
    // mode(加密模式 主要有CBC(默认)、CFB、CTR、OFB、ECB)
    // padding(填充方式 主要有Pkcs7(默认)、Iso97971、AnsiX923、Iso10126、ZeroPadding)

    let encrypted = CryptoJS.DES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    // let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    // return encodeURIComponent(encryptedBase64Data);
    return encrypted.toString();
  },

  /**
   * DES解密
   * @param {*} word
   */
  desDecode: (word) => {
    const key = CryptoJS.enc.Utf8.parse("MOxinrui"); // 密钥
    const iv = CryptoJS.enc.Utf8.parse("MOxinrui"); // 密钥偏移量
    let decrypted = CryptoJS.DES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(decodeURIComponent(word)),
      },
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  },
};
