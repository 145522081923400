<template>
  <div :class="$style['awatar-main-gender']">
    <div :class="$style['awatar-bottom-gender']">
      <div></div>
      <div :class="$style['avatar-imgselectbox']">
        <div
          v-for="item in avartarBodys"
          :key="'gender' + item.equipName"
          :data-avatarimgselectitem-active="item.equipName == avatarBody"
          @click="handleSelectGender(item.equipName)"
        >
          <p data-avatarimgselectitem-img>
            <img
              :src="item.equipImage"
              style="width: 100%; height: 100%; object-fit: cover"
              alt=""
            />
          </p>
          <p data-avatarimgselectitem-text>{{ genderEmu[item.equipName] }}</p>
        </div>
      </div>

      <div data-control-btn style="text-align: right">
        <!-- <van-button data-button-cancel :color="$global.color_bg_line_1" @click="$emit('back')">
          取消
        </van-button> -->
        <van-button data-button-confirm :color="$global.color_primary_2" @click="handleSaveGender">
          下一步
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "awatar_gender",
  data() {
    return {
      gender: "body_female", //0:女士 1:男生
      genderEmu: {
        body_male: "男",
        body_female: "女",
      },
      avatarBody: "",
    };
  },
  props: {
    avartarBodys: {
      default: () => {
        return [];
      },
      type: Array,
    },
    curBody: {
      default: "",
      type: String,
    },
  },
  created() {
    this.avatarBody = this.curBody;
  },
  methods: {
    /**
     * 选择了男女
     */
    handleSelectGender(equipName) {
      this.avatarBody = equipName;
      this.$emit("changeGender", { body: equipName });
    },

    handleSaveGender() {
      this.$emit("save", { body: this.avatarBody });
    },
  },
};
</script>

<style lang="scss" module>
@import "../../assets/themes.scss";
.awatar-main-gender {
  position: absolute;
  z-index: 1;
  color: $--color_text_1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.awatar-bottom-gender {
  height: 355px;
  position: absolute;
  padding: 48px 30px 20px 30px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  pointer-events: auto;
  background-color: $--color_primary_1;
  [data-awatar-bottom-text] {
    text-align: center;
    display: none;
  }
  [data-awatar-toplabel1] {
    font-size: $--font_size_44;
    line-height: 30px;
    padding-bottom: 6px;
  }
  [data-awatar-toplabel2] {
    font-size: $--font_size_32;
  }
  :global(.van-button) {
    height: 50px;
  }
  [data-control-btn] {
    /* padding-left: 138px; */
    position: relative;
  }
  [data-button-cancel] {
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    font-size: $--font_size_32;
  }

  [data-button-confirm] {
    width: 100%;
    font-size: $--font_size_32;
  }
}
.avatar-imgselectbox {
  display: flex;
  justify-content: space-between;
  color: #878787;
  [data-avatarimgselectitem-img] {
    width: 134px;
    height: 134px;
    position: relative;
    border-radius: 134px;
    overflow: hidden;
    border: 3px solid $--color_primary_1;
  }
  [data-avatarimgselectitem-active] {
    [data-avatarimgselectitem-img] {
      border-color: $--color_primary_2;
    }
    color: $--color_primary_2;
  }
  [data-avatarimgselectitem-text] {
    font-size: $--font_size_4;
    padding-top: 18px;
    line-height: 27px;
    text-align: center;
  }
}
html:global(.landscape) {
  .awatar-bottom-gender {
    width: 416px;
    height: 100%;
    left: auto;
    right: 0;
    justify-content: space-between;
    [data-awatar-bottom-text] {
      display: block;
    }
  }
  .awatar-bottom-gender {
    padding: 16px 46px 20px 46px;
    [data-avatarimgselectitem-img] {
      width: 100px;
      height: 100px;
    }
    [data-avatarimgselectitem-text] {
      padding-top: 8px;
    }
  }
}

html:not(:global(.mobile)):global(.landscape) {
  .awatar-bottom-gender {
    width: 100%;
    height: 100%;
  }
}
</style>
