import { post } from "../utils/http";
// import { playerInProgressData } from "./questData";
import { mapQuestList } from "@/utils/taskfactory";
import _lang from "lodash/lang";
import _object from "lodash/object";
import _array from "lodash/array";
/**
 * 获取每日任务和新手任务
 * @param {*} questTypeName "任务类型：everyday || newbee，必传"
 * @returns
 */
export const everydayAndNewbee = (questTypeName) => {
  return post("/quest/app/everydayAndNewbee", {
    questTypeName: questTypeName,
  }).then((res) => {
    if (_lang.isArray(res)) {
      return mapQuestList(res);
    } else {
      return [];
    }
  });
};

/**
 * 商户的任务列表
 * @param {*} questPublisher
 * @returns
 */
export const shopQuestList = async (questPublisher) => {
  const landQuestLists = await post("/quest/app/shopQuestList", {
    questPublisher: questPublisher,
  }).then((res) => {
    if (_lang.isArray(res)) {
      return mapQuestList(res);
    } else {
      return [];
    }
  });

  const landEverydayAndNewbee = await post("/quest/app/everydayAndNewbee", {
    questTypeName: "everyday",
    questPublisher: questPublisher,
  })
    .then((res) => {
      if (_lang.isArray(res)) {
        return mapQuestList(res);
      } else {
        return [];
      }
    })
    .catch(() => {
      return [];
    });

  return _array.compact(_array.concat(landEverydayAndNewbee, landQuestLists));
};

/**
 * 自动领取激活主岛 单次任务
 */
export const receiveMainLandQuest = (questPublisher) => {
  return post("/quest/app/shopQuestList", {
    questPublisher: questPublisher,
  }).then((questList) => {
    if (!_lang.isArray(questList)) return;
    const checkParams = { type: "enterScene", target: "mainLand" };
    questList.forEach((item) => {
      if (item.activateCondition) {
        const activateCondition = JSON.parse(item.activateCondition);
        let baseData = "";
        let compareData = "";
        activateCondition.forEach((element) => {
          _object.forIn(checkParams, function (value, key) {
            baseData = baseData + "_" + element[key];
            compareData = compareData + "_" + value;
          });
        });
        if (baseData == compareData) {
          receiveQuest(item.id);
        }
      }
    });
  });
};

/**
 * 领取任务
 * @param {*} id
 * @returns
 */
export const receiveQuest = (id) => {
  return post("/quest/app/receiveQuest", {
    id: id,
  });
};

/**
 * 查看分岛的任务基本情况
 */
export const shopQuestGroup = () => {
  return post("/quest/app/shopQuestGroup").then((res) => {
    return res.filter((item) => {
      return item.shopId != "1";
    });
  });
};

/**
 * 获取用户进行中的任务
 */
export const playerInProgress = () => {
  return post("/quest/app/playerInProgress");
};

/**
 * 更新保存玩家的任务数据
 */
export const updatePlayerQuest = (id, questNeedItem) => {
  return post("/quest/app/updatePlayerQuest", {
    id,
    questNeedItem,
  });
};

/**
 * 任务完成
 */
export const questDone = (id) => {
  return post("/quest/app/donePlayerQuest", {
    id,
  });
};

/**
 * 获取任务详情
 */
export const getQuestInfo = (id) => {
  return post("/quest/app/questInfo", {
    id,
  });
};
