import Vue from "vue";
const vm = new Vue();

import yaojingToastFn from "./toast";
import yaojingConfirmFn from "./confirm";
import rewardDialogFn from "./rewardDialog";
Vue.use(yaojingToastFn);
Vue.use(yaojingConfirmFn);
Vue.use(rewardDialogFn);

export const yaojingToast = (text, type, duration = 2) => {
  vm.$yaojingToast(text, type, duration);
};
