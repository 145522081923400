<!-- 分页框架  -->
<template>
  <div class="content-scroll-page">
    <vue-scroll
      id="vs"
      ref="vs"
      :ops="option"
      @handle-scroll="handleScroll"
      @refresh-activate="handleActivate"
      @refresh-start="handleStart"
      @refresh-before-deactivate="handleBeforeDeactivate"
      @refresh-deactivate="handleDeactivate"
      @load-activate="handleActivate"
      @load-start="handleStart"
      @load-before-deactivate="handleBeforeDeactivate"
      @load-deactivate="handleDeactivate"
    >
      <div id="vs-content" style="width: 100%; height: 100%; position: relative">
        <slot></slot>
      </div>
      <p
        v-if="isEnd"
        style="text-align: center; color: #cbcbcb; font-size: 12px; padding-bottom: 10px"
      >
        没有更多了
      </p>
    </vue-scroll>
    <!-- 置顶按钮 -->
    <transition name="van-slide-up">
      <div
        v-if="isShowUp"
        :style="{
          position: 'absolute',
          right: '15px',
          bottom: upgradeBottom,
          backgroundColor: '#0d0c1d',
          borderRadius: '50%',
          width: '35px',
          height: '35px',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center',
        }"
        @click="gotoTop()"
      >
        <van-icon color="#665cff" name="back-top" size="25px" />
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import imagesLoaded from "imagesloaded";
import $ from "jquery";
import { delay } from "@/utils/lib";

export default {
  name: "contentScrollComponent",
  components: {},
  props: {
    pageNum: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    upgradeBottom: {
      type: String,
      default: "50px",
    },
  },
  data() {
    return {
      isShowUp: false,
      option: {
        vuescroll: {
          pullRefresh: {
            enable: true,
            tips: {
              deactive: "下拉刷新",
              active: "释放刷新",
              start: "刷新中...",
              beforeDeactive: "刷新成功!",
            },
          },
          pushLoad: {
            enable: true,
            tips: {
              deactive: "滑动加载",
              active: "释放加载",
              start: "加载中...",
              beforeDeactive: "加载成功!",
            },
            auto: false,
            autoLoadDistance: 5,
          },
        },
      },
      isEnd: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refresh() {
      this.$refs["vs"].refresh();
    },
    /**
     * 更多
     */
    noMore() {
      this.isEnd = true;
    },
    /**
     * 图片载入刷新
     */
    imageLoadedRefreshScroll() {
      imagesLoaded("#vs-content", { background: true }, (instance) => {
        this.$nextTick(() => {
          if (this.$refs["vs"]) this.$refs["vs"].refresh();
        });
      });
    },
    /**
     * 初始位置
     */
    gotoTop() {
      this.$refs["vs"].scrollTo({ y: 1 }, 750, "easeInQuad");
    },
    /**
     * 内容是否超过滚动视窗
     */
    checkContentHeight() {
      this.$nextTick(() => {
        const vs = $("#vs").height();
        console.log("vs: ", vs);
        const content = $("#vs-content").height();
        console.log("content: ", content);
        // 内容小于滚动
        if (content <= vs) {
          this.stopPushLoad();
          // this.isEnd = false;
          this.isEnd = true;
        } else {
          if (this.pageNum < this.totalPage) {
            this.activePushLoad();
            this.isEnd = false;
          } else {
            this.stopPushLoad();
            this.isEnd = true;
          }
        }
      });
    },
    /**
     * 停止刷新动画
     */
    stopPullRefresh() {
      this.$nextTick(() => {
        this.option.vuescroll.pullRefresh.enable = false;
      });
    },
    /**
     * 开始刷新动画
     */
    activePullRefresh() {
      this.$nextTick(() => {
        this.option.vuescroll.pullRefresh.enable = true;
      });
    },
    /**
     * 停止载入动画
     */
    stopPushLoad() {
      this.$nextTick(() => {
        this.option.vuescroll.pushLoad.enable = false;
      });
    },
    /**
     * 开始载入动画
     */
    activePushLoad() {
      this.$nextTick(() => {
        this.option.vuescroll.pushLoad.enable = true;
      });
    },
    /**
     * 实时位置
     */
    handleScroll(vertical, horizontal, nativeEvent) {
      let max;
      if ($("#app").height() >= 667) {
        max = 625;
      } else {
        max = 350;
      }
      if (vertical.scrollTop > max) {
        this.isShowUp = true;
      } else {
        this.isShowUp = false;
      }
    },
    /**
     * 激活
     */
    handleActivate(vm, refreshDom) {
      const name = refreshDom.getAttribute("class");
      if (name == "__refresh") {
      }
      if (name == "__load") {
      }
    },
    /**
     * 开始调用后台数据接口
     */
    handleStart(vm, refreshDom, done) {
      const name = refreshDom.getAttribute("class");
      setTimeout(() => {
        if (name == "__refresh") {
          this.$emit("scrollRefresh");
        }
        if (name == "__load") {
          this.$emit("scrollLoad");
        }
        done(); // load finished next step
      }, 300);
    },
    /**
     * 准备刷新
     */
    handleBeforeDeactivate(vm, refreshDom, done) {
      const name = refreshDom.getAttribute("class");
      done(); // next step
    },
    /**
     * 刷新完成
     */
    handleDeactivate(vm, refreshDom) {
      const name = refreshDom.getAttribute("class");
      if (name == "__load") {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs["vs"].refresh();
            this.$refs["vs"].scrollBy({ dy: 50 }, 500);
          }, 100);
        });
      }
    },
    /**
     * 窗口resize
     */
    async onResize() {
      await delay(0.1);
      this.checkContentHeight();
    },
  },
  created() {
    window.addEventListener("resize", this.onResize, false);
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {
    window.removeEventListener("resize", this.onResize, false);
  },
  activated() {},
  deactivated() {},
};
</script>

<style lang="scss" scoped>
.content-scroll-page {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
