<template>
  <div
    :class="$style['loading-wrapper']"
    :data-loading-status="$getters['change/loading']['landId'] == 0 ? 'home' : 'land'"
  >
    <div :class="$style['loading-land-wrapper']" v-if="$getters['change/loading']['landId'] != 1">
      <div data-loading-img>
        <img src="@/assets/login/landloading.gif" style="width: 100%" alt="" />
      </div>
      <div data-loading-land-progress>
        <p data-progress-text>加载中...</p>
        <div data-progress-box>
          <div
            data-progress-bar
            id="loading-progress-bar"
            :style="{
              transform:
                'translateX(' + (($getters['change/loading']['progress'] || 0) * 1 - 100) + '%)',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div :class="$style['loading-main-wrapper']" v-else>
      <div data-loading-img>
        <img src="@/assets/login/loading.png" style="width: 100%" alt="" />
      </div>
      <div data-loading-progress>
        <p data-progress-text>加载中...</p>
        <div data-progress-box>
          <div
            data-progress-bar
            id="loading-progress-bar"
            :style="{
              transform:
                'translateX(' + (($getters['change/loading']['progress'] || 0) * 1 - 100) + '%)',
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dispatcher from "@/utils/dispatcher";
export default {
  name: "loading-page",
  data() {
    return {};
  },
  props: {
    params: {
      default: () => {
        return {
          progress: 0,
          landId: "",
        };
      },
    },
  },
  created() {
    this.$store.dispatch("secne/set_cocos_start", 1);
  },
  beforeDestroy() {
    dispatcher.$emit("loadingEnd", "1");
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.loading-wrapper {
  position: fixed;
  z-index: 1000;
  color: $--color_text_1;
  width: 100%;
  height: 100%;
  &[data-loading-status="home"] {
    background: linear-gradient(180deg, #35344b 0%, #030211 100%);
  }
  &[data-loading-status="land"] {
    background-color: black;
  }
}
.loading-main-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  [data-loading-img] {
    width: 124px;
    margin-bottom: 52px;
  }
  [data-loading-progress] {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    [data-progress-text] {
      text-align: center;
      position: absolute;
      width: 100%;
      top: -48px;
      left: 0;
      font-size: 16px;
    }
    [data-progress-box] {
      position: absolute;
      height: 8px;
      left: 0;
      width: 100%;
      bottom: 0;
      overflow: hidden;
    }
    [data-progress-bar] {
      background-color: $--color_primary_2;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

.loading-land-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 149px;
  background-color: #030211;
  [data-loading-img] {
    width: 124px;
    padding-bottom: 80px;
  }
  [data-loading-land-progress] {
    position: relative;
    width: 60%;
    [data-progress-text] {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 24px;
      left: 0;
      font-size: 16px;
    }
    [data-progress-box] {
      position: absolute;
      height: 8px;
      left: 0;
      width: 100%;
      bottom: 0;
      overflow: hidden;
    }
    [data-progress-bar] {
      background-color: $--color_primary_2;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
}

html:global(.mobile):global(.landscape) {
  .loading-main-wrapper {
    [data-loading-img] {
      width: 110px;
    }
  }

  .loading-land-wrapper {
    padding-top: 0;
    justify-content: center;
    [data-loading-img] {
      width: 124px;
      padding-bottom: 26px;
    }
    [data-loading-land-progress] {
      width: 200px;
    }
  }
}

html:not(:global(.mobile)):global(.landscape) {
  .loading-wrapper {
    padding: 0 240px 150px 240px;
  }

  .loading-land-wrapper {
    padding-top: 0;
    justify-content: center;
    [data-loading-img] {
      width: 200px;
      padding-bottom: 0;
    }
    [data-loading-land-progress] {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
