/* eslint-disable no-unused-vars */
import { getAvatarTypeList, getAvatarList, getPlayerAvatar } from "@/api/avatar";
import _array from "lodash/array";
import _object from "lodash/object";
import { getQueryStringByName } from "@/utils/lib";
/**
 * 用户切换所保存的历史数据
 */
const initLandId = getQueryStringByName("landId") || 1;

const state = {
  historyPaths: "/",
  cocosBaseAssets: false, // 3D基础资源加载完成的标识
  secneData: {
    cmd: "1",
    arg: {},
  },

  landId: initLandId,
  userInfo: {
    id: "",
    nickName: "游客" + Math.round(Math.random() * 10000),
    points: "899888",
    ycoin: "9000",
    gender: "",
    openId: "",
    profilePhoto: "",
    isVerified: 0,
    realName: "",
  },

  access_token: "", //我的token 判断是否登录

  myAvatars: {},

  cocosStart: 0,
};

const mutations = {
  /**
   * 更新场景ID
   */
  UPDATE_LANDID: (state, landId) => {
    state.landId = landId;
  },

  UPDATE_ACCESS_TOKEN: (state, access_token) => {
    state.access_token = access_token;
  },
  UPDATE_HISTORY_PATH: (state, data) => {
    state.historyPaths = data;
  },

  /**
   * 更新3D资源状态
   */
  UPDATE_COCOS_BASEASSETS: (state, data) => {
    state.cocosBaseAssets = data;
  },
  /**
   * 设置用户信息
   */
  UPDATE_USER_INFO: (state, data) => {
    // state.userInfo = JSON.parse(JSON.stringify(Object.assign(state.userInfo, data)));
    state.userInfo = Object.assign(state.userInfo, data);
  },

  /**
   * 修改Avatar装备
   */
  UPDATE_AVATAR_DATA: (state, data) => {
    state.myAvatars = Object.assign(state.myAvatars, data);
  },

  /**
   * 初始化 Avatar装备
   */
  INIT_AVATAR_DATA: (state, data) => {
    state.myAvatars = data;
  },

  /**
   * 初始化 Avatar装备
   */
  SET_COCOS_START: (state, data) => {
    state.cocosStart = data;
  },
};

const actions = {
  // 更新Landid
  update_landid({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_LANDID", data);
      resolve(data);
    });
  },

  // Token
  update_access_token({ commit }, data) {
    commit("UPDATE_ACCESS_TOKEN", data);
  },

  // 保存路由历史
  update_history_router({ commit }, data) {
    commit("UPDATE_HISTORY_PATH", data);
  },
  /**
   * 更新cocos基本加载资源
   */
  update_cocos_baseassets({ commit }, data) {
    commit("UPDATE_COCOS_BASEASSETS", data);
  },

  /**
   * 设置用户信息
   */
  set_user_info({ commit }, data) {
    commit("UPDATE_USER_INFO", data);
  },

  /**
   * 修改Avatar装备
   */
  update_avatar_data({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("UPDATE_AVATAR_DATA", data);
      resolve(state.myAvatars);
    });
  },

  /**
   * 初始化Avatar装备
   */
  inite_avatar_data({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("INIT_AVATAR_DATA", data);
      resolve(state.myAvatars);
    });
  },

  /**
   * 初始化Avatar装备
   */
  set_cocos_start({ commit }, data) {
    commit("SET_COCOS_START", data);
  },
};

const getters = {
  profilePhoto: (state) => state.userInfo.profilePhoto,
  isVerified: (state) => state.userInfo.isVerified,
  realName: (state) => state.userInfo.realName,
  ycoin: (state) => state.userInfo.ycoin,
  nickName: (state) => state.userInfo.nickName,
  cocosStart: (state) => state.cocosStart,
  landId: (state) => state.landId,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
