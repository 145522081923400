<!-- 提示 -->
<template>
  <div :class="$style['toast-page-wrapper']" v-if="show">
    <div :class="$style['toast-page']">
      <span :class="$style['toast-icon']"
        ><van-icon :name="typeEmu[type]['icon']" :color="typeEmu[type]['iconColor']" size="18px"
      /></span>
      <p :class="$style['toast-text']">{{ text }}</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

export default {
  name: "toastIcon",
  data() {
    return {
      show: false,
      text: "",
      type: "info",
      typeEmu: {
        info: {
          icon: "info-o",
          iconColor: "#ABAAAB",
        },
        warning: {
          icon: "warning-o",
          iconColor: "#FF8B0F",
        },
        fail: {
          icon: "close",
          iconColor: "#ee0a24",
        },
        success: {
          icon: "passed",
          iconColor: "#665cff",
        },
      },
    };
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.toast-page-wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 40vh;
  z-index: 21000;
  margin: 0 auto;
  left: 0;
  right: 0;
  pointer-events: none;
}
.toast-page {
  position: relative;
  padding: 12px 16px 12px 16px;
  border-radius: 100px;
  background: #383838;
  max-width: 300px;
  text-align: left;
  padding-left: 38px;
}
.toast-icon {
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 18px;
  height: 18px;
}
.toast-text {
  margin: 0 0 0 5px;
  color: #abaaab;
  font-size: 14px;
  line-height: 16px;
  white-space: pre-line;
}
</style>
