/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import { fetch, post } from "@/utils/http.js";
import _ from "lodash";

/**
 * 消息
 */
export default {
  /**
   * 2.1 获取玩家消息数据（分页）
   * @param {*} pageNum
   * @param {*} pageSize
   * @param {*} type 消息类型 1系统消息 2个人消息
   * @param {*} messageStatus 通知状态 0未读 1已读 ""全部
   * @returns
   */
  messageList: (pageNum, pageSize, type, messageStatus) => {
    return fetch("/system/app/player/messageList", { pageNum, pageSize, type, messageStatus }).then(
      (response) => {
        return _.cloneDeep(response);
      },
    );
  },

  /**
   * 2.2 更新玩家消息状态为已读
   * @param {*} id 玩家消息ID
   * @returns
   */
  readMessage: (id) => {
    return post("/system/app/player/readMessage", { id }).then((response) => {
      return _.cloneDeep(response);
    });
  },
};
