<!-- 任务 金钱 -->
<template>
  <div class="experience-tips-page" @click="onClose()">
    <div class="experience-wrapper animate__animated animate__zoomIn">
      <div class="tips-img-bg">
        <div data-title1>
          <span>恭喜你，得到</span>
          <countTo
            ref="ct"
            :startVal="startVal"
            :endVal="endVal"
            :duration="1000"
            :autoplay="false"
          ></countTo>
          <span>点经验</span>
        </div>

        <img
          src="@/assets/icon/experience.svg"
          style="width: 40%; font-size: 0; margin-top: 40px"
          alt=""
        />
        <!-- <van-progress pivot-text="橙色" color="#f2826a" :percentage="25" /> -->
        <div class="line-bg" style="">
          <span data-number-count>1/8</span>
          <div class="line-position"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import countTo from "vue-count-to";
import { delay } from "@/utils/lib";
import { gsap } from "gsap";

export default {
  name: "experienceTipsComponent",
  components: { countTo },
  props: {},
  data() {
    return { startVal: 0, endVal: 2022 };
  },
  computed: {
    ...mapGetters({
      experienceNum: "tips/experienceNum",
    }),
  },
  watch: {},
  methods: {
    onClose() {
      this.$store.dispatch("change/hidden", "experienceTips");
    },
  },
  created() {
    this.endVal = this.experienceNum;
  },
  mounted() {
    delay(0.6).then(() => {
      this.$refs.ct.start();
      gsap.to(".line-position", { width: 130, duration: 1 });
    });
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.experience-tips-page {
  position: absolute !important;
  z-index: 500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparentize(#0d0c1d, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.experience-wrapper {
  position: relative;
  width: 233px;
  height: 230px;
  background: #121212;
  border-radius: 5px;
  display: flex;

  justify-content: center;
  align-items: center;
}

.tips-img-bg {
  width: 203px;
  height: 203px;
  position: relative;

  background-position: center;
  background-size: cover;
  background-image: url("@/assets/land/tipsbg.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  [data-title1] {
    font-size: $--font_size_32;
    color: $--color_text_1;
    margin-top: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    span {
      float: left;
    }
  }
}

.line-bg {
  margin: 30px 0 0 0;
  width: 185px;
  height: 12px;
  background: rgba(102, 92, 255, 0.15);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  [data-number-count] {
    font-size: 10px;
    color: #ffffff;
  }
}

.line-position {
  position: absolute;
  width: 30px;
  height: 12px;
  left: 0;
  background: #665cff;
  border-radius: 6px;
}
</style>
