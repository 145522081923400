const getters = {
  historyPaths: (state) => state.secne.historyPaths,
  cocosBaseAssets: (state) => state.secne.cocosBaseAssets,
  userInfo: (state) => state.secne.userInfo,
  userId: (state) => state.secne.userInfo.id,
  ycoin: (state) => state.secne.userInfo.ycoin,
  openId: (state) => state.secne.userInfo.openId,
  access_token: (state) => state.secne.access_token,
  avatarTypeList: (state) => state.secne.avatarTypeList,
  avatarAllObject: (state) => state.secne.avatarAllObject,
  equipGenderList: (state) => state.secne.equipGenderList,
  myAvatars: (state) => state.secne.myAvatars,
  myAvatarId: (state) => state.secne.myAvatarId,

  landId: (state) => state.secne.landId || 1,
  cmd: (state) => state.secne.secneData.cmd || "",
  landArg: (state) => state.secne.secneData.arg || {},

  mobile: (state) => state.device.mobile,
  tablet: (state) => state.device.tablet,
  desktop: (state) => state.device.desktop,
  portrait: (state) => state.device.portrait,
  landscape: (state) => state.device.landscape,
};
export default getters;
