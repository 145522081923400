/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import { fetch, post } from "@/utils/http.js";
import _ from "lodash";
import router from "../router";
import { getQueryStringByName, getIsWxClient } from "@/utils/lib";
import Cookies from "js-cookie";
import { getPlayerInfo } from "./login";

/**
 * 商城商户
 */
export default {
  /**
   * 2.3 商户（岛屿）第一期不需要
   * @returns
   */
  shopList: () => {
    return post("/system/app/shop/shopList").then((response) => {
      return response;
    });
  },

  /**
   * 2.4 获取热门推荐数据 推荐商户 岛屿
   * @returns
   */
  shopListFeature: () => {
    return post("/system/app/shop/shopListFeature").then((response) => {
      return _.orderBy(response, ["sort", "asc"]);
    });
  },

  /**
   * 2.6 获取商户信息
   * @param {*} id 商户ID
   * @returns
   */
  getShopById: (id) => {
    return post("/system/app/shop/getShopById", { id }).then((response) => {
      return response;
    });
  },

  /**
   * 2.7 获取商户的活动信息
   * @param {*} id 商户ID
   * @param {*} orderByColumn beginDate 按开始时间排序 不传则按创建时间
   * @param {*} isAsc asc升序 desc降序
   * @returns
   */
  getShopCampaign: (id = "", orderByColumn = "beginDate", isAsc = "asc") => {
    return post("/system/app/shop/getShopCampaign", { id, params: { orderByColumn, isAsc } }).then(
      (response) => {
        return response;
      },
    );
  },

  /**
   * 2.8 浏览量增加
   * @param {*} id 商户id
   * @returns
   */
  shopView: (id) => {
    return post("/system/app/shop/shopView", { id }).then((response) => {
      return response;
    });
  },

  /**
   * 6.1 获取商城推荐数据
   * @returns
   */
  productListFeature: () => {
    return post("/system/app/product/productListFeature").then((response) => {
      return response;
    });
  },

  /**
   * 6.2 商品列表（分页）
   * @param {*} pageNum
   * @param {*} pageSize
   * @param {*} productType 可选 商品类别：1 普通商品， 2 NFT
   * @param {*} hasEntity 可选 是否有实物：0 否， 1 是
   * @param {*} isFree 可选 是否免费：0 否， 1 是
   * @param {*} shopId 可选 商户ID
   * @returns
   */
  productList: (pageNum, pageSize, productType, hasEntity, isFree, shopId) => {
    return fetch("/system/app/product/productList", {
      pageNum,
      pageSize,
      productType,
      hasEntity,
      isFree,
      shopId,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 6.3 商品详情
   * @param {*} id 商品id
   * @returns
   */
  detail: (id) => {
    return post("/system/app/product/detail", { id });
  },

  /**
   * 6.4 预约预售中的商品
   * @param {*} id 商品id
   * @returns
   */
  booking: (id) => {
    return post("/system/app/product/booking", { id });
  },

  /**
   * 6.5 订单创建
   * 支持多个商品，如果包含nft商品(productType = "2")、需要确保用户已经身份验证和创建数字账户（见 1.7，1.8）
   * @param {*} currencyType 货币类型 0Y币  1钻石 2人民币
   * @param {*} shopId 商户ID
   * @param {*} details :[{"productId":14, //商品ID, "quantity":2 //商品数量},{"productId":15,"quantity":3}]
   * @returns
   */
  createOrder: (currencyType, shopId, details) => {
    return post(
      "/system/app/product/createOrder",
      Object.assign(
        { currencyType, shopId, details },
        {
          channelCode:
            getQueryStringByName("payChannelCode") || Cookies.get("payChannelCode") || "",
        },
      ),
    ).then((orderNo) => {
      if (currencyType != 0) {
        const wx = getIsWxClient() ? 1 : 0;
        router.replace("/Payment/?orderNo=" + orderNo + "&wx=" + wx);
      }
      return orderNo;
    });
  },

  /**
   * 6.5.2 创建带优惠券订单
   * 支持多个商品，如果包含nft商品(productType = "2")、需要确保用户已经身份验证和创建数字账户（见 1.7，1.8）
   * @param {*} currencyType 货币类型 0Y币  1钻石 2人民币
   * @param {*} shopId 商户ID
   * @param {*} questId 任务Id
   * @param {*} details :[{"productId":14, //商品ID, "quantity":2 //商品数量},{"productId":15,"quantity":3}]
   * @returns
   */
  createCouponOrder: (currencyType, shopId, questId, details) => {
    return post(
      "/system/app/product/createOrder",
      Object.assign(
        { currencyType, shopId, questId, details },
        { channelCode: getQueryStringByName("channelCode") || Cookies.get("channelCode") || "" },
      ),
    ).then((orderNo) => {
      if (currencyType != 0) {
        const wx = getIsWxClient() ? 1 : 0;
        router.replace("/Payment/?orderNo=" + orderNo + "&wx=" + wx);
      }
      return orderNo;
    });
  },

  /**
   * 6.6 玩家任务完成情况检查
   * 接收一个任务ID，比如装备中的 questId，预约商品的 questId
   *只要后台配置正确，该接口至少都会返回一个任务的详情，结构参考 4.7
   * @param {*} id 任务ID
   * @returns
   */
  questCheck: (id) => {
    return post("/quest/app/questCheck", { id });
  },

  /**
   * 6.7 Y币支付
   * 如果订单 货币类型 currencyType=0 调用此支付接口
   * @param {*} orderNo 订单号
   * @returns
   */
  payOrderY: (orderNo) => {
    return post("/system/app/product/payOrderY", { orderNo }).then((response) => {
      getPlayerInfo();
      return response;
    });
  },

  /**
   * 6.8 玩家预售商品预约信息
   * @param {*} presaleProductId 商品的预售ID
   * @returns
   */
  bookingInfo: (presaleProductId) => {
    return post("/system/app/product/bookingInfo", { presaleProductId });
  },

  /**
   * 6.9 订单详情
   * @param {*} orderNo
   * @returns
   */
  orderDetail: (orderNo) => {
    return post("/system/app/product/orderDetail", { orderNo });
  },

  /**
   * 6.10 浏览量增加
   * @param {*} id // 商品id"
   * @returns
   */
  productView: (id) => {
    return post("/system/app/product/productView", { id });
  },

  /**
   * 6.11 查询nft
   * @param {*} nftAssetId
   * @returns
   */
  queryAsset: (nftAssetId) => {
    return post("/system/app/product/queryAsset", { nftAssetId });
  },

  /**
   * 6.12 合辑商品列表
   * @param {*} pageNum
   * @param {*} pageSize
   * @param {*} id
   * @returns
   */
  productListByCollection: (pageNum, pageSize, id) => {
    return fetch("/system/app/product/productListByCollection", { pageNum, pageSize, id });
  },

  /**
   * 11.1 订单自动关闭分钟数
   * @returns
   */
  orderCloseMinute: () => {
    return fetch("/system/app/common/orderCloseMinute");
  },
};
