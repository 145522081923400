<!-- 横屏提示  -->
<template>
  <div :class="$style['global-main-content-tips ']">
    <div :class="$style['global-main-wrapper-tips']" v-if="!isHelp">
      <van-image fit="contain" width="120" :src="require('@/assets/icon/landscape_icon.svg')" />
      <p style="font-size: 16px; padding-top: 20px">为了更好的体验，请使用横屏浏览</p>
      <p style="font-size: 16px; color: #665cff; padding-top: 20px" @click="onOpenHelp()">
        无法横屏？查看<span style="padding-bottom: 2px; border-bottom: 1px solid #665cff"
          >帮助</span
        >
      </p>
      <!-- <span
        @click="onOpenHelp()"
        style="
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 18px;
          color: #6555ff;
          line-height: 24px;
        "
        >帮助</span
      > -->
    </div>

    <transition name="van-fade">
      <content-layout
        v-if="isHelp"
        :isFull="true"
        :isShowTopBack="true"
        :titleColor="'#6555ff'"
        rightTitleText="帮助"
        topTitleText="帮助"
        contentLandscapeWidth=""
        @back="onBack()"
      >
        <div
          style="
            padding: 10px 20px 0 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 16px;
            color: #ffffff;
          "
        >
          <p>如果无法进入横屏，请确认手机横屏模式是否 开启，开启方式如下：</p>
          <p style="margin-top: 10px; font-size: 14px; width: 100%">
            1、安卓手机 <br />(1) 前往 微信-设置-通用 中开启<br />
            (2) 点击屏幕右上角往下拉，开启“自动旋转”开关
          </p>
          <img src="@/assets/tips/help1.png" style="margin-top: 10px" />
          <img src="@/assets/tips/help3.png" style="margin-top: 10px" />
          <p style="margin-top: 15px; font-size: 14px; width: 100%">
            2、苹果手机请点击屏幕右上角往下拉，关闭“直向锁定”开关，即可开启
          </p>
          <img src="@/assets/tips/help2.png" style="margin-top: 10px" />
        </div>
      </content-layout>
    </transition>
  </div>
</template>

<script>
/* eslint-disable vue/valid-v-on */
import ContentLayout from "@/components/ContentLayout";

export default {
  name: "landscapeTips",
  components: { ContentLayout },
  props: {},
  data() {
    return {
      isHelp: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    onBack() {
      this.isHelp = false;
    },
    onOpenHelp() {
      this.isHelp = true;
    },
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.global-main-content-tips {
  position: relative;
  width: 100%;
  height: 100%;
}

.global-main-wrapper-tips {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: $--color_primary_1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $--color_text_1;
}
</style>
