/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import store from "../store";
import myCenterApi from "@/api/myCenter";
import { yaolandMiniServerPost } from "@/utils/http";
import { yaojingToast } from "@/components/global";
import MD5 from "blueimp-md5";
import { generateMixCode } from "@/utils/lib";
/**
 * 创建地铁零售机兑换码
 * @param {*} playerId 用户id
 * @param {*} shopId 商城id
 * @param {*} mobile 手机
 * @param {*} couponId 产品品类id
 * @param {*} limitedDay 是否限制明天一次0无限制 1限制
 */
export const createPickCode = async (playerId, shopId, mobile, couponId, limitedDay) => {
  try {
    // const templateList = await myCenterApi.pickCodeTemplateList();
    // if (templateList) {
    let pickCodeInfoId = "00000000";
    // 家果园
    if (couponId == "jgy") {
      // pickCodeInfoId = templateList.result[0].id;
      pickCodeInfoId = "1547439220384714754";
    }
    const res = await myCenterApi.create(playerId, shopId, mobile, pickCodeInfoId, 0);
    if (res.success === 1) {
      store.dispatch("change/show", {
        name: "rewardCoupon",
        params: {
          title: "恭喜获得",
          img: "https://obs-helf.cucloud.cn/bucket/assets/jiguoyuan1.png",
          description: "佳果源小青柠复合果汁",
          subDescription: "已放入“我的”—“兑换券”中",
        },
      });
    } else {
      yaojingToast(res.message, "warning");
    }
    // } else {
    //   console.log("模板错误");
    // }
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * nft兑换码
 * @param {*} playerId
 * @param {*} productId
 * @param {*} code
 * @param {*} phone
 * @returns
 */
export const verificationCode = (playerId, productId, code, phone) => {
  const data = {
    phone: phone,
    code: code,
    playerId: playerId,
    productId: productId,
  };

  const timestamp = Math.round(new Date().getTime() / 1000).toString();
  const nonceStr = generateMixCode(16);
  const key = "MJwnhDwmc09ZpUKZ8wBglY37wVIuvifE";
  const stringSignTemp =
    "nonceStr=" +
    nonceStr +
    "&timestamp=" +
    timestamp +
    "&data=" +
    JSON.stringify(data) +
    "&key=" +
    key;
  const sign = MD5(stringSignTemp).toUpperCase();

  return yaolandMiniServerPost("/checkNftRewardCode", {
    playerId,
    productId,
    code,
    phone,
    timestamp,
    nonceStr,
    sign,
  });
};

/**
 * 查询自己可以兑换的优惠券列表
 * @param {*} partnerId //合作伙伴ID，也就是岛ID
 * @param {*} phone
 * @returns
 */
export const queryOwnNftRewardList = () => {
  return yaolandMiniServerPost("/queryOwnNftRewardList", {
    partnerId: store.getters.landId,
    phone: store.getters.userInfo.mobile,
  });
};
