<!-- 实名制 -->
<template>
  <div class="realname-tips-page">
    <content-layout
      :isFull="true"
      topTitleText="实名认证"
      rightTitleText="实名认证"
      :contentLandscapeWidth="$getters.mobile && $getters.landscape ? '100%' : ''"
      @back="onBack()"
      @close="onClose()"
    >
      <div
        style="
          width: 100%;
          height: 100%;
          background-color: #0d0c1d;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <div class="realname-tips-title">
          按照法律规定，使用区块链服务时需提供身份信息，请填写真实、有效并与注册手机相符的身份信息。当前登录手机:&nbsp;{{
            mobile()
          }}
        </div>
        <div class="realname-tips-wrapper">
          <div class="realname-tips-input-wrapper">
            <div class="realname-tips-name">
              <p data-name>姓名</p>
              <van-field
                :disabled="disabled"
                clearable
                v-model="realName"
                placeholder="请输入姓名"
                maxlength="11"
                class="realname-tips-input"
              ></van-field>
            </div>
            <div class="realname-tips-idcard">
              <p data-idcard>身份证号码</p>
              <van-field
                :disabled="disabled"
                v-model="idCard"
                clearable
                maxlength="18"
                placeholder="请输入身份证号码"
                class="realname-tips-input"
              ></van-field>
            </div>
          </div>
          <van-button
            :disabled="disabled"
            color="#665CFF"
            class="next-button"
            :loading="loading"
            type="info"
            loading-text="身份验证中"
            @click="onAnalyze()"
            >{{ buttonName }}
          </van-button>
        </div>
        <!-- 提示 -->
        <transition name="van-fade">
          <toast text="请输入姓名" class="tips1" v-if="isTips1"></toast>
        </transition>

        <transition name="van-fade">
          <toast text="身份证号码有误" class="tips2" v-if="isTips2"></toast>
        </transition>
      </div>

      <!-- <loading-tips v-if="loading" style="position: absolute; top: 0"></loading-tips> -->
    </content-layout>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import ContentLayout from "@/components/ContentLayout";
import Toast from "@/components/tips/realname/Toast";
import LoadingTips from "@/components/tips/LoadingTips";
import realNameApi from "@/api/realName";
import { delay, analyzeIDCard, maskMobile } from "@/utils/lib";
import { getPlayerInfo } from "@/api/login";

export default {
  name: "realName",
  components: { ContentLayout, Toast, LoadingTips },
  props: {},
  data() {
    return {
      winType: 0,
      isTips1: false,
      isTips2: false,
      realName: "",
      idCard: "",
      disabled: false,
      loading: false,
      buttonName: "下一步",
      // mobile: "186****9152",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  watch: {},
  methods: {
    mobile() {
      const str = maskMobile(this.userInfo.mobile);
      return str;
    },
    onClose() {
      this.$store.dispatch("change/hidden", "realName");
    },
    onBack() {
      this.$store.dispatch("change/hidden", "realName");
    },
    // 验证
    async onAnalyze() {
      if (this.realName == "") {
        // this.isTips1 = true;
        this.$yaojingToast("请输入姓名", "warning");
        // await delay(1);
        // this.isTips1 = false;
        return;
      }
      // 身份证验证
      const reg =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (!reg.test(this.idCard)) {
        this.$yaojingToast("身份证号码有误", "warning");
        // this.isTips2 = true;
        // await delay(1);
        // this.isTips2 = false;
        return;
      }
      // 18岁
      if (analyzeIDCard(this.idCard) == false) {
        this.$yaojingToast("认证失败，根据相关法规年满18岁\n才能进行数字藏品的购买", "warning");
        return;
      }
      // 弹窗提示
      try {
        await this.$yaojingConfirm({
          title: "实名认证",
          text: "认证后不可修改，请核验后继续",
          cancelButtonText: "返回",
          confirmButtonText: "认证",
        });
        try {
          this.loading = true;
          this.disabled = true;
          await realNameApi.playerVerified(this.realName, this.idCard);
          await realNameApi.createNftAccount();
          await getPlayerInfo();
          await delay(0.2);
          this.$store.dispatch("change/hidden", "realName");
          this.$yaojingToast("认证成功，请继续购买", "success");
        } catch (error) {
          this.loading = false;
          this.disabled = false;
          this.$yaojingToast("认证失败，请检查姓名或身份证号码", "warning");
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
  },
  created() {
    if (this.userInfo.isVerified == 1) {
      this.realName = this.userInfo.realName;
      this.idCard = this.userInfo.identityCardId;
      this.disabled = true;
      this.buttonName = "已认证";
    } else {
      this.disabled = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.realname-tips-page {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.realname-tips-title {
  width: 100%;
  padding: 5px 30px 5px 30px;
  background: #38847c;
  font-size: $--font_size_24;
  color: $--color_text_1;
}

.realname-tips-name {
  [data-name] {
    font-size: $--font_size_32;
    color: $--color_text_4;
  }
}

.realname-tips-idcard {
  margin: 10px 0 0 0;
  [data-idcard] {
    font-size: $--font_size_32;
    color: $--color_text_4;
  }
}

.realname-tips-input {
  margin: 10px 0 0 0;
  font-size: 18px;
  height: 45px;
  border: 1px solid #525252;
  padding-left: 30px;
  background-color: $--color_primary_1;
}

.next-button {
  margin: 30px 0 0 0;
  width: 100%;
  height: 45px;
}

.realname-tips-wrapper {
  position: relative;
  padding: 15px 30px 0px 30px;
  width: 100%;

  ::v-deep .van-cell::after {
    border-bottom: 0;
  }
  ::v-deep .van-field__clear {
    color: $--color_text_4;
    position: absolute;
    right: 0px;
  }
  ::v-deep .van-field__control {
    color: $--color_text_1;
  }
  ::v-deep .van-field__control::-webkit-input-placeholder {
    color: $--color_text_6;
  }
  // ::v-deep .van-field__control:disabled {
  //   color: $--color_text_6;
  //   color: aqua;
  // }
}

.tips1 {
  position: absolute;
  top: 130px;
}
.tips2 {
  position: absolute;
  top: 215px;
}

// 手机横屏
html.mobile.landscape {
  .realname-tips-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 50px 0px 50px;
    // flex-direction: row;
    margin: 60px 0 0 0;
  }

  .realname-tips-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .realname-tips-idcard {
    margin: 0 0 0 0;
  }

  .realname-tips-name {
    width: 315px;
  }

  .realname-tips-input {
    width: 315px;
  }

  .realname-tips-input {
    height: 55px;
  }

  .next-button {
    margin: 30px 0 0 0;
    width: 315px;
    height: 50px;
  }

  .tips1 {
    position: absolute;
    top: 160px;
    left: 100px;
  }
  .tips2 {
    position: absolute;
    top: 160px;
    right: 100px;
  }
}
</style>
