<!-- 任务 礼物 -->
<template>
  <div :class="$style['personality-tips-page']">
    <span :class="$style['personality-close-icon']" @click="handleCloseTip">
      <van-icon name="cross" :size="20" />
    </span>

    <div :class="$style['personality-wrapper']" v-if="detail">
      <div data-img-wrapper @click="handleRotateImg">
        <div data-img-wrapper-box :style="{ transform: 'rotateY(' + rotateY + 'deg)' }">
          <img
            v-for="(imgitem, index) in imgUrls"
            :key="'data-img-wrapper-box-img' + index"
            :data-img-wrapper-box-img="index"
            :src="imgitem"
            alt=""
          />
        </div>
        <p
          v-if="imgUrls.length > 1"
          style="
            font-size: 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
          "
        >
          请点击<span style="font-size: 14px; color: #00ff01">翻转卡片</span>查看详细性格信息
        </p>
      </div>
      <div data-content>
        <div>
          <p data-content-title>恭喜，您的完整性格已生成！</p>
          <p data-content-subtitle>{{ detail.productName }}</p>
          <div data-content-type-content>
            <p data-content-type>{{ rarityEmu[detail.rarity] }}</p>
          </div>

          <div data-content-land>
            <van-image
              width="25"
              height="25"
              round
              fit="cover"
              style="background-color: #0d0c1d"
              :src="detail.publisherImage"
            >
            </van-image>
            {{ detail.publisher }}
          </div>
        </div>

        <div>
          <div data-content-money>¥ {{ detail.unitPrice }}</div>
          <div data-content-btn>
            <van-button
              data-buy
              color="#665CFF"
              :disabled="detail.stockQuantity == 0"
              @click="handleGoToOrderDetail"
            >
              {{ detail.stockQuantity == 0 ? "无库存" : "购买" }}
            </van-button>
            <van-button data-more color="#383838" @click="handleGoToShop"> 查看更多 </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { delay } from "@/utils/lib";
import shopApi from "@/api/shop";
import { BuyMixin } from "@/mixin/shop/buyMixin";
export default {
  name: "personality-page",
  mixins: [BuyMixin],
  components: {},
  props: {},
  data() {
    return {
      rotateY: 0,
      detail: "",
      rarityEmu: {
        0: "普通",
        1: "精良",
        2: "稀有",
        3: "传说",
      },
      imgUrls: [],
    };
  },
  watch: {},
  methods: {
    /**
     * 点击翻转卡牌
     */
    handleRotateImg() {
      if (this.imgUrls.length < 2) return;
      this.rotateY == 0 ? (this.rotateY = 180) : (this.rotateY = 0);
    },

    handleCloseTip() {
      this.$store.dispatch("change/hidden", "personality");
    },

    handleGetProductDetail() {
      shopApi.detail(this.$getters["change/personality"]["productId"]).then((res) => {
        this.detail = res;
        this.imgUrls = res.imageUrls.split(",").slice(0, 2);
      });
    },

    handleGoToOrderDetail() {
      if (this.$getters.userInfo.isVerified == 0) {
        this.$yaojingConfirm({
          title: "实名认证",
          text: "需实名认证后才可购买数字藏品",
          cancelButtonText: "取消",
          confirmButtonText: "认证",
        }).then(() => {
          this.$store.dispatch("change/show", "realName");
        });
        return;
      }

      const details = [
        {
          productId: this.detail.id,
          quantity: 1,
        },
      ];
      shopApi
        .createOrder(2, this.$getters.landId, details)
        .then(() => {
          this.$store.dispatch("change/hidden", "personality");
        })
        .catch(() => {
          this.handleGetProductDetail();
        });
    },

    handleGoToShop() {
      this.$store.dispatch("change/hidden", "personality");

      this.$router.replace("/Land/Shop?shopType=2&coupon=0" + "&shopId=" + this.$getters.landId);
    },
  },
  // beforeDestroy() {
  //   if (this.$getters["change/personality"]["actionType"]) {
  //     this.$clearCurRewardAction();
  //   }
  // },
  created() {
    this.handleGetProductDetail();
    // delay(1.2).then(() => {
    //   this.handleCloseTip();
    // });
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.personality-close-icon {
  width: 35px;
  height: 35px;
  padding: 15px 15px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.personality-tips-page {
  position: absolute;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  padding: 25px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.personality-wrapper {
  position: relative;
  width: auto;
  margin: 0 auto;
  height: 100%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  [data-img-wrapper] {
    height: 100%;
    padding-bottom: 24px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    [data-img-wrapper-box] {
      height: 100%;
      position: relative;
      max-width: 280px;
      font-size: 0px;
      transform-style: preserve-3d;
      transition: transform 0.32s ease;
    }
    [data-img-wrapper-box-img="0"] {
      backface-visibility: hidden;
      z-index: 2;
    }
    [data-img-wrapper-box-img="1"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      backface-visibility: hidden;
      transform: rotateY(180deg);
    }
  }
  [data-content] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 14px;
    padding-left: 30px;
    [data-content-title] {
      font-size: 20px;
      color: #fb00cf;
      line-height: 27px;
      padding-bottom: 17px;
    }
    [data-content-subtitle] {
      font-size: 16px;
      line-height: 21px;
      padding-bottom: 5px;
    }
    [data-content-type-content] {
      padding-bottom: 18px;
    }
    [data-content-type] {
      border-radius: 7px;
      border: 1px solid #00ff01;
      font-size: 9px;
      color: #00ff01;
      line-height: 14px;
      padding: 0 6px;
      display: inline-block;
    }
    [data-content-land] {
      position: relative;
      padding-left: 30px;
      line-height: 25px;
      :global(.van-image) {
        position: absolute;
        left: 0;
      }
    }
    [data-content-money] {
      font-size: 30px;
      color: #fe9636;
      line-height: 40px;
      padding-bottom: 8px;
    }
    [data-content-btn] {
      [data-buy] {
        height: 36px;
        width: 120px;
        font-size: 14px;
        line-height: 19px;
      }
      [data-more] {
        margin-left: 20px;
        height: 36px;
        width: 120px;
        font-size: 14px;
        line-height: 19px;
        border-color: #665cff !important;
        color: #665cff !important;
      }
    }
  }
}
</style>
