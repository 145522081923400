<!-- 任务 金钱 -->
<template>
  <div :class="$style['lottery-gird-mainwrapper']">
    <div
      :class="$style['lottery-gird-turntable-box']"
      :style="{ transform: 'scale(' + scale + ')' + 'translate(-50%, -50%)' }"
    >
      <p
        style="
          transform: translateY(-100%);
          padding: 0 38px 12px 38px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        "
      >
        <img
          src="@/assets/land/530/one-river-lottery-img.png"
          style="width: 100%; max-width: 300px"
          alt=""
        />
      </p>
      <div
        :class="$style['lottery-gird-turntable-item']"
        v-for="item in options"
        @click="handleStartLottery(item)"
        :key="'oneriver' + item.id"
        :data-active="activeIndex === item.activeIndex"
        :style="{ 'background-image': 'url(' + item.lotteryImg + ')' }"
      ></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { delay } from "@/utils/lib";

export default {
  name: "lottery-gird",
  data() {
    return {
      scale: 1,
      activeIndex: 99,
      list: [
        {
          title: 1,
          activeIndex: 1,
          content: "谢谢惠顾",
        },
        {
          title: 2,
          activeIndex: 2,
          content: "一等奖",
        },
        {
          title: 3,
          activeIndex: 3,
          content: "谢谢惠顾",
        },
        {
          title: 8,
          activeIndex: 8,
          content: "谢谢惠顾",
        },
        {
          title: 0,
          activeIndex: 0,
        },
        {
          title: 4,
          activeIndex: 4,
          content: "三等奖",
        },
        {
          title: 7,
          activeIndex: 7,
          content: "谢谢惠顾",
        },
        {
          title: 6,
          activeIndex: 6,
          content: "二等奖",
        },
        {
          title: 5,
          activeIndex: 5,
          content: "谢谢惠顾",
        },
      ],
      cicles: 0, //定义圈数
      time: 400, //定义速度
    };
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {},
  methods: {
    handleStartLottery(item) {
      if (item.isStartBtn !== 1) return;
      this.$emit("lotteryStart");
    },
    startView(selectIndex, fn) {
      // console.log(this.activeIndex, selectIndex)
      if (this.activeIndex === selectIndex && this.cicles < 4) {
        //圈数+1
        this.cicles += 1;
        //速度加快
        this.time -= 100;
      }
      if (this.activeIndex === selectIndex && this.cicles >= 4) {
        this.cicles += 1;
        //圈数>4时速度减慢
        this.time += 100;
      }
      if (this.cicles > 7 && this.activeIndex === selectIndex) {
        //圈数为8时停止
        const result = this.options.find((item) => {
          return item.activeIndex === selectIndex;
        });
        delay(1).then(() => {
          this.$emit("lotteryEnd", result);
        });
        return;
      }
      let timer = setTimeout(() => {
        //定时器轮询
        if (this.activeIndex < 8) {
          this.activeIndex++;
        } else {
          this.activeIndex = 1;
        }
        //每走一步清除定时器再递归调用，实现连续效果
        clearTimeout(timer);
        this.startView(selectIndex, fn);
      }, this.time);
    },

    resetScale() {
      const window_w = document.body.clientWidth - 60;
      const window_H = document.body.clientHeight - 60;
      const scalePre = window_w / window_H;
      if (scalePre > 1) {
        this.scale = window_H / 664;
      } else {
        this.scale = window_w / 664;
      }

      if (this.scale > 1.2) {
        this.scale = 1.2;
      }
    },
  },
  created() {},
  mounted() {
    this.resetScale();
  },
};
</script>

<style lang="scss" module>
.lottery-gird-mainwrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.7);
}

.lottery-gird-turntable-box {
  position: absolute;
  transform-origin: top left;
  top: 50%;
  left: 50%;
  width: 664px;
  height: 664px;
  background-color: #3528ae;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 15px;
  box-shadow: 0 0 5px 2px white inset;
}

.lottery-gird-turntable-item {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin: 8px;
  z-index: 1;
  &[data-active] {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(240, 7, 124, 0.5) !important;
    }
  }
}
</style>
