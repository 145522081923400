import dayjs from "dayjs";
import store from "../../../store";
import { getThirdPoints } from "@/api/thirdApi/qingyibo";
import Cookies from "js-cookie";
import myCenterApi from "@/api/myCenter";
import { yaojingToast } from "@/components/global";
export default (params) => {
  console.log("----------------params -------------", params);
  const taskID = myCenterApi.desEncode(params.description);
  // const userID = myCenterApi.desEncode("1752");
  // var userID = myCenterApi.desDecode("udWEfj2JgL3FJkC6FFc7Zg%3D%3D");
  // Cookies.set("qing_userId", userID, { expires: 31 });
  var userID =
    // myCenterApi.desEncode(Cookies.get("qing_userId")) || myCenterApi.desEncode("23050983124");
    myCenterApi.desEncode(Cookies.get("qing_userId")) || myCenterApi.desEncode("23050983124");
  const points = myCenterApi.desEncode(params.num);
  const timestamp = dayjs().unix();
  const token = Cookies.get("qing_token") || "MOxinrui";
  const signature = myCenterApi.desEncode(token + timestamp);
  const toastText = params.title;
  store.dispatch("landQuest/get_land_quests"); // 完成任务，更新任务图标的数字信息
  getThirdPoints(taskID, userID, points, timestamp, signature, token).then((res) => {
    console.log("===完成青艺博任务增加积分res", res);
    yaojingToast(toastText, "success");
  });
};
