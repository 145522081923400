import { render, staticRenderFns } from "./RewardY.vue?vue&type=template&id=7fc46f03&"
import script from "./RewardY.vue?vue&type=script&lang=js&"
export * from "./RewardY.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports