<!-- 横屏提示  -->
<template>
  <div :class="$style['tips-wrapper']">
    <!-- 任务提示 -->
    <landscape-tips v-if="showLandTips"></landscape-tips>
    <!-- 金钱 -->
    <transition name="van-fade">
      <money-tips v-if="$getters['change/moneyTips']"></money-tips>
    </transition>

    <!-- 拍照框 -->
    <transition name="van-fade">
      <take-photo v-if="$getters['change/takePhoto']"></take-photo>
    </transition>

    <!-- 分岛内-完成任务获取的礼物提示 -->
    <transition name="van-fade">
      <personality v-if="$getters['change/personality']"></personality>
    </transition>

    <!-- 任务- 获取碎片 弹窗-->
    <transition name="van-fade">
      <get-fragment v-if="$getters['change/getFragment']"></get-fragment>
    </transition>

    <!-- 任务-获取空碎片 弹窗 -->
    <transition name="van-fade">
      <get-null-fragment v-if="$getters['change/getNullFragment']"></get-null-fragment>
    </transition>

    <!-- 分岛内 - 后置动作 -->
    <transition name="van-fade">
      <buy-reward v-if="$getters['change/rewardCouponBuy']"></buy-reward>
    </transition>

    <!-- 经验值 -->
    <transition name="van-fade">
      <experience-tips v-if="$getters['change/experienceTips']"></experience-tips>
    </transition>

    <!-- 实名制 - 填写信息 -->
    <transition name="van-fade">
      <real-name v-if="$getters['change/realName']"></real-name>
    </transition>

    <!-- 修改昵称 -->
    <transition name="van-fade">
      <my-setting-nickname v-if="$getters['change/mySettingNickName']"></my-setting-nickname>
    </transition>

    <!-- 任务 - 奖励弹窗 - 装备-->
    <transition name="van-fade">
      <reward-equips-buy v-if="$getters['change/rewardEquipsBuy']"></reward-equips-buy>
    </transition>

    <!-- 任务 - 奖励弹窗 - Y币-->
    <transition name="van-fade">
      <reward-y v-if="$getters['change/rewardY']"></reward-y>
    </transition>
    <!-- 任务 - 奖励弹窗 - 经验-->
    <transition name="van-fade">
      <reward-experience v-if="$getters['change/rewardExperience']"></reward-experience>
    </transition>
    <!-- 任务 - 奖励弹窗 - 装备-->
    <transition name="van-fade">
      <reward-equips v-if="$getters['change/rewardEquips']"></reward-equips>
    </transition>
    <!-- 任务 - 奖励弹窗 - 装备-->
    <transition name="van-fade">
      <reward-space v-if="$getters['change/rewardSpace']"></reward-space>
    </transition>
    <!-- 任务 - 奖励弹窗 - 装备-->
    <transition name="van-fade">
      <reward-feature v-if="$getters['change/rewardFeature']"></reward-feature>
    </transition>
    <!-- 任务 - 奖励弹窗 - 装备-->
    <transition name="van-fade">
      <reward-coupon v-if="$getters['change/rewardCoupon']"></reward-coupon>
    </transition>

    <!-- 任务 - 奖励弹窗 - 装备-->
    <transition name="van-fade">
      <reward-nft v-if="$getters['change/rewardNFT']"></reward-nft>
    </transition>

    <!-- 任务 - 后置动作 - 打开视频框-->
    <transition name="van-fade">
      <play-video v-if="$getters['change/afterActionPlayVideo']"></play-video>
    </transition>

    <!-- 任务 - 后置动作 - 打开奖状-->
    <transition name="van-fade">
      <prize-image v-if="$getters['change/afterPrizeImage']"></prize-image>
    </transition>
    <!-- 任务 - 后置动作 - 打开Iframe-->
    <transition name="van-fade">
      <open-iframe v-if="$getters['change/afterActionOpenIframe']"></open-iframe>
    </transition>

    <!-- 任务 - 后置动作 - 打开Iframe-->
    <transition name="van-fade">
      <hydeout-dacall v-if="$getters['change/hydeoutDacall']"></hydeout-dacall>
    </transition>

    <transition name="van-fade">
      <wx-share v-if="$getters['change/wxShareTips']"></wx-share>
    </transition>

    <transition name="van-fade">
      <photo-share v-if="$getters['change/photoShareTips']"></photo-share>
    </transition>

    <transition name="van-fade">
      <buy-share v-if="$getters['change/buyShareTips']"></buy-share>
    </transition>

    <transition name="van-fade">
      <hydeout-rank v-if="$getters['change/hydeoutRank']"></hydeout-rank>
    </transition>

    <transition name="van-fade">
      <download-share v-if="$getters['change/downloadShareTips']"></download-share>
    </transition>

    <transition name="van-fade">
      <live-stream263 v-if="$getters['change/liveStream263']"></live-stream263>
    </transition>

    <transition name="van-fade">
      <image-view v-if="$getters['change/imgTipsView']"></image-view>
    </transition>

    <transition name="van-fade">
      <show-photo v-if="$getters['change/showPhoto']"></show-photo>
    </transition>
    <!-- nft公益证书 -->
    <transition name="van-fade">
      <nft-coupon v-if="$getters['change/nftCoupon']"></nft-coupon>
    </transition>

    <transition name="van-fade">
      <space-cover v-if="$getters['change/chooseSpaceCover']" />
    </transition>

    <transition name="van-fade">
      <lottery-gird v-if="$getters['change/lotteryGird']"></lottery-gird>
    </transition>

    <transition name="van-fade">
      <land-img v-if="$getters['change/openLandImg']"></land-img>
    </transition>
  </div>
</template>

<script>
import LandscapeTips from "@/components/tips/LandscapeTips";
import MoneyTips from "@/components/tips/MoneyTips";
import GetFragment from "@/components/tips/fragment/GetFragment";
import GetNullFragment from "@/components/tips/fragment/GetNullFragment";
import BuyReward from "@/components/tips/buyReward/BuyReward";
import personality from "@/components/tips/personality/personality";
import ExperienceTips from "@/components/tips/ExperienceTips";
import RealName from "@/components/tips/realname/RealName";
import MySettingNickname from "@/components/tips/MySettingNickname";
import RewardY from "@/components/tips/reward/RewardY";
import RewardExperience from "@/components/tips/reward/RewardExperience";
import RewardEquips from "@/components/tips/reward/RewardEquips";
import RewardEquipsBuy from "@/components/tips/afterAction/RewardEquipsBuy";
import RewardSpace from "@/components/tips/reward/RewardSpace";
import RewardFeature from "@/components/tips/reward/RewardFeature";
import RewardCoupon from "@/components/tips/reward/RewardCoupon";
import PlayVideo from "@/components/tips/afterAction/PlayVideo";
import OpenIframe from "@/components/tips/afterAction/OpenIframe";
import TakePhoto from "@/components/tips/TakePhoto";
import PrizeImage from "@/components/tips/afterAction/PrizeImage";
import WxShare from "@/components/tips/share/WxShare";
import PhotoShare from "@/components/tips/share/PhotoShare";
import BuyShare from "@/components/tips/share/BuyShare";
import HydeoutDacall from "@/components/land/hydeout/HydeoutDacall";
import HydeoutRank from "@/components/land/hydeout/HydeoutRank";
import DownloadShare from "@/components/tips/share/DownloadShare";
import LiveStream263 from "@/components/tips/liveStream/LiveStream263";
import ImageView from "@/components/tips/ImageView";
import RewardNFT from "@/components/tips/reward/RewardNFT";
import ShowPhoto from "@/components/tips/ShowPhoto";
import NftCoupon from "@/components/tips/NftCoupon";
import SpaceCover from "@/components/space/create/SpaceCover";
import LotteryGird from "@/components/tips/LotteryGird";
import LandImg from "@/components/tips/LandImg";

export default {
  name: "tips-wrapper",
  data() {
    return {};
  },
  computed: {
    showLandTips() {
      const landscapeIncludeRoute = ["mySpace", "landHome", "landShop", "ShopDetail"];
      const landscapeWhitelandId = ["9", "530"];
      if (landscapeWhitelandId.indexOf(this.$getters.landId + "") > -1) {
        return false;
      } else {
        return this.$getters.portrait && landscapeIncludeRoute.indexOf(this.$route.name) > -1;
      }
    },
  },
  components: {
    MoneyTips,
    LandscapeTips,
    GetFragment,
    GetNullFragment,
    BuyReward,
    personality,
    ExperienceTips,
    RealName,
    MySettingNickname,
    RewardY,
    RewardExperience,
    RewardEquips,
    RewardEquipsBuy,
    RewardSpace,
    RewardFeature,
    RewardCoupon,
    PlayVideo,
    OpenIframe,
    TakePhoto,
    PrizeImage,
    HydeoutDacall,
    HydeoutRank,
    WxShare,
    PhotoShare,
    BuyShare,
    DownloadShare,
    LiveStream263,
    ImageView,
    RewardNft: RewardNFT,
    ShowPhoto,
    NftCoupon,
    SpaceCover,
    LotteryGird,
    LandImg,
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.tips-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  pointer-events: none;
  & > div {
    pointer-events: auto;
  }
}
</style>
