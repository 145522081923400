<!-- 弹窗提示 -->
<template>
  <div :class="$style['countdown-wrapper']"><span>00</span> <span>04</span> <span>28</span></div>
</template>

<script>
export default {
  name: "img-load-wrapper",
  data() {
    return {};
  },
  mounted() {
    this.$imagesLoaded(this.$refs.contentimgwrapper, () => {
      this.$emit("imgload");
    });
  },
};
</script>
<style lang="scss" module>
@import "@/assets/themes.scss";
.countdown-wrapper {
  color: $--color_text_1;
  font-size: 10px;
  line-height: 14px;
  & > span {
    line-height: 14px;
    height: 14px;
    padding: 0 3px;
    border-radius: 2px;
    background-color: transparentize(#000000, 0.9);
    &:nth-last-of-type(1) {
      margin-right: 3px;
    }
  }
}
</style>
