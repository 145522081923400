<template>
  <div :class="$style['avatar-name-wrapper']" id="cocosReferenceEl_3">
    <p data-avatar-name-wrapper-label>创建昵称</p>

    <van-field
      :class="$style['nickname-input']"
      center
      clearable
      maxlength="10"
      v-model="nickName"
      placeholder="请输入2-10个字符的昵称"
      class="space-create-input"
    />

    <div style="padding-top: 18px; position: absolute; bottom: 0; left: 0; width: 100%">
      <van-button
        :class="$style['avatar-name-btn']"
        :color="$global.color_primary_2"
        @click="handleSave"
        >保存</van-button
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getPlayerInfo, updatePlayerInfo } from "@/api/login";
import { checktext } from "@/api/nodeapi";
export default {
  name: "avatar_setting",
  data() {
    return {
      nickName: "",
    };
  },
  methods: {
    handleClose() {
      this.$store.dispatch("change/hidden", "avatarName");
    },
    async handleSave() {
      if (this.nickName.length < 2 || this.nickName.length > 20) {
        this.$yaojingToast("请输入2-20个字符的昵称", "warning");
        return;
      }

      const reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
      if (!reg.test(this.nickName)) {
        this.$yaojingToast("昵称只能中文，数字，字母", "warning");
        return;
      }
      // 违规检查
      try {
        const res = await checktext(this.nickName);
        if (res.conclusionType == 1) {
          this.changeNiceName();
        } else {
          this.$yaojingToast("昵称有违禁词", "warning");
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    // 昵称修改
    async changeNiceName() {
      try {
        const res = await updatePlayerInfo({
          id: this.$getters.userInfo.id,
          nickName: this.nickName,
        });
        await getPlayerInfo();
        this.$yaojingToast("昵称修改成功", "success");
        this.$emit("savename");
      } catch (error) {
        this.$yaojingToast("昵称修改失败", "warning");
        throw new Error(error);
      }
    },
  },
};
</script>

<style lang="scss" module>
@import "../../assets/themes.scss";
.avatar-name-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: #0d0c1d;
  color: white;
  padding: 14px;
  [data-avatar-name-wrapper-label] {
    font-size: 18px;
    padding-bottom: 24px;
  }
}

.avatar-name-btn {
  font-size: $--font_size_24;
  width: 100%;
}

.nickname-input {
  height: 55px;
  border: 1px solid #525252;
  width: 100%;
  outline: none;
  background-color: transparent;
  font-size: $--font_size_4;
  padding: 0 14px;
  text-align: center;
  color: white;
  &::after {
    display: none;
  }
  input {
    color: white !important;
  }
}
</style>
