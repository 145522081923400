import store from "../../../store";
import shopApi from "@/api/shop";
import { clearCurRewardAction } from "@/utils/taskfactory.js";
export default (params) => {
  console.log("----------------params -------------", params);
  shopApi.detail(params.productId).then((res) => {
    if (res.stockQuantity * 1 > 0) {
      store.dispatch("change/show", {
        name: "rewardCouponBuy",
        params,
      });
    } else {
      clearCurRewardAction();
    }
  });
};
