var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style['content-main-layout'],style:({ 'z-index': _vm.isFull ? '201' : '6' }),attrs:{"data-content-main-layout":""}},[(_vm.isShowMask)?_c('div',{class:_vm.$style['content-main-mask'],attrs:{"data-content-main-mask":""},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_c('div',{class:_vm.$style['content-wrapper'],style:({
      width: _vm.contentLandscapeWidth,
      'padding-top': _vm.showTopTitle ? '52px' : '0',
      'padding-right': _vm.showRightTitle ? '54px' : '0',
      'padding-bottom': _vm.isFull || _vm.$getters.landscape ? '0' : '92px',
    }),attrs:{"data-content-wrapper":""}},[(_vm.showTopTitle)?_c('div',{class:_vm.$style['content-layout-title'],style:({
        'padding-right': _vm.showRightTitle ? '54px' : '0',
      })},[_c('div',{staticStyle:{"position":"relative","width":"100%","height":"100%"}},[(_vm.$slots.topTitle)?_vm._t("topTitle"):[(_vm.isFull && _vm.isShowTopBack)?_c('span',{attrs:{"data-content-layout-title-back":""},on:{"click":function($event){return _vm.$emit('back')}}},[_c('van-icon',{attrs:{"name":_vm.leftIconName,"color":"white"}})],1):_vm._e(),_c('p',{style:({ color: _vm.titleColor }),attrs:{"data-content-layout-title-text":""}},[_vm._v(_vm._s(_vm.topTitleText))]),(_vm.topTitleBtn)?_c('van-button',{attrs:{"size":"small","data-content-layout-title-btn":"","color":_vm.$global.color_primary_2},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(_vm._s(_vm.topTitleBtn))]):_vm._e()]],2)]):_vm._e(),(_vm.showRightTitle)?_c('div',{class:_vm.$style['content-layout-landscape-title']},[(_vm.$slots.rightTitle)?_vm._t("rightTitle"):[_c('span',{attrs:{"data-content-layout-landscape-title-back":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('van-icon',{attrs:{"name":"cross","color":"white"}})],1),_c('p',{style:({ color: _vm.titleColor }),attrs:{"data-content-layout-landscape-title-text":""}},[_vm._v(" "+_vm._s(_vm.rightTitleText || _vm.topTitleText)+" ")])]],2):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }