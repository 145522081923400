/* eslint-disable no-unused-vars */
<template>
  <div
    :class="$style['avatar-main-wrapper']"
    :data-register="$getters['change/avatar'] == 'register'"
  >
    <div :class="$style['avatar-quest-wrapper']" v-if="showNeedQuest">
      <reward-item
        :title="needQuestObj.title"
        :description="needQuestObj.description"
        buttonText="领取任务"
        :img="needQuestObj.img"
        @close="
          showNeedQuest = false;
          needQuestObj = {};
        "
        @tapBtn="handleGetQuest"
      ></reward-item>
    </div>
    <div :class="$style['avatar-buy-main-wrapper']" v-if="showNeedBuy">
      <div :class="$style['avatar-buy-wrapper']">
        <div data-buy-title>
          购买<span @click="showNeedBuy = false" data-buy-close
            ><van-icon name="cross" size="20px"
          /></span>
        </div>

        <div :class="$style['avatar-buy-needbug-list']">
          <vue-scroll ref="avaratcrollwrapper">
            <div v-for="(item, index) in needBuyLists" :key="'need-buy-avatar-' + index">
              <!-- @click="handleSelectedBugItem(item, index)" -->
              <div :class="$style['avatar-buy-needbug-item']">
                <span data-selected-status :data-selected="item.selected">
                  <img
                    v-if="item.selected"
                    data-bug-selected
                    src="@/assets/avatar/selected.svg"
                    alt=""
                  />
                </span>

                <div data-avatar-buy-itemimg>
                  <img
                    style="width: 100%; height: 100%; object-fit: contain"
                    :src="item.equipImage"
                    alt=""
                  />
                </div>
                <div data-avatar-buy-detail>
                  <p data-avatar-buy-detail-name>{{ item.equipSubTypeName }}</p>
                  <div data-avatar-bottom-coin>
                    <span data-avatar-bottom-coin-img
                      ><img
                        style="width: 100%"
                        :src="require('@/assets/home/home_top_2.svg')"
                        alt=""
                    /></span>
                    {{ item.unitPrice }}
                  </div>
                </div>
              </div>
            </div>
          </vue-scroll>
        </div>

        <div :class="$style['avatar-buy-needbug-total']">
          {{ buyTotals }}件商品，合计
          <span data-avatar-bottom-coin-img
            ><img style="width: 100%" :src="require('@/assets/home/home_top_2.svg')" alt=""
          /></span>
          <span data-avatar-bottom-coin-text>{{ buyTotalMoney }}</span>
        </div>

        <div :class="$style['avatar-buy-needbug-total-btn']">
          <van-button
            :class="$style['avatar-buy-needbug-btn']"
            :color="$global.color_primary_2"
            @click="handeBuyAllEquips"
            >购买</van-button
          >
        </div>
      </div>
    </div>

    <div :class="$style['avatar-gender-register']" v-if="$getters['change/avatar'] == 'register'">
      <avatar-gender-register
        @save="
          isRegister = 1;
          $store.dispatch('change/show', { name: 'avatar', params: 'body' });
          handleSaveGender($event);
        "
        @changeGender="handleSaveGenderAndRegister"
        :avartarBodys="avartarBodys"
      ></avatar-gender-register>
    </div>
    <div :class="$style['avatar-wrapper']" v-else>
      <div :class="$style['avatar-dialog']" v-if="dialogName">
        <avatar-gender
          ref="myAvatarGender"
          @back="dialogName = ''"
          @changeGender="handleSaveGenderAndRegister"
          @save="handleSaveGender"
          :curBody="localMyAvatars.body"
          :avartarBodys="avartarBodys"
          v-if="dialogName == 'avatar-gender'"
        ></avatar-gender>
        <!-- <avatar-name
          ref="myAvatarName"
          @back="dialogName = ''"
          v-if="dialogName == 'avatar-name'"
        ></avatar-name> -->
      </div>
      <div :class="$style['data-avatar-main-sub-setting']">
        <span data-avatar-main-setting-1 @click="handleClose" v-if="$getters.portrait"
          ><van-icon name="arrow-left"
        /></span>
        <van-button
          data-avatar-main-setting-2
          size="small"
          round
          :color="$global.color_primary_2"
          @click="handleGotoGender"
          >编辑角色</van-button
        >

        <!-- <van-button round size="small" :color="$global.color_primary_2" @click="handleGotoName">
          <template #icon>
            <img
              style="height: 100%; width: 100%; object-fit: contain; vertical-align: middle"
              src="@/assets/mycenter/mycenter_0.svg"
              alt=""
            />
          </template>
           {{ $getters.userInfo.nickName }}</van-button
        > -->
      </div>

      <div :class="$style['avatar-main-setting-wrapper']">
        <div :class="$style['avatar-main-setting']">
          <div :class="$style['avatar-top-setting']" v-if="$getters.portrait">
            <div data-avatar-cocos-1 @click="handleFastTest">
              <img
                style="width: 100%; height: 100%; object-fit: cover"
                src="@/assets/avatar/magic.svg"
                alt=""
              />
              <p>一键换装</p>
            </div>

            <div data-avatar-cocos-2 @click="handleCancel" v-if="historyAvatar.length > 1">
              <img
                style="width: 100%; height: 100%; object-fit: cover"
                src="@/assets/avatar/cancel.svg"
                alt=""
              />
            </div>
          </div>

          <div :class="$style['avatar-customer-setting-wrapper']">
            <div :class="$style['avatar-bottom-setting-wrapper']">
              <div
                :class="$style['avatar-bottom-show-setting']"
                v-if="$getters.landscape && !dialogName"
              >
                <div data-avatar-cocos-1 @click="handleFastTest">
                  <img
                    style="width: 100%; height: 100%; object-fit: cover"
                    src="@/assets/avatar/magic.svg"
                    alt=""
                  />
                  <p>一键换装</p>
                </div>

                <div data-avatar-cocos-2 @click="handleCancel" v-if="historyAvatar.length > 1">
                  <img
                    style="width: 100%; height: 100%; object-fit: cover"
                    src="@/assets/avatar/cancel.svg"
                    alt=""
                  />
                </div>
              </div>
              <div data-avatar-selecte-wrapper>
                <div :class="$style['avatar-title-scrollwrapper']">
                  <vue-scroll ref="avaratTitlecrollwrapper" :ops="avatarTitleOpstion">
                    <div :class="$style['avatar-title-scrollwrapper-contaioner']">
                      <p
                        :class="$style['avatar-title-scrollwrapper-item']"
                        @click="handleCickTab(item, key)"
                        v-for="(item, key) in avatarAllObject"
                        :data-title-avatar-active="curSelectedKey == key"
                        :key="'typeName' + key"
                      >
                        {{ avatarTypeEmu[key] }}
                      </p>
                    </div>
                  </vue-scroll>
                </div>

                <!-- <van-tabs v-model="active">
                  <van-tab v-for="(item, key) in avatarAllObject" :key="'typeName' + key">
                    <template #title>
                      <span @click="handleCickTab(item)" style="display: inline-block">{{
                        avatarTypeEmu[key]
                      }}</span></template
                    >
                  </van-tab>
                </van-tabs> -->
                <div :class="$style['avatar-bottom-setting-scrollwrapper']" ref="avatrwrapper">
                  <vue-scroll ref="avaratcrollwrapper">
                    <div ref="avaratImgwrapper" class="clearFloat">
                      <div
                        @click="handleSelected(item)"
                        :class="$style['avatar-bottom-setting-content-item']"
                        v-for="item in avatarList"
                        :key="'avatarList-img' + item.id"
                      >
                        <div
                          :class="$style['avatar-bottom-setting-content-item2']"
                          :style="{
                            'background-image': 'url(' + item.equipImage + ')',
                          }"
                        >
                          <div
                            data-avatar-bottom-lock
                            v-if="item.unlockMode == 2 && item.isLock == 1"
                          >
                            <svg
                              viewBox="0 0 16 20"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                            >
                              <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                <g
                                  transform="translate(-605.000000, -139.000000)"
                                  fill-rule="nonzero"
                                >
                                  <path
                                    d="M610.368984,147.004246 L607.673797,147.004246 L607.673797,143.883227 C607.673797,143.203822 607.809269,142.570418 608.080214,141.983015 C608.351159,141.395612 608.72549,140.878981 609.203209,140.433121 C609.680927,139.987261 610.244207,139.636943 610.893048,139.382166 C611.541889,139.127389 612.251337,139 613.02139,139 C613.705882,139 614.365419,139.127389 615,139.382166 C615.634581,139.636943 616.197861,139.987261 616.68984,140.433121 C617.181818,140.878981 617.573975,141.395612 617.86631,141.983015 C618.158645,142.570418 618.304813,143.203822 618.304813,143.883227 L618.304813,147.004246 L615.716578,147.004246 L615.716578,144.477707 C615.716578,143.585987 615.467023,142.895966 614.967914,142.407643 C614.468806,141.919321 613.791444,141.675159 612.935829,141.675159 C612.165775,141.675159 611.545455,141.919321 611.074866,142.407643 C610.604278,142.895966 610.368984,143.585987 610.368984,144.477707 L610.368984,147.004246 Z M619.588235,148.278132 C619.973262,148.278132 620.304813,148.416136 620.582888,148.692144 C620.860963,148.968153 621,149.29724 621,149.679406 L621,156.261146 C621,156.643312 620.932264,157.000708 620.796791,157.333333 C620.661319,157.665959 620.475936,157.956122 620.240642,158.203822 C620.005348,158.451522 619.727273,158.646143 619.406417,158.787686 C619.085561,158.929229 618.73262,159 618.347594,159 L607.545455,159 C607.160428,159 606.811052,158.929229 606.497326,158.787686 C606.183601,158.646143 605.916221,158.458599 605.695187,158.225053 C605.474153,157.991507 605.30303,157.719038 605.181818,157.407643 C605.060606,157.096249 605,156.763624 605,156.409766 L605,149.679406 C605,149.29724 605.135472,148.968153 605.406417,148.692144 C605.677362,148.416136 606.005348,148.278132 606.390374,148.278132 L607.673797,148.278132 L610.368984,148.278132 L615.716578,148.278132 L618.304813,148.278132 L619.588235,148.278132 L619.588235,148.278132 Z"
                                    id="形状"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <img src="@/assets/icon/empty.svg" style="width: 100%" alt="" />
                          <img
                            v-if="item.isSelected"
                            src="@/assets/avatar/selected.svg"
                            data-selected-icon
                            alt=""
                          />
                        </div>
                        <div
                          data-avatar-bottom-coin
                          v-if="item.unlockMode == 1 && item.isLock == 1"
                        >
                          <span data-avatar-bottom-coin-img
                            ><img
                              style="width: 100%"
                              :src="require('@/assets/home/home_top_2.svg')"
                              alt=""
                          /></span>
                          {{ item.unitPrice }}
                        </div>
                      </div>

                      <div
                        :class="$style['avatar-bottom-setting-content-item']"
                        v-if="$getters.landscape"
                      >
                        <div :class="$style['avatar-bottom-setting-content-item2']">
                          <img src="@/assets/icon/empty.svg" style="width: 100%" alt="" />
                        </div>
                      </div>
                    </div>
                  </vue-scroll>

                  <van-button
                    v-if="needBuyLists.length > 0"
                    size="small"
                    :class="$style['avatar-bottom-setting-btn']"
                    :color="$global.color_primary_2"
                    :disabled="!!selectedNeedQuest"
                    @click="handeAvatarOpenBuy"
                    >购买({{ needBuyLists.length }})</van-button
                  >
                  <van-button
                    v-else
                    size="small"
                    :class="$style['avatar-bottom-setting-btn']"
                    :color="$global.color_primary_2"
                    :disabled="!!selectedNeedQuest"
                    @click="handeAvatarSaveSubmit"
                    >保存</van-button
                  >
                </div>
              </div>
            </div>
            <div :class="$style['avatar-bottom-setting-close-wrapper']" v-if="$getters.landscape">
              <span avatar-bottom-setting-close-icon @click="handleClose">
                <van-icon name="cross" color="white" />
              </span>
              <p>个人形象</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlayerInfo } from "@/api/login";
import { delay } from "@/utils/lib";
import AvatarGender from "@/components/avatar/AvatarGender";
import AvatarGenderRegister from "@/components/avatar/AvatarGenderRegister";
import dispatcher from "@/utils/dispatcher";
import _collection from "lodash/collection";
import _array from "lodash/array";
import RewardItem from "@/components/tips/reward/RewardItem";
// import _object from "lodash/object";
import { landConfig } from "@/utils/landBaseInfo";

import {
  savePlayAvatar,
  avatarTypeEmu,
  getPlayerAvatar,
  getAvatarList,
  getDefaultAvatars,
} from "@/api/avatar";
import { getQuestInfo, receiveQuest } from "@/api/quest";
import shopApi from "@/api/shop";
import {
  // eslint-disable-next-line no-unused-vars
  openAvatar,
  // eslint-disable-next-line no-unused-vars
  renderCocosRect,
  closeAvatar,
  userSetAvatar,
  cocosFullScreen,
  setGameAvatar,
  avatarShot,
  releaseIslandCard,
} from "@/utils/cocosBridge";
export default {
  name: "avatar_wrapper",
  data() {
    return {
      avatarTitleOpstion: {
        scrollPanel: {
          speed: 100,
          scrollingX: true,
          scrollingY: false,
        },
      },
      isRegister: 0,
      playerAvatar: {},
      myAvatarsStore: {},
      avatarTypeEmu: avatarTypeEmu,
      avatarTypeList: [],
      avatarAllObject: {},
      curAvatars: [],
      avatarList: [],
      avartarBodys: [],
      dialogName: "",
      active: 0,
      showNeedBuy: false,
      needBuyLists: [],
      selectneedBuyLists: [],

      showNeedQuest: false,
      selectedNeedQuest: "",
      needQuestObj: {},
      curSelectedKey: "",

      historyAvatar: [],
      isFast: false, //是否在一键换装

      localMyAvatars: {}, //当前状态下 的 Avatar 数据
    };
  },
  computed: {
    buyTotalMoney: function () {
      let money = 0;
      this.needBuyLists
        .filter((item) => {
          return item.selected;
        })
        .forEach((element) => {
          money = money + element.unitPrice * 1;
        });

      return money;
    },
    buyTotals: function () {
      return this.needBuyLists.filter((item) => {
        return item.selected;
      }).length;
    },
  },
  methods: {
    /**
     * 一建换装
     */
    handleFastTest() {
      this.isFast = true;
      delay(0.3).then(() => {
        this.isFast = false;
      });
      let avatarObJson = {};
      for (var key in this.avatarAllObject) {
        const itemOneObj = this.avatarAllObject[key].filter((item) => {
          return (
            item.isLock == 0 &&
            (item.equipGender == this.localMyAvatars.body || item.equipGender == "body_all")
          );
        });
        if (itemOneObj.length > 0) {
          if (key == "accessories" || key == "misc") {
            const tempObj = _collection.groupBy(itemOneObj, "equipSubTypeKey");

            for (var tempkey in tempObj) {
              avatarObJson[tempkey] = _collection.sampleSize(tempObj[tempkey], 1)[0]["equipName"];
            }
          } else {
            avatarObJson[key] = _collection.sampleSize(itemOneObj, 1)[0]["equipName"];
          }
        }
      }

      if (Object.keys(avatarObJson).length == 0) {
        this.$yaojingToast("先去解锁装备哦", "warning");
        return;
      }

      this.localMyAvatars = Object.assign(this.localMyAvatars, avatarObJson);

      this.historyAvatar.push({
        avatar: JSON.stringify(this.localMyAvatars),
        need: JSON.stringify(this.needBuyLists),
      });
      this.needBuyLists = [];
      this.selectedNeedQuest = "";
      userSetAvatar(this.localMyAvatars);
      this.setAvatarLists();
    },

    /**
     * 返回，撤销
     */
    handleCancel() {
      if (this.historyAvatar.length > 1) {
        this.historyAvatar = this.historyAvatar.slice(0, this.historyAvatar.length - 1);
        const avatarObJson = this.historyAvatar[this.historyAvatar.length - 1];

        this.localMyAvatars = Object.assign(this.localMyAvatars, JSON.parse(avatarObJson.avatar));

        userSetAvatar(this.localMyAvatars);
        this.needBuyLists = JSON.parse(avatarObJson.need);
        this.setAvatarLists();
      }
    },

    handleGotoGender() {
      this.dialogName = "avatar-gender";
    },
    handleGotoName() {
      this.dialogName = "avatar-name";
    },

    // handleSelectedBugItem(item, index) {
    //   return;
    //   // this.$set(this.needBuyLists, index, Object.assign(item, { selected: !item.selected }));
    // },

    handeAvatarOpenBuy() {
      this.showNeedBuy = true;
    },

    /**
     * Y币购买装备
     */
    handeBuyAllEquips() {
      if (this.$getters.ycoin * 1 < this.buyTotalMoney * 1) {
        this.$yaojingToast("您的曜币不足", "fail");
        return;
      }

      const details = this.needBuyLists
        .filter((item) => {
          return item.selected;
        })
        .map((item) => {
          return {
            productId: item.productId,
            quantity: 1,
          };
        });

      shopApi.createOrder(0, this.$getters.landId, details).then((orderNo) => {
        shopApi.payOrderY(orderNo).then(() => {
          this.showNeedBuy = false;
          this.$yaojingToast("购买成功", "success");
          this.refushAvatar();
          this.needBuyLists = [];
          getPlayerInfo();
        });
      });
    },

    /**
     * 保存性别 用于注册的时候
     */
    handleSaveGenderAndRegister(avatarItem) {
      this.updateStoreAvatar(avatarItem);
      userSetAvatar(this.localMyAvatars);
      this.setAvatarLists();
      // this.handeAvatarSave();
    },
    /**
     * 保存性别
     */
    handleSaveGender(avatarItem) {
      this.dialogName = "";
      this.updateStoreAvatar(avatarItem);
      userSetAvatar(this.localMyAvatars);
      this.setAvatarLists();
      // this.handeAvatarSave();
    },

    /**
     * 设置avatar列表
     */

    setAvatarLists() {
      this.avatarList = this.curAvatars
        .filter((item) => {
          return item.equipGender == this.localMyAvatars.body || item.equipGender == "body_all";
        })
        .map((item) => {
          return Object.assign(item, {
            isSelected: this.localMyAvatars[item.equipSubTypeKey] == item.equipName,
          });
        });
    },

    /**
     * 选择装备
     */
    handleSelected(avatar) {
      if (this.isFast) return;

      this.isFast = true;
      delay(0.3).then(() => {
        this.isFast = false;
      });

      if (this.$getters.landId == 1) {
        if (
          avatar.unlockMode == 1 &&
          avatar.isLock == 1 &&
          avatar.relatedShopId != this.$getters.landId
        ) {
          let oraginAvatar = {};
          oraginAvatar[avatar.equipSubTypeKey] = this.localMyAvatars[[avatar.equipSubTypeKey]];
          let selectObj = {};
          selectObj[avatar.equipSubTypeKey] = avatar.equipName;
          this.localMyAvatars = Object.assign(this.localMyAvatars, selectObj);
          userSetAvatar(this.localMyAvatars);

          this.$yaojingConfirm({
            title: "购买须知",
            text: "跳转到" + this.$landConfig[avatar.relatedShopId]["landName"],
            cancelButtonText: "取消",
            confirmButtonText: "确认",
          })
            .then(() => {
              this.localMyAvatars = Object.assign(this.localMyAvatars, oraginAvatar);
              this.$store.dispatch("change/hidden", "avatar");
              this.$router.replace({
                name: "landHome",
                query: {
                  landId: avatar.relatedShopId,
                },
              });
            })
            .catch(() => {
              this.localMyAvatars = Object.assign(this.localMyAvatars, oraginAvatar);
              userSetAvatar(this.localMyAvatars);
              this.setAvatarLists();
            });

          return;
        }
      } else {
        if (
          avatar.unlockMode == 1 &&
          avatar.isLock == 1 &&
          avatar.relatedShopId != 1 &&
          avatar.relatedShopId != this.$getters.landId
        ) {
          let oraginAvatar = {};
          oraginAvatar[avatar.equipSubTypeKey] = this.localMyAvatars[[avatar.equipSubTypeKey]];

          let selectObj = {};
          selectObj[avatar.equipSubTypeKey] = avatar.equipName;
          this.localMyAvatars = Object.assign(this.localMyAvatars, selectObj);
          userSetAvatar(this.localMyAvatars);

          this.$yaojingConfirm({
            title: "购买须知",
            text: "跳转到" + this.$landConfig[avatar.relatedShopId]["landName"],
            cancelButtonText: "取消",
            confirmButtonText: "确认",
          })
            .then(() => {
              this.localMyAvatars = Object.assign(this.localMyAvatars, oraginAvatar);

              this.$store.dispatch("change/hidden", "avatar");
              this.$router.replace({
                name: "landHome",
                query: {
                  landId: avatar.relatedShopId,
                },
              });
              this.$router.go(0);
            })
            .catch(() => {
              this.localMyAvatars = Object.assign(this.localMyAvatars, oraginAvatar);
              userSetAvatar(this.localMyAvatars);
              this.setAvatarLists();
            });
          return;
        }
      }

      if (avatar.unlockMode == 2 && avatar.isLock == 1) {
        this.handleGetQuestInfo(avatar.questId);
        this.showNeedBuy = false;
        this.selectedNeedQuest = avatar.questId;
      } else {
        this.selectedNeedQuest = "";
      }

      const findequipItem = this.needBuyLists.find((o) => {
        return o.equipName == avatar.equipName;
      });
      if (findequipItem) {
        // 只有 配饰 道具 可以 卸载装备
        if (avatar.equipTypeKey == "accessories" || avatar.equipTypeKey == "misc") {
          _array.remove(this.needBuyLists, function (n) {
            return n.equipName == findequipItem.equipName;
          });
        }
      } else {
        //选择了需要购买的 装备
        _array.remove(this.needBuyLists, function (n) {
          return n.equipSubTypeKey == avatar.equipSubTypeKey;
        });
        this.needBuyLists.push(Object.assign(avatar, { selected: true }));
        this.needBuyLists = this.needBuyLists.filter((item) => {
          return item.isLock == 1 && item.unlockMode == 1;
        });
      }
      let selectObj = {};

      selectObj[avatar.equipSubTypeKey] =
        this.localMyAvatars[avatar.equipSubTypeKey] == avatar.equipName ? "" : avatar.equipName;

      // 只有 配饰 道具 可以 卸载装备
      if (avatar.equipTypeKey == "accessories" || avatar.equipTypeKey == "misc") {
        selectObj[avatar.equipSubTypeKey] =
          this.localMyAvatars[avatar.equipSubTypeKey] == avatar.equipName ? "" : avatar.equipName;
      } else {
        selectObj[avatar.equipSubTypeKey] = avatar.equipName;
      }
      this.localMyAvatars = Object.assign(this.localMyAvatars, selectObj);

      if (avatar.unlockMode == 0 && avatar.isLock == 0) {
        this.historyAvatar.push({
          avatar: JSON.stringify(this.localMyAvatars),
          need: JSON.stringify(this.needBuyLists),
        });
      }

      userSetAvatar(this.localMyAvatars);
      this.setAvatarLists();
    },

    /**
     * 用户保存 test avatar数据
     */
    handeTestAvatarSave() {
      return savePlayAvatar(this.playerAvatar.id, this.localMyAvatars).then((avatarId) => {
        this.playerAvatar.id = avatarId;
        this.$yaojingToast("保存成功", "success");
        this.$store.dispatch("secne/update_avatar_data", this.localMyAvatars);
        setGameAvatar(this.localMyAvatars);
      });
    },

    /**
     * 用户保存avatar数据
     */
    handeAvatarSave() {
      return savePlayAvatar(this.playerAvatar.id, this.localMyAvatars).then((avatarId) => {
        this.playerAvatar.id = avatarId;
        this.$yaojingToast("保存成功", "success");
        this.$store.dispatch("secne/update_avatar_data", this.localMyAvatars);
        setGameAvatar(this.localMyAvatars);
      });
    },
    handeAvatarSaveSubmit() {
      this.handeAvatarSave().then(() => {
        avatarShot();
        this.$store.dispatch("change/hidden", "avatar");
      });
    },

    handleClose() {
      this.$store.dispatch("change/hidden", "avatar");
    },

    /**
     * 选择了tab
     */
    handleCickTab(item, key) {
      this.curSelectedKey = key;
      this.curAvatars = item;
      this.setAvatarLists();
    },

    /**
     * 刷新Avatar 数据列表
     */
    refushAvatar() {
      this.needBuyLists.forEach((item) => {
        const equipIndex = _array.findIndex(this.avatarAllObject[item.equipTypeKey], {
          id: item.id,
        });
        if (equipIndex > -1) {
          const objVal = this.avatarAllObject[item.equipTypeKey][equipIndex];
          this.$set(
            this.avatarAllObject[item.equipTypeKey],
            equipIndex,
            Object.assign(objVal, { isLock: 0 }),
          );
        }
      });
    },

    /**
     * avatar数据初始化
     */
    async initAvatarCocos() {
      try {
        this.playerAvatar = await getPlayerAvatar(
          this.$getters["change/avatar"] == "register"
            ? 1
            : landConfig[this.$getters.landId]["avatarId"],
        );
        const initMyAvatars = this.playerAvatar.equips
          ? JSON.parse(this.playerAvatar.equips)
          : getDefaultAvatars(this.playerAvatar.defaults, this.playerAvatar.defaultBodyName);
        let avatarResult = await getAvatarList(
          this.$getters["change/avatar"] == "register"
            ? 1
            : landConfig[this.$getters.landId]["avatarId"],
        );

        if (this.$getters["change/avatar"] == "register") {
          avatarResult = avatarResult.filter((item) => {
            return item.unlockMode == 0 && item.relatedShopId == 1;
          });
        }

        // 获取所有 Avatar的 数据，根据equipTypeKey 重新组成 Object
        this.avatarAllObject = _collection.groupBy(avatarResult, "equipTypeKey");
        //默认显示  tab栏上的第一个 key
        this.curSelectedKey = Object.keys(this.avatarAllObject)[0];
        //默认显示  avatar内容列表
        this.curAvatars = this.avatarAllObject[this.curSelectedKey];
        //获取 avatar 的 body数据
        this.avartarBodys = this.avatarAllObject.body;
        // 将整体对象中，移除body
        this.avatarAllObject.body && delete this.avatarAllObject.body;

        // 将初始化数据 放入历史记录中
        this.historyAvatar.push({
          avatar: JSON.stringify(initMyAvatars),
          need: JSON.stringify(this.needBuyLists),
        });
        // 把初始的 avatar数据 放进 缓存中
        this.localMyAvatars = initMyAvatars;
        this.$store.dispatch("secne/update_avatar_data", this.localMyAvatars);

        // this.updateStoreAvatar(initMyAvatars);
        this.setAvatarLists();
        openAvatar();
        await delay(0.2);
        renderCocosRect(414, 355);
        userSetAvatar(this.localMyAvatars);
        dispatcher.$off("deviceOrientation").$on("deviceOrientation", () => {
          setTimeout(() => {
            renderCocosRect(414, 355);
          }, 1000);
        });
      } catch (error) {
        throw {
          err: error,
        };
      }
    },

    /**
     * 将已经选择的avatar信息保存到store
     */
    updateStoreAvatar(data) {
      if (data.body && this.localMyAvatars.body && data.body != this.localMyAvatars.body) {
        this.myAvatarsStore[this.localMyAvatars.body] = JSON.parse(
          JSON.stringify(this.localMyAvatars),
        );
        const new_myAvatars =
          this.myAvatarsStore[data.body] ||
          getDefaultAvatars(this.playerAvatar.defaults, data.body);
        const _localavatar = Object.assign(this.localMyAvatars, new_myAvatars, {
          badge: "",
          glass: "",
          hat: "",
          necklace: "",
        });
        this.localMyAvatars = _localavatar;
      } else {
        this.localMyAvatars = Object.assign(this.localMyAvatars, data);
      }
    },

    /**
     * 获取任务详情
     */
    handleGetQuestInfo(id) {
      getQuestInfo(id).then((res) => {
        const img = res.questRewardItem ? JSON.parse(res.questRewardItem)[0]["img"] : "";
        this.showNeedQuest = true;
        this.needQuestObj = {
          id: res.id,
          title: res.questName,
          description: res.questDesc,
          buttonText: "领取任务",
          img: img,
        };
      });
    },
    /**
     * 领取任务
     */
    handleGetQuest() {
      receiveQuest(this.needQuestObj.id).then(() => {
        this.$yaojingToast("任务领取成功", "success");
        this.showNeedQuest = false;
      });
    },
  },
  created() {
    releaseIslandCard();
    this.$store.dispatch("change/hidden", "landInfo");

    this.$trackEvent("Avatar形象", "open", this.$landConfig[this.$getters.landId]["landName"]);
    this.initAvatarCocos();
    if (!this.isRegister) {
      this.$makeQuestEvent({ type: "openUI", target: "avatar", landid: this.$getters.landId });
    }
  },
  beforeDestroy() {
    dispatcher.$off("deviceOrientation");
    releaseIslandCard();
    this.$store.dispatch("change/hidden", "landInfo");
    if (this.$route.name != "my") {
      if (!this.$getters["thirdMy/isCloseAvatar"]) return;
      closeAvatar();
      delay(0.2).then(() => {
        cocosFullScreen();
        // setGameAvatar(this.localMyAvatars);
      });
    } else {
      renderCocosRect(this.desktop ? 428 : 414, 355);
    }
  },
  components: {
    AvatarGender,
    AvatarGenderRegister,
    RewardItem,
  },
};
</script>
<style lang="scss" module>
@import "../../assets/themes.scss";
.avatar-dialog {
  position: absolute;
  z-index: 202;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.avatar-main-wrapper {
  position: absolute;
  z-index: 201;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.avatar-wrapper {
  position: absolute;
  z-index: 201;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none !important;
}

.data-avatar-main-sub-setting {
  pointer-events: auto;
  cursor: pointer;
  position: fixed;
  font-size: $--font_size_36;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 2;
  display: flex;
  align-items: center;
  [data-avatar-main-setting-1] {
    font-size: $--font_size_36;
    margin-right: 12px;
  }

  [data-avatar-main-setting-2] {
    margin-right: 12px;
  }
}

.avatar-main-setting-wrapper {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  color: $--color_text_1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.avatar-main-setting {
  position: absolute;
  z-index: 1;
  color: $--color_text_1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  & > * {
    pointer-events: auto;
  }
}

.avatar-top-setting {
  height: calc(100% - 355px);
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;

  [data-avatar-cocos-1] {
    pointer-events: auto;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 0;
    & > p {
      position: absolute;
      bottom: 0;
      left: 50%;
      white-space: nowrap;
      font-size: $--font_size_24;
      transform: translate(-50%, 100%);
    }
  }

  [data-avatar-cocos-2] {
    pointer-events: auto;
    width: 20px;
    position: absolute;
    bottom: 14px;
    left: 20px;
  }
}

.avatar-customer-setting-wrapper {
  height: 355px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: auto;
  background-color: $--color_bg_line_1;
}

.avatar-bottom-setting-close-wrapper {
  height: 100%;
  width: 54px;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  background-color: $--color_primary_1;
  [avatar-bottom-setting-close-icon] {
    position: absolute;
    width: 54px;
    height: 52px;
    line-height: 52px;
    top: 0;
    right: 0;
    z-index: 4;
    text-align: center;
    cursor: pointer;
    font-size: $--font_size_44;
  }
  p {
    position: absolute;
    height: 100%;
    width: 54px;
    line-height: 54px;
    top: 0;
    left: auto;
    right: 0;
    text-align: center;
    writing-mode: tb-rl;
    letter-spacing: 0.4em;
    font-size: $--font_size_28;
  }
}

.avatar-bottom-setting-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  [data-avatar-selecte-wrapper] {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 44px;
  }

  :global(.van-tabs) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $--color_primary_1;
  }
  :global(.van-tabs__wrap) {
    &::after {
      content: "";
      display: block;
      width: 100%;
      position: absolute;
      bottom: 1px;
      left: 0;
      height: 1px;
      background-color: #434343;
    }
  }
  :global(.van-tabs__nav) {
    background-color: transparent;
    padding-bottom: 0;
  }
  :global(.van-tab) {
    color: $--color_text_1;
  }
  :global(.van-tabs__line) {
    background-color: $--color_primary_2;
    width: 18px;
    height: 3px;
    bottom: 0px;
  }
  :global(.van-tab--active) {
    color: $--color_primary_2;
  }
}

.avatar-bottom-setting-scrollwrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 38px;
  background-color: $--color_primary_1;
  border-top: 1px solid #434343;
}

.avatar-bottom-show-setting {
  width: 60px;
  height: 100%;
  position: absolute;
  pointer-events: auto;
  bottom: auto;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  background: rgba(13, 12, 29, 0.8);
  [data-avatar-cocos-1] {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 12px;
    right: 0;
    left: 0;
    margin: 0 auto;
    font-size: 0;
    & > p {
      position: absolute;
      bottom: -6px;
      left: 50%;
      white-space: nowrap;
      font-size: $--font_size_2;
      transform: translate(-50%, 100%);
    }
  }

  [data-avatar-cocos-2] {
    pointer-events: auto;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px 19px 34px;
    left: 0;
    cursor: pointer;
  }

  [data-avatar-cocos-3] {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 88px;
    right: 0;
    left: 0;
    margin: 0 auto;
    font-size: 0;
    & > p {
      position: absolute;
      bottom: -6px;
      left: 50%;
      white-space: nowrap;
      font-size: $--font_size_2;
      transform: translate(-50%, 100%);
    }
  }
}

.avatar-bottom-setting-content {
}

.avatar-bottom-setting-content-item {
  width: 25%;
  float: left;
  font-size: 0;
  position: relative;
  padding: 10px 14px;
  [data-avatar-bottom-lock] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($--color_primary_1, 1);
    & > svg {
      width: 15px;
      position: absolute;
      right: 0;
      bottom: 0;
      // transform: translate(-50%, -50%);
      display: inline-block;
      fill: $--color_primary_2;
      z-index: 2;
    }
  }
  [data-avatar-bottom-coin] {
    font-size: $--font_size_24;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(6px);
  }
  [data-avatar-bottom-coin-img] {
    font-size: 0;
    width: 10px;
    display: inline-block;
    margin-right: 4px;
  }
}
.avatar-bottom-setting-content-item2 {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  [data-selected-icon] {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 18px;
    z-index: 2;
  }
}

.avatar-bottom-setting-btn {
  font-size: $--font_size_28;
  width: 72px;
  height: 30px;
  position: absolute;
  bottom: 7px;
  right: 12px;
  border-radius: 0;
}
html:global(.desktop) {
  .avatar-main-wrapper {
    &[data-register] {
      pointer-events: auto !important;
    }
  }
}
html:global(.landscape) {
  .avatar-top-setting {
    width: calc(100% - 416px);
    height: 100%;
    left: 0;
    right: auto;
  }

  .avatar-customer-setting-wrapper {
    width: 416px;
    height: 100%;
    left: auto;
    right: 0;
    padding-right: 54px;
  }

  .avatar-bottom-setting-btn {
    font-size: $--font_size_28;
    bottom: 16px;
    right: 15px;
  }
  .avatar-bottom-setting-scrollwrapper {
    padding-bottom: 0;
  }
  &:global(.mobile) {
    .avatar-bottom-setting-wrapper {
      padding: 6px 6px 0 0;
    }
  }

  &:global(.tablet),
  &:global(.desktop) {
    .avatar-bottom-setting-wrapper {
      padding: 10px 10px 0 0;
    }
    .avatar-bottom-show-setting {
      background-color: $--color_bg_line_1;
    }
  }
}

html:not(:global(.mobile)):global(.landscape) {
  .avatar-wrapper {
    width: 416px;
    // height: 550px;
    height: 100%;
    right: 0;
    bottom: 0;
    margin: auto 0;
    left: auto;
  }
  .avatar-gender-register {
    width: 476px;
    height: 550px;
    right: 0;
    bottom: 0;
    margin: auto 0;
    left: auto;
    top: 0;
    position: absolute;
  }
  .data-avatar-main-sub-setting {
    // transform: translate(-20px, -100%);
  }
}

.avatar-buy-main-wrapper {
  position: absolute;
  z-index: 203;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}
.avatar-buy-wrapper {
  pointer-events: auto;
  width: 330px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(13, 12, 29, 0.8);
  color: white;
  [data-buy-title] {
    line-height: 43px;
    position: relative;
    background: black;
    font-size: 16px;
    text-align: center;
  }
  [data-buy-close] {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 12px;
  }
}

.avatar-buy-needbug-list {
  height: 136px;
}

.avatar-buy-needbug-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 12px 8px 25px;
  height: 68px;
  [data-selected-status] {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 0;
    position: relative;
    display: inline-block;
    [data-bug-selected] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    &[data-selected] {
      border-color: transparent !important;
    }
  }
  [data-avatar-buy-itemimg] {
    width: 52px;
    height: 52px;
    margin: 0 16px 0 14px;
  }
  [data-avatar-buy-detail] {
    [data-avatar-buy-detail-name] {
      padding-bottom: 3px;
    }
    [data-avatar-bottom-coin] {
      font-size: 12px;
      line-height: 1;
      [data-avatar-bottom-coin-img] {
        font-size: 0;
        padding-top: 1px;
        width: 10px;
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }
}

.avatar-buy-needbug-total {
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 25px;
  [data-avatar-bottom-coin-img] {
    display: inline-block;
    width: 10px;
    margin: 0 4px;
  }
  [data-avatar-bottom-coin-text] {
    font-size: 14px;
    color: #fe9636;
    line-height: 19px;
  }
}

.avatar-buy-needbug-total-btn {
  padding: 10px 25px 20px 25px;
}

.avatar-buy-needbug-btn {
  width: 100%;
  height: 42px;
  margin: 0 auto;
}

.avatar-quest-wrapper {
  position: absolute;
  pointer-events: auto;
  width: 100%;
  height: 100%;
  z-index: 205;
}

.avatar-title-scrollwrapper {
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0d0c1d;

  :global(.__panel) {
    min-width: 100% !important;
  }
}
.avatar-title-scrollwrapper-contaioner {
  height: 44px;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}

.avatar-title-scrollwrapper-item {
  flex-shrink: 0;
  padding: 0 12px;
  line-height: 44px;
  position: relative;
  z-index: 2;
  &[data-title-avatar-active] {
    color: #665cff;
    &::after {
      content: "";
      display: block;
      position: absolute;
      background-color: #665cff;
      width: 18px;
      height: 3px;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
