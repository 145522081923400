/* jshint esversion: 6 */
import Vue from "vue";
/**
 * event 跨页接收
 * import dispatcher from '@/utils/broadcast.js
 * dispatcher.$on('event',(val)=>{})
 * dispatcher.$off('event')
 * dispatcher.$off('event').$on('event',(val)=>{})
 * dispatcher.$emit('event', 'value')
 */
const dispatcher = new Vue();

export default dispatcher;
