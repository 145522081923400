<!-- 购买证书  -->
<!-- http://192.168.199.157:8086/ShopDetail?productType=2&pid=146&myType=1&nftSdsTxId=123456111111111111111111111111111111111111111111111111111111  -->
<template>
  <div class="buy-share-tips">
    <div @click="onClick()" style="position: absolute; top: 10px; right: 20px; z-index: 9999">
      <van-icon name="cross" size="25px" color="#fff" />
    </div>

    <div style="position: relative; display: flex; flex-direction: column; align-items: center">
      <div class="buy-tips-wrapper" id="shareOrigination" v-if="isShowInfo">
        <img src="@/assets/share/5.png" style="width: 350px; height: 580px" />

        <div class="buy-title">
          <img src="@/assets/share/logo.png" />
          <p style="font-size: 14px; color: #ffffff; margin-left: 10px">曜境数字收藏证书</p>
        </div>

        <div class="buy-icon">
          <img src="@/assets/share/1.png" v-if="rarity == 3" />
          <img src="@/assets/share/2.png" v-if="rarity == 1" />
          <img src="@/assets/share/3.png" v-if="rarity == 2" />
        </div>
        <!-- https://obs-helf.cucloud.cn/bucket/system/2022/06/22/%E5%B0%84%E6%89%8B_20220622154537A004.jpg -->
        <div class="buy-info">
          <div
            class="buy-image"
            :style="{
              'background-image': 'url(' + smallImage + ')',
            }"
          ></div>

          <p data-title1 class="van-multi-ellipsis--l2">{{ productName }}</p>
          <p data-title2>
            <span><img src="@/assets/share/icon.png" /></span>&nbsp;&nbsp;收藏家&nbsp;{{
              checkNickNameLength($getters.userInfo.nickName, 10)
            }}&nbsp;持有
          </p>

          <div class="nft-info">
            <p data-title3>交易唯一标识</p>
            <p data-title4 class="van-multi-ellipsis--l2">
              {{ nftSdsTxId }}
            </p>
          </div>

          <div class="qr-info">
            <vue-qrcode
              :value="qrcode"
              :options="{ width: 70, margin: 2, errorCorrectionLevel: 'Q', quality: 0.5 }"
            ></vue-qrcode>
            <p style="font-size: 12px; color: #8e8895; margin-top: 5px">微信扫码查看藏品</p>
          </div>
        </div>
      </div>

      <div id="htmlCanvasWrapper" class="htmlCanvasWrapper"></div>
      <!-- 底部提示 -->
      <div style="position: relative; margin-top: 5px">
        <span style="font-size: 14px; color: #ffffff">{{
          $getters.desktop ? "请鼠标右键保存" : "请长按图片保存"
        }}</span>
      </div>
    </div>

    <loading-tips
      v-if="loading"
      style="
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #0d0c1d;
      "
    ></loading-tips>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import imagesLoaded from "imagesloaded";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { delay, getImage, limitedStr, changeURLArg, ridUrlParam } from "@/utils/lib";
import html2canvas from "html2canvas";
import LoadingTips from "@/components/tips/LoadingTips";
import _ from "lodash";

export default {
  name: "buyShareTips",
  components: {
    VueQrcode,
    LoadingTips,
  },
  props: {},
  data() {
    return {
      qrcode: process.env.VUE_APP_MAIN_BASE,
      rarity: 0, // 稀有2 普通0 精良1 传说3
      smallImage: "",
      productName: "",
      nickName: "",
      realName: "",
      nftSdsTxId: "",
      isShowInfo: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      productInfo: "shop/productInfo",
    }),
  },
  watch: {},
  methods: {
    checkNickNameLength(arg, length) {
      return limitedStr(arg, length);
    },
    onClick() {
      this.$store.dispatch("change/hidden", "buyShareTips");
    },
    // 截图
    setCanvas() {
      html2canvas(document.querySelector("#shareOrigination"), {
        width: 350,
        height: 580,
        allowTaint: false,
        useCORS: true,
        logging: true,
        backgroundColor: null,
        dpi: window.devicePixelRatio,
      }).then((canvas) => {
        const o = new Image();
        o.src = canvas.toDataURL("image/png");
        o.style.background = "transparent !important";
        document.getElementById("htmlCanvasWrapper").appendChild(o);
        this.loading = false;
      });
    },
    // 初始化
    async initValue() {
      this.smallImage = this.productInfo.info.smallImage;
      const str = this.$route.fullPath;
      const str1 = changeURLArg(str, "myType", 3);
      const str2 = ridUrlParam(str1, ["nftAssetId", "nftSdsTxId"]);
      this.qrcode = process.env.VUE_APP_MAIN_BASE + str2;

      // this.qrcode = process.env.VUE_APP_MAIN_BASE;

      if (this.productInfo.info.rarity != null) {
        this.rarity = this.productInfo.info.rarity;
      }
      this.productName = this.productInfo.info.productName;
      this.nickName = this.$getters.userInfo.nickName;
      this.nftSdsTxId = this.productInfo.nftSdsTxId;
      // await delay(1);
      imagesLoaded(".buy-image", { background: true }, (instance) => {
        delay(0.1).then(() => {
          this.setCanvas();
        });
      });
    },
  },
  created() {
    this.loading = true;
    this.initValue();
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.buy-share-tips {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($color: #0d0c1d, $alpha: 0.9);
  display: flex;
  justify-content: center;
  align-items: center;

  ::v-deep .van-image__loading {
    background-color: $--color_primary_1;
  }
  ::v-deep .van-image__error {
    background-color: $--color_primary_1;
  }
}

.htmlCanvasWrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  ::v-deep img {
    width: 350px;
    height: 580px;
  }
}

.buy-tips-wrapper {
  position: relative;
  width: 350px;
  height: 580px;
}

.buy-title {
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.buy-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.buy-info {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding: 70px 35px 0 35px;
  display: flex;
  flex-direction: column;

  align-items: center;
  [data-title1] {
    margin-top: 10px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    word-break: break-word;
    width: 100%;
  }
  [data-title2] {
    position: absolute;
    top: 360px;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    width: 100%;
  }
}

.buy-image {
  width: 220px;
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent !important;
}

.nft-info {
  width: 100%;
  justify-content: flex-start;
  position: absolute;
  top: 400px;
  padding: 0 40px 0 40px;
  [data-title3] {
    font-size: 12px;
    color: #8e8895;
  }
  [data-title4] {
    margin-top: 5px;
    font-size: 12px;
    color: #8e8895;
    word-break: break-word;
  }
}

.qr-info {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  position: absolute;
  top: 480px;
}

html.mobile.landscape {
  // .buy-tips {
  //   position: absolute;
  //   top: 0;
  //   right: 65px;
  // }
}
</style>
