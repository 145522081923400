<!-- loading结果 -->
<template>
  <div class="loading-tips-page">
    <div class="loading-msg-box">
      <van-loading
        type="spinner"
        color="#1989fa"
        size="36px"
        text-color="#cbcbcb"
        text-size="12px"
        :vertical="true"
        >{{ text }}</van-loading
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "loadingTipsComponent",
  components: {},
  props: {
    text: {
      type: String,
      default: "数据加载中...",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.loading-tips-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparentize($--color_primary_1, 1);
  // background-color: aqua;
}

.loading-msg-box {
  position: absolute;
  top: 40%;
  // width: 185px;
  // font-size: $--font_size_28;
}

html.mobile.landscape {
  .loading-msg-box {
    top: 40%;
  }
}
</style>
