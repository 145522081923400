<!-- 自定义封面相册选取 -->
<template>
  <content-layout
    :isFull="true"
    contentLandscapeWidth=""
    isShowTopTitle="show"
    topTitleText="云相册"
    rightTitleText="空间"
    :isShowTopBack="!$getters.landscape"
    @close="onClose()"
    @back="onClose()"
  >
    <cloud-album :isCover="true"></cloud-album>
  </content-layout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import ContentLayout from "@/components/ContentLayout";
import CloudAlbum from "@/components/space/album/CloudAlbum";

export default {
  name: "spaceCoverComponent",
  components: { ContentLayout, CloudAlbum },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    onClose() {
      this.$store.dispatch("change/hidden", "chooseSpaceCover");
    },
  },
  created() {
    // this.$store.commit("space/isChoose", false);
  },
  mounted() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.onClose();
  },
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.space-cover-page {
  width: 100%;
  height: 100%;
}
</style>
