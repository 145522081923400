const state = {
  landBaseInfo: {
    userInfo: {
      islock: false,
    },
    thirdMy: {
      islock: false,
    },
    home: {
      islock: false,
    },
    space: {
      islock: true,
    },
    quest: {
      islock: false,
    },
    shop: {
      islock: false,
    },
    raffle: {
      islock: true,
    },
    avatar: {
      islock: false,
    },
    map: {
      islock: false,
    },
  },
};

const getters = {
  landBaseInfo: (state) => state.landBaseInfo,
};

const mutations = {
  GET_LAND_BASE_INFO: (state, landBaseInfo) => {
    state.landBaseInfo = landBaseInfo;
  },
  UPDATE_LAND_BASE_INFO: (state, landBaseInfo) => {
    state.landBaseInfo = JSON.parse(JSON.stringify(landBaseInfo));
  },
};

const actions = {
  get_land_base_info({ commit }, landBaseInfo) {
    commit("GET_LAND_BASE_INFO", landBaseInfo);
  },

  update_land_base_info({ commit }, landBaseInfo) {
    commit("UPDATE_LAND_BASE_INFO", landBaseInfo);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
