/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import { fetch, post } from "@/utils/http";
// import _ from "lodash";

/**
 * 空间
 */
export default {
  /**
   * 5.1 获取空间模版列表
   * @returns
   */
  getSpaceTemplate: () => {
    return post("/system/app/space/getSpaceTemplate").then((response) => {
      return response;
    });
  },

  /**
   * 5.2 获取所有公开的玩家空间列表
   * @param {*} pageNum
   * @param {*} pageSize
   * @param {*} spaceName 空间名称
   * @returns
   */
  getPlayerSpaceList: (pageNum, pageSize, spaceName) => {
    return fetch("/system/app/space/getPlayerSpaceList", {
      pageNum,
      pageSize,
      spaceName,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 5.3 创建空间/修改空间
   * @param {*} id 传id修改 空新增
   * @param {*} shopId
   * @param {*} templateId 模版id
   * @param {*} coverImage 封面图片
   * @param {*} spaceName 空间名称
   * @param {*} templateData 模版内容
   * @param {*} isPublic 是否公开 0否 1是
   * @returns
   */
  insertPlayerSpace: (id, shopId, templateId, coverImage, spaceName, templateData, isPublic) => {
    return post("/system/app/space/insertPlayerSpace", {
      id,
      shopId,
      templateId,
      coverImage,
      spaceName,
      templateData,
      isPublic,
    }).then((response) => {
      return response;
    });
  },

  /**
   * 5.4 根据id获取空间详情
   * @param {*} id 玩家空间id
   * @returns
   */
  getInfo: (id) => {
    return fetch("/system/app/space/getInfo/" + id).then((response) => {
      return response;
    });
  },

  /**
   * 5.5 空间点赞/取消点赞
   * @param {*} id 玩家空间id
   * @returns
   */
  spaceLike: (id) => {
    return post("/system/app/space/spaceLike", { id }).then((response) => {
      return response;
    });
  },

  /**
   * 5.6 玩家nft资产列表
   * @param {*} pageNum
   * @param {*} pageSize
   * @returns
   */
  getNftList: (pageNum, pageSize) => {
    return fetch("/system/app/player/getNftList", { pageNum, pageSize }).then((response) => {
      return response;
    });
  },

  /**
   * 5.7 玩家相册列表
   * @param {*} pageNum
   * @param {*} pageSize
   * @returns
   */
  getPlayerPhotoList: (pageNum, pageSize) => {
    return fetch("/system/app/player/getPlayerPhotoList", { pageNum, pageSize }).then(
      (response) => {
        return response;
      },
    );
  },

  /**
   * 5.8 更新玩家相册/nft商品使用状态
   * @param {*} nftId nft资产 id
   * @param {*} photoId 相册id
   * @param {*} isUsed 是否已使用 0否 1是
   * @returns
   */
  updatePlayerSpaceUsed: (nftId, photoId, isUsed) => {
    return post("/system/app/player/updatePlayerSpaceUsed", { nftId, photoId, isUsed }).then(
      (response) => {
        return response;
      },
    );
  },

  /**
   * 5.9 个人空间
   * @param {*} pageNum
   * @param {*} pageSize
   * @param {*} shopId 商户id
   * @returns
   */
  getPlayerSpaceListSelf: (pageNum, pageSize, shopId) => {
    return fetch("/system/app/space/getPlayerSpaceListSelf", { pageNum, pageSize, shopId }).then(
      (response) => {
        return response;
      },
    );
  },

  /**
   * 5.10 专属空间
   * @returns
   */
  getPlayerSpaceListExclusive: () => {
    return fetch("/system/app/space/getPlayerSpaceListExclusive").then((response) => {
      return response;
    });
  },

  /**
   * 5.12 删除空间
   * @param {*} id
   * @returns
   */
  removePlayerSpace: (id) => {
    return post("/system/app/space/removePlayerSpace", { id }).then((response) => {
      return response;
    });
  },
  /**
   * 5.13 获取第三方画作详情
   * @param {*} resKey 画作id
   * @param {*} resLang 语种: 1-中文 2-英文
   * @returns
   */
  getCmsDetail: (resKey, resLang) => {
    return post("/system/app/game/cmsInfo", { resKey, resLang }).then((response) => {
      return response;
    });
  },
};
