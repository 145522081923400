/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* jshint esversion: 11 */
import shopApi from "@/api/shop";
import { getIsWxClient } from "@/utils/lib";

/**
 * 购买判断 适用所有购买
 */
export const BuyMixin = {
  data() {
    return {
      orderStatus: null, //1下单 2已经下单直接支付
      orderNo: "",
    };
  },
  methods: {
    // 测试数据
    testDb1(arg) {
      arg.presaleProductId = "5";
      arg.scheduleDate = "2022-06-1 13:52:03";
      arg.deadlineDate = "2022-06-25 13:52:03";

      arg.expiredDateBegin = "2022-06-11 13:52:03";
      arg.expiredDateEnd = "2022-06-30 13:52:03";
      arg.totalCirculation = "100";
      arg.stockQuantity = "1";
    },
    // 购买
    buy(orderStatus, orderNo) {
      this.orderStatus = orderStatus;
      this.orderNo = orderNo;
      // 实名制判断 0无实名 1实名
      if (this.$getters.userInfo.isVerified == 0) {
        this.checkRealName();
      } else {
        this.checkProductState();
      }
    },
    // 实名制
    async checkRealName(_text = "需实名认证后才可购买数字藏品") {
      try {
        const res = await this.$yaojingConfirm({
          title: "实名认证",
          text: _text,
          cancelButtonText: "取消",
          confirmButtonText: "认证",
        });
        this.$store.dispatch("change/show", "realName");
      } catch (error) {
        console.log("error: ", error);
      }
    },
    // 预约 库存 开放时间等
    async checkProductState() {
      try {
        const res = await shopApi.detail(this.value.id);
        // 测试数据
        // this.testDb1(res);

        // this.orderNo = 1;
        // 无库存
        if (res.stockQuantity == 0) {
          this.$yaojingToast("暂无库存，无法购买", "warning");
          return;
        }
        // 无预售
        // if (res.presaleProductId == "" || res.presaleProductId == null) {
        //   const isBefore = dayjs(new Date()).isBefore(dayjs(res.expiredDateBegin));
        //   if (isBefore) {
        //     this.$yaojingToast("不在购买期限无法购买", "warning");
        //     return;
        //   }
        // }
        // 有预售
        // if (res.presaleProductId != "" && res.presaleProductId != null) {
        //   const isBefore = dayjs(new Date()).isBefore(dayjs(res.scheduleDate));
        //   if (isBefore) {
        //     this.$yaojingToast("预售未开始，无法购买", "warning");
        //     return;
        //   } else {
        //     // 查看预约情况
        //     try {
        //       let bookingRes = await shopApi.bookingInfo(res.presaleProductId);
        //       // 测试数据
        //       bookingRes = { lucky: "1", orderNo: "0", orderStatus: "0" };
        //       // bookingRes = null;
        //       if (bookingRes == null) {
        //         this.$yaojingToast("请先预约后购买", "warning");
        //         return;
        //       } else {
        //         if (bookingRes.lucky == null) {
        //           this.$yaojingToast("请等待抽签完成后购买", "warning");
        //           return;
        //         }
        //         if (bookingRes.lucky == 0) {
        //           this.$yaojingToast("未中签，无法购买", "warning");
        //           return;
        //         }
        //         if (bookingRes.lucky == 1) {
        //           if (bookingRes.orderNo != null && bookingRes.orderStatus == 0) {
        //             this.orderStatus = 0;
        //           }
        //           if (bookingRes.orderNo != null && bookingRes.orderStatus == 1) {
        //             this.$yaojingToast("已经购买，无法重复购买", "warning");
        //             return;
        //           }
        //         }
        //       }
        //     } catch (error) {
        //       console.log(error);throw error;
        //     }
        //   }
        // }

        this.order();
      } catch (error) {
        throw new Error(error);
      }
    },
    // 下单
    order() {
      const order = {
        productName: this.productName,
        price: this.unitPrice,
        shopId: this.shopId,
        currencyType: this.currencyType || 2,
        details: [{ productId: this.value.id, quantity: 1 }],
        orderStatus: this.orderStatus,
      };
      console.log("order: ", order);

      if (this.orderStatus == 1) {
        return shopApi.createOrder(order.currencyType, order.shopId, order.details);
      }
      // 直接购买
      if (this.orderStatus == 2) {
        const wx = getIsWxClient() ? 1 : 0;
        this.$router.replace("/Payment/?orderNo=" + this.orderNo + "&wx=" + wx);
      }
    },
  },
};
