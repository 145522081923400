/* eslint-disable no-unused-vars */
/* jshint esversion: 6 */
import store from "../store";
import { triggerRwardAction } from "@/utils/taskfactory";
import router from "../router";
import avatarScreenShot from "./vueBridge/avatarScreenShot";
import open720 from "./vueBridge/open720";
import openYaolive from "./vueBridge/openYaolive";
import showActivityAboutCGI from "./vueBridge/showActivityAboutCGI";
import { yaojingToast } from "@/components/global";
import { post, fetch, nodePost } from "../utils/http";
import { getPlayerInfo } from "@/api/login";
import { createPickCode } from "@/api/coupons";
import PubSub from "pubsub-js";
import { Event } from "@/utils/event";
import spaceApi from "@/api/space";
import _ from "lodash";
import Cookies from "js-cookie";
// import utils from "@/utils/utils";
/**
 * cocosToVue
 * @param {*} params
 * @returns
 */
const cocosToVue = function (params) {
  console.log("收到 cocosToVue 消息", params);
  switch (params.cmd) {
    // 场景初始化 loading
    case 1:
      store.dispatch("change/show", {
        name: "loading",
        params: { landId: params.landId, progress: params.arg.progress },
      });
      break;
    // 结束Loading
    case "loading-end":
      store.dispatch("change/hidden", "loading");
      break;
    // cocos3D 基础库 加载完成
    case "cocosReady":
      store.dispatch("secne/update_cocos_baseassets", true);
      break;

    // 点击了场景上的卡牌， 弹出看卡牌信息
    case 2:
      store.dispatch("change/show", {
        name: "landInfo",
        params: { landId: params.landId },
      });
      // var sign = utils.UovaSignMD5("yaoland", "13283869473", "Vcite0os9XQBtqYT3nRJKyAjflMxFpqZ");
      // console.log(sign, "cocos1111");
      break;
    // 点击了场景上的卡牌， 弹出看卡牌信息
    case "showAwardAndAfterAction":
      triggerRwardAction(params.arg);
      break;
    // 点击3D场景中的物品， 弹出详情
    case "albumProduct":
      // 空间显示
      if (params.landId == 1) {
        spaceApi
          .getNftList(1, 999999)
          .then((res) => {
            const res1 = _.find(res.rows, ["id", params.arg.pid]);
            console.log("🚀 -> .then -> res1", params.arg);
            if (res1 === undefined) {
              if (params.arg.productId === undefined) {
                yaojingToast("无此数字藏品", "warning");
              } else {
                router.replace(
                  "/ShopDetail?productType=2" +
                    "&pid=" +
                    params.arg.productId +
                    "&myType=3" +
                    "&landId=1",
                );
              }
            } else {
              router.replace(
                "/ShopDetail?productType=2" +
                  "&pid=" +
                  res1.productId +
                  "&myType=1" +
                  "&landId=1" +
                  "&nftAssetId=" +
                  res1.nftAssetId +
                  "&nftSdsTxId=" +
                  res1.nftSdsTxId,
              );
            }
          })
          .catch((err) => {
            console.log("🚀 -> cocosToVue -> err", err);
          });
      } else {
        router.replace(
          "/Land/ShopDetail?productType=2&" +
            "pid=" +
            params.arg.productId +
            "&landId=" +
            store.getters.landId,
        );
      }
      break;
    // 打开图片详情
    case "openCMSItem":
      console.log(
        "%c ----->-105",
        "font-size:13px; background:pink; color:#bf2c9f;",
        params.arg.data,
      );
      if (store.getters.landId == 538) {
        Cookies.set("cms_data", JSON.stringify(params.arg.data));
        store.dispatch("thirdMy/set_cms_data", params.arg.data);
        const title = router.currentRoute.query.qybTitle || "曜境YAOLAND";
        console.log("%c ----->-110", "font-size:13px; background:pink; color:#bf2c9f;", title);
        router.replace(
          "/Land/ThirdDetail?productType=2&" +
            "pid=" +
            params.arg.data.resId +
            "&landId=" +
            store.getters.landId +
            "&qybTitle=" +
            title,
        );
      }
      break;
    // 显示 3D场景的UI
    case "visibleUI":
      store.dispatch("change/show", "landUiShowStatus");
      break;
    // 隐藏 3D场景的UI
    case "hiddenUI":
      store.dispatch("change/hidden", "landUiShowStatus");
      break;
    // 隐藏 组件
    case "hiddenCom":
      store.dispatch("change/hidden", params.arg.name);
      break;
    // 隐藏 3D场景的UI
    case "avatarScreenShot":
      avatarScreenShot(params.arg);
      break;
    // 打开720
    case "Open720":
      open720(Object.assign(params.arg, { landId: params.landId }));
      break;
    // 打开曜live
    case "OpenYaolive":
      openYaolive(Object.assign(params.arg, { landId: params.landId }));
      break;
    // 隐藏 3D场景的UI
    case "toast":
      yaojingToast(params.arg.text, params.arg.type || "info");
      break;
    // 隐藏 3D场景的UI
    case "hiddenNftViewLoading":
      store.dispatch("change/hidden", "nftViewLoading");
      break;
    //打开直播
    case "open263Live":
      store.dispatch("change/show", {
        name: "liveStream263",
        params: params.arg,
      });
      break;
    //关闭直播
    case "hiddenLive":
      store.dispatch("change/hidden", "liveStream263");
      break;
    //刷新用户信息
    case "updateUser":
      getPlayerInfo();
      break;
    //家果园 兑换券
    case "jgy":
      createPickCode(
        store.getters.userId,
        store.getters.landId,
        store.getters.userInfo.mobile,
        "jgy",
        1,
      );
      break;
    //删除画册面板
    case "deleteSpaceImg":
      PubSub.publish(Event.DELETE_PHOTO, params.arg);
      break;
    // 打开视频
    case "showActivityAboutCGI":
      showActivityAboutCGI(params.arg.type);
      break;
    // 打开iframe
    case "openIframe":
      console.log(
        "%c ----->-openIframe",
        "font-size:13px; background:pink; color:#bf2c9f;",
        params.arg,
      );
      if (!params.arg) return;
      store.dispatch("change/show", "openIframe");
      store.dispatch("change/setiframe", params.arg);
      break;
    default:
      store.dispatch(
        "change/show",
        params.arg
          ? {
              name: params.cmd,
              params: params.arg,
            }
          : params.cmd,
      );
      break;
  }

  return {
    success: true,
    dara: params,
  };
};

window.transmitDataToVue = function (params) {
  cocosToVue(params);
};

const postMsgFun = function (event) {
  if (event.data.type == "msgToYaoLand") {
    cocosToVue(event.data);
  }
};
window.addEventListener("message", postMsgFun);

window.proxyAPi = function (params) {
  if (params.method.toLowerCase() == "get") {
    fetch("/" + params.url, params.data)
      .then((res) => {
        params.success(res);
      })
      .catch((err) => {
        params.fail(err);
      });
  } else if (params.method.toLowerCase() == "nodepost") {
    nodePost("/" + params.url, params.data)
      .then((res) => {
        params.success(res);
      })
      .catch((err) => {
        params.fail(err);
      });
  } else {
    post("/" + params.url, params.data)
      .then((res) => {
        params.success(res);
      })
      .catch((err) => {
        params.fail(err);
      });
  }
};
