<!-- 任务 礼物 -->
<template>
  <reward-item
    @maskClose="handleCloseTip"
    @close="handleCloseTip"
    @tapBtn="handleCloseTip"
    :title="$getters['change/rewardEquips']['title']"
    :description="$getters['change/rewardEquips']['description']"
    :img="$getters['change/rewardEquips']['img']"
  ></reward-item>
</template>

<script>
// import { delay } from "@/utils/lib";
import RewardItem from "./RewardItem";
export default {
  name: "reward-equips",
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/rewardEquips"]) {
        this.$store.dispatch("change/hidden", "rewardEquips");
      }
    },
  },
  created() {
    this.$makeQuestEvent({
      type: "gotItem",
      itemId: this.$getters["change/rewardEquips"]["equipId"],
    });
    // delay(2).then(() => {
    //   this.handleCloseTip();
    // });
  },
  components: {
    RewardItem,
  },
};
</script>
