import { clearCurRewardAction } from "@/utils/taskfactory.js";
import { delay } from "@/utils/lib";

import { openLuckyDraw } from "@/utils/cocosBridge";

export default (params) => {
  openLuckyDraw(params.landId);
  delay(2).then(() => {
    clearCurRewardAction();
  });
};
