/* eslint-disable no-unused-vars */
import store from "../../../store";
import { clearCurRewardAction } from "@/utils/taskfactory.js";
import { delay } from "@/utils/lib";
import _collection from "lodash/collection";
import _lang from "lodash/lang";
import { savePlayAvatar, getPlayerAvatar, getDefaultAvatars } from "@/api/avatar";

import { setRewardSetGameAvatar } from "@/utils/cocosBridge";

import { landConfig } from "@/utils/landBaseInfo";

export default (params) => {
  getPlayerAvatar(landConfig[store.getters.landId]["avatarId"]).then((playerAvatar) => {
    const initavatars = playerAvatar.equips
      ? JSON.parse(playerAvatar.equips)
      : getDefaultAvatars(playerAvatar.defaults, playerAvatar.defaultBodyName);
    let replaceAvatar = {};

    const avatarDataJson = params.avatarData ? JSON.parse(params.avatarData) : "";
    if (avatarDataJson) {
      avatarDataJson.forEach((element) => {
        replaceAvatar[element.equipSubTypeKey] = element.equipName;
      });
    }
    const resultAvatar = Object.assign(initavatars, replaceAvatar);

    savePlayAvatar(playerAvatar.id, resultAvatar).then(() => {
      store.dispatch("secne/update_avatar_data", resultAvatar);
      setRewardSetGameAvatar(resultAvatar);
      delay(2).then(() => {
        savePlayAvatar;
        clearCurRewardAction();
      });
    });
  });
};
