/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* jshint esversion: 11 */
import { mapGetters } from "vuex";
import { bindSpaceImg } from "@/utils/cocosBridge";
import spaceApi from "@/api/space";
import PubSub from "pubsub-js";
import { Event } from "@/utils/event";
import _ from "lodash";

/**
 * 自定义空间 数字藏品 云相册
 */
export const MySpaceItemMixin = {
  computed: {
    ...mapGetters({
      mySpaceInfo: "space/mySpaceInfo",
      isChoose: "space/isChoose",
      chooseSpaceImg: "change/chooseSpaceImg",
    }),
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 更新
     * @param {*} type nft 或 photo
     */
    async update(_type) {
      const cocosObj = {
        spaceId: this.chooseSpaceImg.spaceId, //空间模板编号
        placeKey: this.chooseSpaceImg.placeKey, //画框KEY
        type: _type, // nft or photo
        pid: this.value.id,
        productId: _type == "photo" ? undefined : this.value.productId, // type is nft use productId or type is photo use id
        imgUrl: _type == "photo" ? this.value.imagePath : this.value.smallImage,
      };
      // 模板更新
      const templateData = JSON.parse(this.mySpaceInfo.templateData);
      templateData[this.chooseSpaceImg.placeKey] = cocosObj;
      // 更新玩家空间
      try {
        const res = await spaceApi.insertPlayerSpace(
          this.mySpaceInfo.id,
          this.mySpaceInfo.shopId,
          this.mySpaceInfo.templateId,
          this.mySpaceInfo.coverImage,
          this.mySpaceInfo.spaceName,
          JSON.stringify(templateData),
          this.mySpaceInfo.isPublic,
        );
        this.locked = true;
        // 刷新自定义空间数据
        const res1 = await spaceApi.getInfo(this.mySpaceInfo.id);
        this.$store.commit("space/mySpaceInfo", res1);
      } catch (error) {
        throw new Error(error);
      }
      // 绑定三维
      bindSpaceImg(cocosObj);
      // 内容刷新
      if (_type == "photo") {
        PubSub.publish(Event.REFRESH_MYSPACE_PHOTO, { id: this.value.id });
      } else {
        PubSub.publish(Event.REFRESH_MYSPACE_NFT, { id: this.value.id });
      }
    },
    /**
     * 空间图片是否使用
     * @param {*} id nft productId photo id
     */
    spaceIsUsed(id) {
      // 测试数据
      //   const test = {
      //     a01: {
      //       spaceId: "7", //空间模板编号
      //       placeKey: "a01", //画框KEY
      //       type: "photo", // nft 或 photo
      //       pid: "18", // 如果type是 nft，就有pid
      //       imgUrl: "https://xxxx.jpg",
      //     },
      //     c01: {
      //       spaceId: "7", //空间模板编号
      //       placeKey: "c01", //画框KEY
      //       type: "nft", // nft 或 photo
      //       pid: "19", // 如果type是 nft，就有pid
      //       imgUrl: "https://xxxx.jpg",
      //     },
      //   };

      const templateData = JSON.parse(this.mySpaceInfo.templateData);
      // const arr = Object.values(test);
      const arr = Object.values(templateData);
      const res = _.find(arr, ["pid", id]);
      if (res === undefined) {
        this.locked = false;
      } else {
        this.locked = true;
      }
    },
  },
};
