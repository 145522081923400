<!-- 任务 礼物 -->
<template>
  <div :class="$style['reward-tips-page']">
    <div :class="$style['reward-tips-page-mask']" @click="handleCloseMask"></div>
    <div :class="$style['reward-wrapper']">
      <span :class="$style['reward-close-icon']" @click="handleCloseTip">
        <van-icon name="cross" :size="14" />
      </span>
      <div :class="$style['reward-title']" class="ellipsis2" v-if="title">
        {{ title }}
      </div>

      <div :class="$style['reward-img-wrapper']" v-if="img">
        <img :src="img" alt="" />
      </div>

      <div :class="$style['reward-description']" v-if="description">
        <p style="padding-bottom: 4px">
          {{ description }}
        </p>
        <p style="padding-bottom: 4px" v-if="subDescription">
          {{ subDescription }}
        </p>
        <div data-buy-wrapper v-if="buttonText">
          <slot v-if="$slots.buttonwrapper" name="buttonwrapper"></slot>
          <van-button v-else @click="$emit('tapBtn')" data-buy size="small" color="#665CFF">
            {{ buttonText }}
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { delay } from "@/utils/lib";

export default {
  name: "reward-item",
  data() {
    return {};
  },
  props: {
    title: {
      default: "",
      type: String,
    },
    description: {
      default: "",
      type: String,
    },
    subDescription: {
      default: "",
      type: String,
    },
    img: {
      default: "",
    },
    buttonText: {
      default: "开心收下",
    },
  },
  methods: {
    handleCloseTip() {
      this.$emit("close");
    },
    handleCloseMask() {
      this.$emit("maskClose");
    },
  },
  created() {
    delay(400).then(() => {
      this.handleCloseTip();
    });
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.reward-close-icon {
  width: 25px;
  height: 25px;
  padding: 5px;
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  cursor: pointer;
}

.reward-tips-page {
  position: absolute;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reward-tips-page-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.reward-wrapper {
  position: absolute;
  width: 233px;
  min-height: 230px;
  padding: 22px 30px 14px;
  background: transparentize(#121212, 0.2);
  background-image: url("@/assets/tips/lightbg.png");
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  text-align: center;
  color: white;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.reward-title {
  font-size: 14px;
  padding-bottom: 8px;
}

.reward-img-wrapper {
  position: relative;
  img {
    max-width: 180px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.reward-description {
  width: 100%;
  font-size: 10px;
  padding-top: 8px;
  [data-buy] {
    margin-top: 6px;
    width: 120px;
  }
}
</style>
