<!-- 提示 -->
<template>
  <div class="toast-page">
    <van-icon name="info-o" color=" #ff8b0f" size="16px" />
    <span class="toast-text">{{ text }}</span>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

export default {
  name: "toastIcon",
  components: {},
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.toast-page {
  position: relative;
  width: 200px;
  height: 40px;
  background: #383838;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 15px;
}

.toast-text {
  margin: 0 0 0 5px;
  color: #abaaab;
  font-size: 14px;
  line-height: 16px;
}
</style>
