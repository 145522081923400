<!-- 空结果 -->
<template>
  <div class="empty-tips-page">
    <div class="empty-msg-box">
      <img src="@/assets/space/msg_empty.png" style="width: 100%" />
      <p style="text-align: center">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "emptyTipsComponent",
  components: {},
  props: {
    text: {
      type: String,
      default: "空空如也",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.empty-tips-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-msg-box {
  position: absolute;
  top: 30%;
  width: 185px;
  font-size: $--font_size_28;
}

html.mobile.landscape {
  .empty-msg-box {
    top: 20%;
  }
}
</style>
