<!-- 任务 金钱 -->
<template>
  <div class="money-tips-page" @click="onClose()">
    <div class="money-wrapper animate__animated animate__zoomIn">
      <div class="tips-img-bg">
        <p data-title1 class="ellipsis">恭喜你，得到10Y币</p>
        <img
          src="@/assets/icon/money2.svg"
          style="width: 40%; font-size: 0; margin-top: 40px"
          alt=""
        />
        <div data-title2>
          <img src="@/assets/icon/money1.svg" style="font-size: 0" alt="" />
          <countTo
            ref="ct"
            :startVal="startVal"
            :endVal="endVal"
            :duration="1000"
            :autoplay="false"
            data-title-number
          ></countTo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
import countTo from "vue-count-to";
import { delay } from "@/utils/lib";

export default {
  name: "moneyTipsComponent",
  components: { countTo },
  props: {},
  data() {
    return { startVal: 0, endVal: 2022 };
  },
  computed: {
    ...mapGetters({
      money: "tips/money",
    }),
  },
  watch: {},
  methods: {
    onClose() {
      this.$store.dispatch("change/hidden", "moneyTips");
    },
  },
  created() {
    this.endVal = this.money;
  },
  mounted() {
    delay(0.6).then(() => {
      this.$refs.ct.start();
    });
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.money-tips-page {
  position: absolute !important;
  z-index: 500 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparentize(#0d0c1d, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.money-wrapper {
  position: relative;
  width: 233px;
  height: 230px;
  background: #121212;
  border-radius: 5px;
  display: flex;

  justify-content: center;
  align-items: center;
}

.tips-img-bg {
  width: 203px;
  height: 203px;
  position: relative;

  background-position: center;
  background-size: cover;
  background-image: url("@/assets/land/tipsbg.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  [data-title1] {
    font-size: $--font_size_32;
    color: $--color_text_1;
    margin-top: 5px;
    width: 100%;
    text-align: center;
  }
  [data-title2] {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  [data-title-number] {
    font-size: $--font_size_36;
    color: $--color_text_1;
    margin-left: 5px;
  }
}
</style>
