<!-- 昵称修改 -->
<template>
  <content-layout
    isShowTopTitle="show"
    :isFull="true"
    ref="contentLayout"
    @close="handleClose()"
    @back="handleClose()"
    topTitleText="修改昵称"
    rightTitleText="设置"
  >
    <div :class="$style['my-setting-nickname-wrapper']">
      <div :class="$style['my-nickname']">
        <van-field
          :class="$style['nickname-input']"
          center
          clearable
          maxlength="10"
          v-model="nickName"
          :placeholder="'请输入2-10个字符的昵称'"
          class="space-create-input"
        />

        <div>
          <van-button
            data-name-btn
            type="primary"
            :color="$global.color_primary_2"
            @click="handleSave()"
            >保存</van-button
          >
        </div>
      </div>
    </div>
  </content-layout>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import ContentLayout from "@/components/ContentLayout";
import { getPlayerInfo, updatePlayerInfo } from "@/api/login";
import { checktext } from "@/api/nodeapi";

export default {
  name: "my-nickname",
  components: {
    ContentLayout,
  },
  computed: {},
  watch: {},
  data() {
    return {
      nickName: "",
    };
  },
  methods: {
    handleClose() {
      this.$store.dispatch("change/hidden", "mySettingNickName");
    },

    async handleSave() {
      if (this.nickName.length < 2 || this.nickName.length > 20) {
        this.$yaojingToast("请输入2-20个字符的昵称", "warning");
        return;
      }

      const reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
      if (!reg.test(this.nickName)) {
        this.$yaojingToast("昵称只能中文，数字，字母", "warning");
        return;
      }
      // 违规检查
      try {
        const res = await checktext(this.nickName);
        if (res.conclusionType == 1) {
          this.changeNiceName();
        } else {
          this.$yaojingToast("昵称有违禁词", "warning");
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    // 昵称修改
    async changeNiceName() {
      try {
        const res = await updatePlayerInfo({
          id: this.$getters.userInfo.id,
          nickName: this.nickName,
        });
        await getPlayerInfo();
        this.$store.dispatch("change/hidden", "mySettingNickName");
        this.$yaojingToast("昵称修改成功", "success");
      } catch (error) {
        this.$yaojingToast("昵称修改失败", "warning");
        throw new Error(error);
      }
    },
  },
  created() {
    if (this.$getters.userInfo.nickName != "" && this.$getters.userInfo.nickName != null) {
      this.nickName = this.$getters.userInfo.nickName;
    }
  },
  mounted() {},
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.my-setting-nickname-wrapper {
  height: 100%;
  background-color: $--color_primary_1;
  position: relative;
}

.my-nickname {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 30px;
  [data-name-input] {
    border: 1px solid $--color_black_1;
    outline: none;
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    font-size: $--font_size_28;
    text-align: center;
  }
  [data-name-btn] {
    height: 44px;
    border-radius: 0;
    width: 100%;
    margin-top: 30px;
  }

  :global(.van-cell::after) {
    border-bottom: 0;
  }
  :global(.van-field__clear) {
    color: $--color_text_4;
    position: absolute;
    right: 0px;
  }
  :global(.van-field__control) {
    color: $--color_text_1;
  }
  :global(.van-field__control)::-webkit-input-placeholder {
    color: $--color_text_6;
  }
}

.nickname-input {
  height: 45px;
  border: 1px solid #525252;
  margin-top: 15px;
  padding-left: 30px;
  background-color: $--color_primary_1;
  font-size: $--font_size_36;
}

html:global(.mobile):global(.landscape) {
  .my-setting-nickname-wrapper {
    padding: 5px;
    background-color: $--color_bg_line_1;
  }
  .my-nickname {
    padding: 20px 25px;
    [data-name-input] {
      height: 32px;
      line-height: 32px;
      padding: 0 12px;
      font-size: $--font_size_24;
    }
    [data-name-btn] {
      height: 32px;
      border-radius: 0;
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
