/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import { fetch, post } from "@/utils/http.js";
// import _ from "lodash";

/**
 * 实名制
 */
export default {
  /**
   * 1.7 身份证验证
   * @param {*} realName
   * @param {*} identityCardId
   * @returns
   */
  playerVerified: (realName, identityCardId) => {
    return post("/system/app/player/playerVerified", { realName, identityCardId }).then(
      (response) => {
        return response;
      },
    );
  },

  /**
   * 1.8 创建数字账户
   * @returns
   */
  createNftAccount: () => {
    return post("/system/app/player/createNftAccount").then((response) => {
      return response;
    });
  },
};
