// eslint-disable-next-line no-unused-vars
import { questDone, updatePlayerQuest, playerInProgress, receiveQuest } from "@/api/quest";
import _array from "lodash/array";
import _collection from "lodash/collection";
import _object from "lodash/object";
import afterPlayVideo from "./taskfactory/afterAction/afterPlayVideo";
import afterOpenIframe from "./taskfactory/afterAction/afterOpenIframe";
import afterSetAvatar from "./taskfactory/afterAction/afterSetAvatar";
import afterOpenUI from "./taskfactory/afterAction/afterOpenUI";
import afterNewQuest from "./taskfactory/afterAction/afterNewQuest";
import afterluckyDraw from "./taskfactory/afterAction/afterluckyDraw";
import afterPrizeImage from "./taskfactory/afterAction/afterPrizeImage";
import rewardY from "./taskfactory/rewardAction/rewardY";
import rewardExperience from "./taskfactory/rewardAction/rewardExperience";
import rewardEquips from "./taskfactory/rewardAction/rewardEquips";
import rewardSpace from "./taskfactory/rewardAction/rewardSpace";
import rewardFeature from "./taskfactory/rewardAction/rewardFeature";
import rewardCoupon from "./taskfactory/rewardAction/rewardCoupon";
import rewardCouponBuy from "./taskfactory/rewardAction/rewardCouponBuy";
import rewardThirdPoint from "./taskfactory/rewardAction/rewardThirdPoint";
import store from "../store";
// store.dispatch("quest/update_donull_quest_number", questDoneList.length);
/**
 * 任务触发机制， 次数完成类任务
 */
export const makeQuestEvent = async (params, params2 = {}) => {
  const otherParams = Object.assign({ numStep: 1 }, params2);
  const questDoneList = (await playerInProgress()).filter((item) => {
    return item.completeCondition != null;
  });

  questDoneList.forEach((questItem) => {
    const completeCondition = questItem.playerQuestData.questNeedItem
      ? JSON.parse(questItem.playerQuestData.questNeedItem)
      : typeof questItem.completeCondition == "object"
      ? questItem.completeCondition
      : JSON.parse(questItem.completeCondition);
    let _updateDataItem = [];
    //遍历下完成条件，
    completeCondition.forEach((element) => {
      let baseData = "";
      let compareData = "";
      _object.forIn(params, function (value, key) {
        baseData = baseData + "_" + (element[key] == "all" ? value : element[key]);
        compareData = compareData + "_" + value;
      });

      if (baseData == compareData) {
        const hasDone = element.done * 1;
        if (hasDone < element.num) {
          //如果当前的完成数还小于需求数 done + 1 在保存
          let item_hit = element;
          item_hit.done = hasDone + otherParams.numStep * 1;
          _updateDataItem.push(item_hit);
        }
      } else {
        _updateDataItem.push(element);
      }
    });

    let _num = 0;
    let _done = 0;
    _updateDataItem.forEach((item) => {
      _num = _num + item.num * 1;
      _done = _done + item.done * 1;
    });
    if (_done >= _num) {
      //完成任务 执行
      updatePlayerQuest(questItem.playerQuestData.id, JSON.stringify(_updateDataItem)).then(() => {
        questDone(questItem.playerQuestData.id).then((questRewards) => {
          store.dispatch("homeQuest/get_home_quests");
          store.dispatch("landQuest/get_land_quests");

          const parentQuestRewardItem = questRewards
            ? questRewards.questRewardItem
              ? JSON.parse(questRewards.questRewardItem)
              : []
            : [];

          const parentAfterAction = questRewards
            ? questRewards.afterAction
              ? JSON.parse(questRewards.afterAction)
              : []
            : [];

          const childrenQuestRewardItem = questItem.questRewardItem
            ? JSON.parse(questItem.questRewardItem)
            : [];

          const childrenAfterAction = questItem.afterAction
            ? JSON.parse(questItem.afterAction)
            : [];

          var rwardActions = _array.concat(
            childrenQuestRewardItem,
            childrenAfterAction,
            parentQuestRewardItem,
            parentAfterAction,
          );
          triggerRwardAction(rwardActions);
        });
      });
    } else if (_done > 0 && _done < _num) {
      //还没完成任务，保存信息
      updatePlayerQuest(questItem.playerQuestData.id, JSON.stringify(_updateDataItem));
    } else {
      console.log("没有命中");
    }
  });
  return questDoneList;
};

/**
 * 格式化 任务列表 ， 在首页和岛的首页 显示任务清单
 */
export const mapQuestList = (questDatas) => {
  const soetArrys = _collection.orderBy(
    questDatas
      .filter((questItem) => {
        return !!questItem.playerQuestData;
      })
      .map((item) => {
        return Object.assign(item, {
          parentSort: item.children.length,
          childrenSort: item.playerQuestData.questStatus,
        });
      }),
    ["childrenSort", "parentSort"],
    ["asc", "desc"],
  );

  return soetArrys.map((item) => {
    if (item.children.length > 0) {
      const _children = item.children
        .filter((childrenItem) => {
          return !!childrenItem.playerQuestData;
        })
        .map((chidrenItem) => {
          let completeCondition = chidrenItem.playerQuestData.questNeedItem
            ? JSON.parse(chidrenItem.playerQuestData.questNeedItem)
            : typeof chidrenItem.completeCondition == "object"
            ? chidrenItem.completeCondition
            : JSON.parse(chidrenItem.completeCondition);
          let _num = 0;
          let _done = 0;
          completeCondition.forEach((subitem) => {
            _num = _num + subitem.num * 1;
            _done = _done + subitem.done * 1;
          });
          return Object.assign(chidrenItem, {
            completeCondition: completeCondition,
            num: _num,
            done: _done,
          });
        });

      const parent_num = item.children.length;
      const parent_done = item.children.filter((subItem) => {
        return subItem.playerQuestData && subItem.playerQuestData.questStatus == 1;
      }).length;

      return Object.assign(item, { num: parent_num, done: parent_done, children: _children });
    } else {
      // 父任务
      let completeCondition = item.playerQuestData.questNeedItem
        ? JSON.parse(item.playerQuestData.questNeedItem)
        : typeof item.completeCondition == "object"
        ? item.completeCondition
        : JSON.parse(item.completeCondition);
      let _num = 0;
      let _done = 0;
      completeCondition.forEach((subitem) => {
        _num = _num + subitem.num * 1;
        _done = _done + subitem.done * 1;
      });
      return Object.assign(item, {
        completeCondition: completeCondition,
        num: _num,
        done: _done,
      });
    }
  });
};

//奖励和后置动作列表
let reward_actions = [];
//当前奖励和后置动作
let now_reward_actions = "";

let intervalLoop = "";

/**
 * 出发奖励和后置任务
 */
export const triggerRwardAction = (items) => {
  reward_actions = _array.concat(reward_actions, items);
  if (reward_actions.length < 1) return;
  loopShowAction();
};

/**
 * 清除当前的奖励信息
 */
export const clearCurRewardAction = () => {
  now_reward_actions = "";
};

/**
 * 循环显示奖励和后置动作信息
 */
const loopShowActionItem = () => {
  console.log("qingyibo1");
  if (now_reward_actions) return;
  console.log("qingyibo2");
  now_reward_actions = reward_actions[0];
  if (now_reward_actions.actionType == "reward") {
    // store.dispatch("change/show", {
    //   name: now_reward_actions.type,
    //   params: now_reward_actions,
    // });
    console.log("qingyibo3");
    switch (now_reward_actions.type) {
      case "rewardY":
        rewardY(now_reward_actions);
        break;
      case "rewardExperience":
        rewardExperience(now_reward_actions);
        break;
      case "rewardEquips":
        rewardEquips(now_reward_actions);
        break;
      case "rewardSpace":
        rewardSpace(now_reward_actions);
        break;
      case "rewardFeature":
        rewardFeature(now_reward_actions);
        break;
      case "rewardCoupon":
        rewardCoupon(now_reward_actions);
        break;
      case "rewardCouponBuy":
        rewardCouponBuy(now_reward_actions);
        break;
      case "reward_shyaf_point":
        console.log("qingyibo-START", now_reward_actions);
        rewardThirdPoint(now_reward_actions);
        clearCurRewardAction();
        console.log("qingyibo-END", now_reward_actions);
        break;
      default:
        console.log("默认");
        break;
    }
  }
  if (now_reward_actions.actionType == "afterAction") {
    switch (now_reward_actions.type) {
      case "playVideo":
        afterPlayVideo(now_reward_actions);
        break;
      case "openIframe":
        afterOpenIframe(now_reward_actions);
        break;
      case "setAvatar":
        afterSetAvatar(now_reward_actions);
        break;
      case "openUI":
        afterOpenUI(now_reward_actions);
        break;
      case "luckyDraw":
        afterluckyDraw(now_reward_actions);
        break;
      case "newQuest":
        afterNewQuest(now_reward_actions);
        break;
      case "prizeImage":
        afterPrizeImage(now_reward_actions);
        break;

      default:
        console.log("默认");
        break;
    }
  }
  reward_actions = _array.drop(reward_actions);
};

const loopShowAction = () => {
  console.log("qingyibo9", reward_actions);
  if (reward_actions.length < 1) {
    return;
  }
  console.log("qingyibo10", reward_actions.length);
  if (intervalLoop) return;
  intervalLoop = window.setInterval(() => {
    loopShowActionItem();
    console.log("qingyibo11", reward_actions.length);
    if (reward_actions.length < 1) {
      window.clearInterval(intervalLoop);
      intervalLoop = "";
    }
  }, 1000);
};
