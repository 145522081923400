import store from "../../../store";

export default (params) => {
  let prizeImageParams = params;
  // 大树夏的证书
  if (params.prizeType == 1) {
    prizeImageParams = Object.assign(params, {
      img: "prize1.png",
      name_position: {
        top: "260px",
        left: "436px",
      },
      date_position: {
        bottom: "114px",
        left: "318px",
      },
    });
  }
  // 彩虹岛的证书
  if (params.prizeType == 2) {
    prizeImageParams = Object.assign(params, {
      img: "prize2.png",
      name_position: {
        top: "218px",
        left: "378px",
      },
      date_position: {
        bottom: "120px",
        left: "200px",
      },
    });
  }
  store.dispatch("change/show", {
    name: "afterPrizeImage",
    params: prizeImageParams,
  });
};
