import { updatePlayerInfo } from "@/api/login";
import { yaojingToast } from "@/components/global";
import store from "../../store";
export default (params = {}) => {
  if (params.img) {
    updatePlayerInfo({
      profilePhoto: params.img,
      id: store.getters.userInfo.id,
      nickName: store.getters.userInfo.nickName,
    }).then(() => {
      store.dispatch("secne/set_user_info", { profilePhoto: params.img }).then(() => {
        yaojingToast("头像更新成功", "success");
      });
    });
  } else {
    updatePlayerInfo({
      profilePhoto: params.img,
      id: store.getters.userInfo.id,
      nickName: store.getters.userInfo.nickName,
    }).then(() => {
      store.dispatch("secne/set_user_info", { profilePhoto: params.img }).then(() => {
        yaojingToast("头像更新成功", "success");
      });
    });
  }
};
