<template>
  <reward-item
    v-if="detail"
    @maskClose="handleCloseTip"
    @close="handleCloseTip"
    @tapBtn="handleCloseTip"
    title="恭喜您获得数字藏品"
    :description="detail.productName"
    :img="detail.smallImage"
  ></reward-item>
</template>

<script>
import RewardItem from "./RewardItem";
import shopApi from "@/api/shop";

export default {
  name: "reward-nft",
  data() {
    return {
      detail: "",
    };
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/rewardNFT"]) {
        this.$store.dispatch("change/hidden", "rewardNFT");
      }
    },

    handleOpenNftDetail() {
      shopApi.detail(this.$getters["change/rewardNFT"]["productId"]).then((res) => {
        this.detail = res;
      });
    },
  },
  created() {
    this.handleOpenNftDetail();
  },
  components: {
    RewardItem,
  },
};
</script>
