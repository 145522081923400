<!-- 任务 礼物 -->
<template>
  <div :class="$style['after-action-tips-page']">
    <!-- <span
      v-if="!$getters['change/afterActionOpenIframe']['hiddenClose']"
      :class="$style['after-action-close-icon']"
      @click="handleCloseTip"
    >
      <img style="width: 100%" src="@/assets/land/menu/close.svg" alt="" />
    </span> -->

    <span :class="$style['after-action-close-icon']">
      <van-button
        v-if="!$getters['change/afterActionOpenIframe']['hiddenClose']"
        @click="handleCloseTip"
        icon="arrow-left"
        color="#665cff"
        round
        size="small"
        type="success"
        >返回元宇宙</van-button
      >
    </span>

    <div data-iframe-wrapper>
      <iframe
        :src="iframeUrl"
        frameborder="0"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapQueryURLParams } from "@/utils/lib";
// import { delay } from "@/utils/lib";

export default {
  name: "reward-after-action-play-video",
  data() {
    return {};
  },
  computed: {
    iframeUrl() {
      if (this.$getters["change/afterActionOpenIframe"]["type"] == "game") {
        return mapQueryURLParams(
          this.$getters["change/afterActionOpenIframe"]["url"],
          "userId=" + this.$getters.userId + "&t=" + new Date().getTime(),
        );
      } else {
        return this.$getters["change/afterActionOpenIframe"]["url"];
      }
    },
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/afterActionOpenIframe"]) {
        this.$store.dispatch("change/hidden", "afterActionOpenIframe");
      }
    },
  },
  beforeDestroy() {
    this.$clearCurRewardAction();
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.after-action-close-icon {
  padding: 12px;
  position: fixed;
  display: inline-block;
  top: 0;
  left: 0;
  z-index: 99999;
  cursor: pointer;
  pointer-events: auto;
}

.after-action-tips-page {
  position: absolute;
  z-index: 1;
  background-color: #0d0c1d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  [data-iframe-wrapper] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    iframe {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      outline: none;
      border: none;
    }
  }
}
</style>
