<template>
  <div :class="$style['awatar-main-gender']">
    <div :class="$style['awatar-top-gender']">
      <div style="flex-shrink: 0" data-awatar-top-text>
        <p data-awatar-toplabel1>定制你的专属形象</p>
        <p data-awatar-toplabel2>{{ step == 2 ? "请创建角色昵称" : "请选择角色性别" }}</p>
      </div>
      <div style="font-size: 0">
        <img
          src="@/assets/avatar/avatar.png"
          style="width: 100%; position: absolute; bottom: 0; left: 0"
          alt=""
        />
      </div>
    </div>

    <div :class="$style['awatar-bottom-gender']">
      <template v-if="step == 1">
        <div style="flex-shrink: 0" data-awatar-bottom-text>
          <p data-awatar-toplabel1>定制你的专属形象</p>
          <p data-awatar-toplabel2>请选择角色性别</p>
        </div>

        <div :class="$style['avatar-imgselectbox']">
          <div
            v-for="item in avartarBodys"
            :key="'gender' + item.equipName"
            :data-avatarimgselectitem-active="item.equipName == avatarBody"
            @click="handleSelectGender(item.equipName)"
          >
            <p data-avatarimgselectitem-img>
              <img
                :src="item.equipImage"
                style="width: 100%; height: 100%; object-fit: cover"
                alt=""
              />
            </p>
            <p data-avatarimgselectitem-text>{{ genderEmu[item.equipName] }}</p>
          </div>
        </div>

        <van-button
          :style="{ width: '100%', 'font-size': $global.font_size_32 }"
          :color="$global.color_primary_2"
          @click="handleSaveGender"
        >
          下一步
        </van-button>
      </template>
      <avatar-name v-else @savename="handleSaveName"></avatar-name>
    </div>
  </div>
</template>

<script>
import AvatarName from "@/components/avatar/AvatarName";
export default {
  name: "awatar_gender",
  data() {
    return {
      gender: "body_female", //0:女士 1:男生
      genderEmu: {
        body_male: "男",
        body_female: "女",
      },
      avatarBody: "body_male",
      step: 1,
    };
  },
  props: {
    avartarBodys: {
      default: () => {
        return [];
      },
      type: Array,
    },
  },
  methods: {
    /**
     * 选择了男女
     */
    handleSelectGender(equipName) {
      this.avatarBody = equipName;
      this.$emit("changeGender", { body: equipName });
    },

    handleSaveGender() {
      if (!this.avatarBody) {
        this.$yaojingToast("请先选择角色", "warning");
        return;
      }
      this.step = 2;
    },

    handleSaveName() {
      this.$emit("save", { body: this.avatarBody });
    },
  },

  components: {
    AvatarName,
  },
};
</script>

<style lang="scss" module>
@import "../../assets/themes.scss";
.awatar-main-gender {
  position: absolute;
  z-index: 1;
  background-color: $--color_primary_1;
  color: $--color_text_1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  [data-awatar-main-gender-1] {
    pointer-events: auto;
    cursor: pointer;
    position: absolute;
    font-size: $--font_size_36;
    padding: 20px;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.awatar-top-gender {
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0 0 50px;
  background: linear-gradient(360deg, $--color_primary_2 0%, $--color_other_1 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  [data-awatar-toplabel1] {
    font-size: $--font_size_68;
    padding-bottom: 12px;
  }
  [data-awatar-toplabel2] {
    font-size: $--font_size_32;
  }
}

.awatar-bottom-gender {
  height: 50%;
  position: absolute;
  padding: 48px 30px 20px 30px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  pointer-events: auto;
  [data-awatar-bottom-text] {
    text-align: center;
    display: none;
  }
  [data-awatar-toplabel1] {
    font-size: $--font_size_44;
    line-height: 30px;
    padding-bottom: 6px;
  }
  [data-awatar-toplabel2] {
    font-size: $--font_size_32;
  }
  :global(.van-button) {
    height: 50px;
  }
}
.avatar-imgselectbox {
  display: flex;
  justify-content: space-between;
  color: #878787;
  [data-avatarimgselectitem-img] {
    width: 134px;
    height: 134px;
    position: relative;
    border-radius: 134px;
    overflow: hidden;
    border: 3px solid $--color_primary_1;
  }
  [data-avatarimgselectitem-active] {
    [data-avatarimgselectitem-img] {
      border-color: $--color_primary_2;
    }
    color: $--color_primary_2;
  }
  [data-avatarimgselectitem-text] {
    font-size: $--font_size_4;
    padding-top: 18px;
    line-height: 27px;
    text-align: center;
  }
}
html:global(.landscape) {
  .awatar-top-gender {
    width: 50%;
    height: 100%;
    left: 0;
    right: auto;
    // padding: 21px 0px 0 23px;
    [data-awatar-toplabel1] {
      display: none;
    }
    [data-awatar-toplabel2] {
      display: none;
    }
  }

  .awatar-bottom-gender {
    width: 50%;
    height: 100%;
    left: auto;
    right: 0;
    justify-content: space-between;
    [data-awatar-bottom-text] {
      display: block;
    }
  }
  .awatar-bottom-gender {
    padding: 16px 46px 20px 46px;
    [data-avatarimgselectitem-img] {
      width: 100px;
      height: 100px;
    }
    [data-avatarimgselectitem-text] {
      padding-top: 8px;
    }
  }
}

html:not(:global(.mobile)):global(.landscape) {
  .awatar-top-gender {
    display: none !important;
  }
  .awatar-bottom-gender {
    width: 100%;
    height: 100%;
    justify-content: space-around;
  }
}
</style>
