<!-- 全景 -->
<template>
  <div :class="$style['aou-main-wrapper']" v-if="callData">
    <div :class="$style['aou-main-box']">
      <div :class="$style['aou-main-left']">
        <p aou-main-left-img>
          <img :src="callData.image" alt="" />
        </p>
      </div>

      <div :class="$style['aou-main-right']">
        <span :class="$style['aou-main-close-icon']" @click="handleCloseTip">
          <van-icon name="cross" :size="14" />
        </span>
        <div :class="$style['aou-main-right-box']">
          <p data-aou-right-1>{{ callData.name }}</p>
          <p data-aou-right-2>介绍</p>
          <p data-aou-right-3>{{ callData.remark }}</p>
        </div>

        <!-- <div @click="handleTicketVoteItem" :class="$style['aou-main-right-box-bottom']">
          <div data-aou-right-4>
            <p style="font-size: 0">
              <img src="@/assets/icon/like-2.svg" alt="" />
            </p>
            <p data-aou-number>{{ callData.voteCount }}</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getSurveygetOption, ticketVoteItem } from "@/api/ticket";
// import shopApi from "@/api/shop";
import Cookies from "js-cookie";
export default {
  name: "aou-wrapper",
  data() {
    return {
      callData: "",
    };
  },
  created() {
    this.initData();
  },
  props: {},
  methods: {
    handleCloseTip() {
      if (this.$getters["change/hydeoutDacall"]) {
        this.$store.dispatch("change/hidden", "hydeoutDacall");
      }
    },

    /**
     * 获取初始化数据
     */
    initData() {
      getSurveygetOption(this.$getters["change/hydeoutDacall"]["optionIds"]).then((res) => {
        this.callData = res;
      });
    },

    /**
     * 投票
     */
    // handleTicketVoteItem() {
    //   if (!Cookies.get("tickfree")) {
    //     this.handleTicket();
    //   } else {
    //     shopApi
    //       .createOrder(0, this.$getters.landId, [{ productId: 209, quantity: 1 }])
    //       .then((orderNo) => {
    //         shopApi.payOrderY(orderNo).then(() => {
    //           this.handleTicket();
    //         });
    //       });
    //   }
    // },

    /**
     * 投票
     */
    handleTicketVoteItem() {
      const ticketNum = (Cookies.get("ticketNumber") || 0) * 1;
      if (ticketNum * 1 < 5) {
        this.handleTicket(ticketNum);
      } else {
        this.$yaojingToast("每天最多只能投票5次！", "warning");
      }
    },

    handleTicket(ticketNum) {
      ticketVoteItem(this.callData.surveyId, this.callData.id).then(() => {
        this.$yaojingToast("投票成功", "success");
        this.callData.voteCount = this.callData.voteCount * 1 + 1;

        Cookies.set("ticketNumber", ticketNum + 1, { expires: 1 });
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.aou-main-wrapper {
  position: absolute;
  z-index: 2;
  pointer-events: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: transparentize(#0d0c1d, 0.5);
  color: $--color_text_1;
}

.aou-main-box {
  position: absolute;
  height: 250px;
  width: 520px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-play-btn {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  margin: auto;
}

.aou-main-left {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 240px;
  text-align: right;
  overflow: auto;
  [aou-main-left-img] {
    height: 100%;
    font-size: 0;
    img {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
    }
  }
}

.aou-main-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 270px;
  height: 100%;
  background-color: #0d0c1d;
  padding-bottom: 12px;
  [data-aou-right-1] {
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 20px;
  }

  [data-aou-right-2] {
    font-size: 13px;
    line-height: 20px;
    color: #6555ff;
  }

  [data-aou-right-3] {
    font-size: 12px;
    line-height: 16px;
    color: #cccad3;
    padding-bottom: 16px;
    word-break: break-word;
  }

  [data-aou-right-4] {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    cursor: pointer;
    img {
      width: 16px;
    }
    [data-aou-number] {
      padding-left: 2px;
      font-size: 12px;
      padding-bottom: 2px;
    }
  }
}

.aou-main-right-box {
  position: relative;
  height: 100%;
  overflow: auto;
  z-index: 1;
  padding: 10px 24px 8px 14px;
}
.aou-main-right-box-bottom {
  height: 56px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 20px 8px 14px;
}

.aou-aou-right-5 {
  font-size: 0;
  height: 26px;
}

.aou-main-box-title {
  position: absolute;
  top: -20px;
  left: 40px;
  font-size: 16px;
  color: #665cff;
  line-height: 21px;
  transform: translateY(-22px);
}

.aou-main-close-icon {
  width: 25px;
  height: 25px;
  padding: 5px;
  position: absolute;
  z-index: 2;
  display: inline-block;
  top: 0;
  right: 0;
  cursor: pointer;
}

html:global(.desktop) {
  .aou-main-box {
    height: 456px;
    width: 1000px;
  }
  .aou-main-left {
    width: 495px;
  }
  .aou-main-right {
    width: 495px;
    padding-bottom: 24px;
  }

  .aou-main-right-box {
    padding: 14px 36px 0px 14px;
  }

  .aou-aou-right-5 {
    height: 30px;
  }
  .aou-main-close-icon {
    width: 40px;
    height: 40px;
    padding: 10px;
    position: absolute;
    i {
      font-size: 24px !important;
    }
  }
}
</style>
