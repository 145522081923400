const state = {
  isOpenTirdMy: false,
  isCloseAvatar: true,
  cmsData: {},
  subLandId: "",
  thirdUserInfo: {
    id: "",
    nickName: "游客" + Math.round(Math.random() * 10000),
    points: "899888",
    ycoin: "9000",
    gender: "",
    openId: "",
    profilePhoto: "",
    isVerified: 0,
    realName: "",
  },
};

const getters = {
  isOpenTirdMy: (state) => state.isOpenTirdMy, // 控制第三方个人中心组件的开关
  isCloseAvatar: (state) => state.isCloseAvatar, // 是否关闭三维渲染的avatar
  cmsData: (state) => state.cmsData, // cms传来的数据
  subLandId: (state) => state.subLandId, // 分岛的子岛场景
  thirdUserInfo: (state) => state.thirdUserInfo, // 第三方用户信息
};

const mutations = {
  /**
   * 获得第三方个人中心组件的开关状态
   */
  GET_Third_My_INFO: (state, isOpenTirdMy) => {
    state.isOpenTirdMy = isOpenTirdMy;
  },
  /**
   * 设置第三方个人中心组件的开关状态
   */
  UPDATE_Third_My_INFO: (state, isOpenTirdMy) => {
    state.isOpenTirdMy = JSON.parse(JSON.stringify(isOpenTirdMy));
  },
  /**
   * 设置第三方三维渲染的avatar开关状态
   */
  UPDATE_Third_CLOSE_AVATAR: (state, isCloseAvatar) => {
    state.isCloseAvatar = JSON.parse(JSON.stringify(isCloseAvatar));
  },
  /**
   * 设置cms传过来的数据
   */
  SET_CMS_DATA: (state, cmsData) => {
    state.cmsData = JSON.parse(JSON.stringify(cmsData));
  },
  /**
   * 设置分岛的子岛id
   */
  SET_SUB_LANDID: (state, subLandId) => {
    state.subLandId = JSON.parse(JSON.stringify(subLandId));
  },
  /**
   * 设置第三方用户信息
   */
  UPDATE_THIRD_USER_INFO: (state, data) => {
    state.thirdUserInfo = Object.assign(state.thirdUserInfo, data);
  },
};

const actions = {
  /**
   * 获得第三方个人中心组件的开关状态
   */
  get_third_my_info({ commit }, isOpenTirdMy) {
    commit("GET_Third_My_INFO", isOpenTirdMy);
  },
  /**
   * 设置第三方个人中心组件的开关状态
   */
  update_third_my_info({ commit }, isOpenTirdMy) {
    commit("UPDATE_Third_My_INFO", isOpenTirdMy);
  },
  /**
   * 设置第三方三维渲染的avatar开关状态
   */
  update_third_close_avatar({ commit }, isCloseAvatar) {
    commit("UPDATE_Third_CLOSE_AVATAR", isCloseAvatar);
  },
  /**
   * 设置cms传过来的数据
   */
  set_cms_data({ commit }, cmsData) {
    commit("SET_CMS_DATA", cmsData);
  },
  /**
   * 设置分岛的子岛id
   */
  set_sub_land_id({ commit }, subLandId) {
    commit("SET_SUB_LANDID", subLandId);
  },
  /**
   * 设置第三方用户信息
   */
  set_third_user_info({ commit }, data) {
    commit("UPDATE_THIRD_USER_INFO", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
