<template>
  <div id="app" style="width: 100%; height: 100%">
    <loading v-if="showLogin"></loading>
    <div :class="$style.desktopMask" v-if="$getters.desktop && $getters.portrait">
      <p>请将浏览器拉大再体验</p>
    </div>

    <cocos3d v-if="$getters.userId"></cocos3d>

    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive && ($route.meta.need3d ? $getters.cocosBaseAssets : true)"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive && ($route.meta.need3d ? $getters.cocosBaseAssets : true)"
    ></router-view>
    <avatar-wrapper v-if="$getters['change/avatar']"></avatar-wrapper>
    <tips-wrapper></tips-wrapper>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import loading from "@/components/tips/loading.vue";
import cocos3d from "@/components/cocos3d.vue";
import AvatarWrapper from "@/components/avatar/AvatarWrapper";
import TipsWrapper from "@/components/tips/";
export default {
  name: "APP",
  computed: {
    routerKey() {
      return this.$route.fullPath;
    },
    showLogin() {
      return this.$getters["change/loading"];
    },
  },
  data() {
    return {
      queryData: {},
    };
  },

  methods: {},

  components: {
    loading,
    cocos3d,
    AvatarWrapper,
    TipsWrapper,
  },
  created() {},
};
</script>

<style lang="scss" module>
@import "./assets/themes.scss";
.desktopMask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  font-size: 42px;
  color: white;
  background-color: #0d0c1d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
</style>
