import { fetch, post } from "../utils/http";

/**
 * 获取投票选项的信息
 * @param {*} id
 * @returns
 */
export const getTickets = (id) => {
  return fetch("/system/app/survey/list?pageNum=1&pageSize=100&id=" + id);
};

/**
 * 获取单个选项
 * @param {*} id
 * @returns
 */
export const getSurveygetOption = (id) => {
  return fetch("/system/app/survey/getOption?id=" + id);
};

/**
 * 投票结果
 * @param {*} id
 * @returns
 */
export const getSurveyResult = (id) => {
  return post("/system/app/survey/getSurveyResult", { id });
};

/**
 * 投票
 * @param {*} surveyId, optionIds
 * @returns
 */
export const ticketVoteItem = (surveyId, optionIds) => {
  return post("/system/app/survey/vote", { surveyId, optionIds });
};
