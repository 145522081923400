import Toast from "./toast";

export default (Vue) => {
  let instClass = Vue.extend(Toast);
  function showFun(text, type, duration = 2) {
    let instanceDom = new instClass({
      data() {
        return {
          show: true,
          text: text,
          type: type,
        };
      },
    }).$mount();
    document.body.appendChild(instanceDom.$el);
    setTimeout(() => {
      instanceDom.show = false;
    }, duration * 1000);
  }
  Vue.prototype.$yaojingToast = showFun;
};
