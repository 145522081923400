/* eslint-disable no-unused-vars */
/* jshint esversion: 6 */
/* eslint-disable prettier/prettier */
/* axios封装 */
import axios from "axios";
import Cookies from "js-cookie";
import router from "../router";
import store from "../store";
// eslint-disable-next-line no-unused-vars
import { yaojingToast } from "@/components/global";

axios.defaults.timeout = 1000 * 15;
axios.defaults.retry = 5;
axios.defaults.retryDelay = 1000;

// http request 请求拦截
axios.interceptors.request.use(
  (config) => {
    // config.baseURL = process.env.VUE_APP_API_URL;
    // const token = Cookies.get("yaojing_token");
    // token && (config.headers["Authorization"] = "Bearer " + token);
    //判断是否http或者https否则用反向代理路径
    // let isUrl = checkUrl(config.url);
    // if (isUrl) {
    //   config.baseURL = "";
    // }
    if (process.env.NODE_ENV === "development") {
      if (config.method === "post") console.log("axios-params: ", config.data);
      else console.log("axios-params: ", config.params);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 响应拦截 异常处理
axios.interceptors.response.use(
  (response) => {
    if (response.data.code != "200" && response.data.code != "401" && response.data.msg) {
      yaojingToast(response.data.msg, "fail");
    }
    if (response.data.code == "401") {
      router.replace({
        name: "login",
        query: {
          redirect: window.location.pathname + window.location.search,
        },
      });
    }
    if (process.env.NODE_ENV === "development") console.log(" response.data: ", response);

    return response.data;
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          yaojingToast("错误请求", "fail");
          break;
        case 401:
          router.replace({
            name: "login",
            query: {
              redirect: window.location.pathname + window.location.search,
            },
          });
          break;
        case 403:
          yaojingToast("拒绝访问", "fail");
          break;
        case 404:
          yaojingToast("请求错误,未找到该资源", "fail");
          break;
        case 405:
          yaojingToast("请求方法未允许", "fail");
          break;
        case 408:
          yaojingToast("请求超时", "fail");
          break;
        case 500:
          yaojingToast("服务器端出错", "fail");
          break;
        case 501:
          yaojingToast("网络未实现", "fail");
          break;
        case 502:
          yaojingToast("网络错误", "fail");
          break;
        case 503:
          yaojingToast("服务不可用", "fail");
          break;
        case 504:
          yaojingToast("网络超时", "fail");
          break;
        case 505:
          yaojingToast("http版本不支持该请求", "fail");
          break;
        default:
          yaojingToast("连接错误", "fail");
      }
    } else {
      yaojingToast("连接到服务器失败", "fail");
    }
    throw error.response;
  },
);

/**
 * 判断是否为网址
 * @param {*} URL
 * @returns true
 */
function checkUrl(url) {
  var str = url;
  //判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
  //下面的代码中应用了转义字符"\"输出一个字符"/"
  // eslint-disable-next-line no-useless-escape
  var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
  var objExp = new RegExp(Expression);
  if (objExp.test(str) == true) {
    return true;
  } else {
    return false;
  }
}

/**
 * get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.VUE_APP_API_URL + url, {
        headers: {
          Authorization: "Bearer " + Cookies.get("yaojing_token"),
        },
        params: params,
      })
      .then((response) => {
        if (response.code == 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.VUE_APP_API_URL + url, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("yaojing_token"),
        },
      })
      .then(
        (response) => {
          if (response.code == 200) {
            resolve(response.data);
          } else {
            reject(response);
          }
        },
        (err) => {
          reject(err);
        },
      );
  });
}

/**
 * patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .patch(process.env.VUE_APP_API_URL + url, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("yaojing_token"),
        },
      })
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        },
      );
  });
}

/**
 * put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .put(process.env.VUE_APP_API_URL + url, data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("yaojing_token"),
        },
      })
      .then(
        (response) => {
          resolve(response.data);
        },
        (err) => {
          reject(err);
        },
      );
  });
}

/**
 * 曜境 微服务
 * @param url
 * @param data
 * @returns {Promise}
 */
export function yaolandMiniServerPost(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post("/yaolandMiniServerApi" + url, data, {
        headers: {
          playerid: store.getters.userId,
        },
      })
      .then(
        (response) => {
          if (response.code == 200) {
            resolve(response.data);
          } else {
            reject(response);
          }
        },
        (err) => {
          reject(err);
        },
      );
  });
}

/**
 * pickApi get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function pickCodeFetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    // axios;
    // .get("http://127.0.0.1:7001" + url, { params: params })
    axios
      .get("/pickApi" + url, { params: params })
      .then((response) => {
        if (response.code == 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * pickApi post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function pickCodePost(url, data = {}) {
  return new Promise((resolve, reject) => {
    // axios.post("http://127.0.0.1:7001" + url, data).then(
    axios.post("/pickApi" + url, data).then(
      (response) => {
        if (response.code == 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (err) => {
        reject(err);
      },
    );
  });
}

/**
 * nodeApi post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function nodePost(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post("https://api.yaoland.net" + url, data).then(
      // axios.post("/nodeApi" + url, data).then(
      (response) => {
        if (response.code == 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (err) => {
        reject(err);
      },
    );
  });
}

/**
 * easyarvrApi post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function easyarvrApiPost(url, data = {}) {
  return new Promise((resolve, reject) => {
    // axios.post("http://127.0.0.1:7001" + url, data).then(
    axios.post("/easyarvrApi" + url, data).then(
      (response) => {
        if (response.code == 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (err) => {
        reject(err);
      },
    );
  });
}
