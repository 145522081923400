/* eslint-disable no-unused-vars */
/* jshint esversion: 11 */
import router from "@/router";
import { queryURLParams, getQueryStringByName } from "@/utils/lib";

/**
 * 路由历史记录
 */
const state = {
  history: [],
};

const getters = {
  history(state) {
    return state.history;
  },
};

const mutations = {
  history: (state, data) => {
    state.history = data;
  },
};

const actions = {
  /**
   * 历史记录
   * @param {*} param0
   * @param {*} data
   * @returns
   */
  addHistory({ commit }, data) {
    console.log("🚀 -> addHistory -> data", data);
    const whiteList = ["landShop", "landShopDetail", "payment", "shop", "mainShopDetail"]; // no redirect whitelist

    // if (state.history.length < 9999) {
    if (whiteList.indexOf(data.name) > -1) {
      state.history.push(data);
    } else {
      state.history = [];
    }
    // } else {
    //   state.history.shift();
    //   state.history.push(data);
    // }
  },

  /**
   *
   * @param {*} param0
   * @param {*} data
   */
  back({ commit }, data) {
    const name = state.history[state.history.length - 2].name;
    // console.log("🚀 -> back -> name", name);
    const fullPath = state.history[state.history.length - 2].fullPath;
    // console.log("🚀 -> back -> fullPath", fullPath);
    if (name != "payment") {
      router.replace(fullPath);
    } else {
      for (let index = state.history.length - 2; index >= 0; index--) {
        const element = state.history[index];
        // console.log("🚀 -> back -> element", element);
        const urlArr = location.href.split("/");
        const url = "/" + urlArr[urlArr.length - 1];
        if (element.name != "payment" && element.fullPath != url) {
          router.replace(element.fullPath);
          break;
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
