<!-- 微信提示  -->
<template>
  <div class="wx-share-tips" @click="$store.dispatch('change/hidden', 'wxShareTips')">
    <img
      src="@/assets/share/share_tips@3x.png"
      class="wx-tips"
      :style="isWxIPhone && $getters.landscape ? { right: '65px' } : { right: '25px' }"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { delay, getIsWxClient, isIPhone } from "@/utils/lib";

export default {
  name: "wxShareTips",
  components: {},
  props: {},
  data() {
    return { isWxIPhone: isIPhone() && getIsWxClient() };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  deactivated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.wx-share-tips {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1999;
  background-color: rgba($color: #0d0c1d, $alpha: 0.75);
  // background-color: rgba($color: #ffffff, $alpha: 0.75);
  // pointer-events: none;
  // & > div {
  //   pointer-events: auto;
  // }
}

.wx-tips {
  position: absolute;
  top: 0;
  right: 25px;
  width: 50%;
}

html.mobile.landscape {
  .wx-tips {
    position: absolute;
    top: 0;
    right: 65px;
    width: 25%;
  }
}
</style>
