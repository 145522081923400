<!-- 购买提示 -->
<template>
  <div :class="$style.prizeImageWrapper">
    <span :class="$style['prizeImageWrapperClose']" @click="handleClose">
      <img style="width: 100%" src="@/assets/land/menu/close.svg" alt="" />
    </span>

    <div :class="$style.prizeImageWrapperImg">
      <div :class="$style.prizeImageWrapperImgBox" ref="prizeImageWrapper" id="prizeImageWrapper">
        <div :class="$style['html-canvas-wrapper']" id="htmlCanvasWrapper"></div>
        <div :class="$style['html-canvas-loading']" v-if="!isMake">
          <van-loading type="spinner" color="#665cff" vertical>证书生成中</van-loading>
        </div>
        <div :class="$style['prize-img-origration']" id="prizeImgOrigration" v-if="!isMake">
          <p data-img-sign-time :style="$getters['change/afterPrizeImage']['date_position']">
            日期：{{ dateTime }}
          </p>
          <p data-img-sign :style="$getters['change/afterPrizeImage']['name_position']">
            {{ $getters["secne/realName"] }}
          </p>
          <img
            style="width: 100%; height: 100%; object-fit: cover"
            :src="require('@/assets/tips/afterAction/' + $getters['change/afterPrizeImage']['img'])"
            alt=""
          />
        </div>
        <p style="width: 100%; font-size: 0; position: relative; z-index: 1">
          <img style="width: 100%" src="@/assets/tips/afterAction/prizeEmpty.png" alt="" />
        </p>
      </div>

      <div :class="$style.prizeImageBtnWrapper" v-if="isMake">
        <div :class="$style.prizeImageSaveLocalBtn">
          <img data-photo-btnicon src="@/assets/land/menu/saveLocal.svg" alt="" />
          <span>{{ $getters.desktop ? "右键保存" : "长按图片保存" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { delay } from "@/utils/lib";
import html2canvas from "html2canvas";
import { uploadOss, insertPlayerPhoto } from "@/api/photo";
export default {
  name: "prize-image",
  data() {
    return {
      isMake: 0,
      dateTime: dayjs().format("YYYY-MM-DD"),
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.$store.dispatch("change/hidden", "afterPrizeImage");
    },

    setCanvas() {
      const that = this;
      html2canvas(document.querySelector("#prizeImgOrigration")).then((canvas) => {
        const o = new Image();
        o.src = canvas.toDataURL();
        var formData = new FormData();
        canvas.toBlob(function (blobObj) {
          formData.append("file", blobObj);
          uploadOss(formData).then((res) => {
            insertPlayerPhoto(res.url).then(() => {
              that.$yaojingToast("已经保存到云相册", "success");
            });
          });
        });

        document.getElementById("htmlCanvasWrapper").appendChild(o);
        this.isMake = 1;
      });
    },
  },
  mounted() {
    delay(1).then(() => {
      this.setCanvas();
    });
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";

.prizeImageWrapper {
  position: fixed;
  z-index: 1;
  background-color: transparentize(#0d0c1d, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 160px;
}

.prizeImageWrapperImg {
  width: 400px;
  position: relative;
}

.prizeImageWrapperImgBox {
  font-size: 0;
  user-select: none;
  overflow: hidden;
  position: relative;
  [data-canvas] {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.prizeImageBtnWrapper {
  position: absolute;
  bottom: 0;
  right: -20px;
  transform: translateX(100%);
}

.prizeImageWrapperClose {
  width: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.prizeImageSaveCloudBtn {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 20px;
  [data-photo-btnicon] {
    width: 30px;
    margin-right: 10px;
  }
}
.prizeImageSaveLocalBtn {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  [data-photo-btnicon] {
    width: 30px;
    margin-right: 10px;
  }
}
.html-canvas-wrapper {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.html-canvas-loading {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.prize-img-origration {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 843px;
  height: 597px;
  [data-img-sign] {
    position: absolute;
    z-index: 3;
    font-size: 30px;
    white-space: nowrap;
  }
  [data-img-sign-time] {
    position: absolute;
    z-index: 3;
    font-size: 14px;
    white-space: nowrap;
  }
}

html:global(.desktop) .prizeImageWrapperImg {
  width: 600px;
}
html:global(.mobile):global(.portrait) {
  .prizeImageWrapper {
    padding: 40px 24px !important;
  }

  .prizeImageWrapperImg {
    width: 100% !important;
  }

  .prizeImageBtnWrapper {
    left: 0 !important;
    bottom: -8px !important;
    transform: translate(0, 100%) !important;
  }
}
</style>
