/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 11 */
import Vue from "vue";
import VueRouter from "vue-router";
// import { wechatAuth } from "@/utils/wechatConfig.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "home_main" */ "../views/home/index.vue"),
    children: [
      {
        path: "",
        name: "home",
        info: "首页",
        component: () => import(/* webpackChunkName: "home" */ "../views/home/home/index.vue"),
        meta: {
          keepAlive: false,
          allowShare: true,
          loginRequest: true,
          need3d: true,
          render3d: true,
        },
      },
      {
        path: "Space",
        name: "space",
        component: () =>
          import(/* webpackChunkName: "home_space" */ "../views/home/space/index.vue"),
        meta: {
          keepAlive: false,
          allowShare: false,
          loginRequest: true,
          need3d: true,
          render3d: true,
        },
      },
      {
        path: "Trend",
        name: "trend",
        component: () =>
          import(/* webpackChunkName: "home_trend" */ "../views/home/trend/index.vue"),
        meta: {
          keepAlive: false,
          allowShare: false,
          loginRequest: true,
          need3d: true,
          render3d: false,
        },
      },
      {
        path: "Shop",
        name: "shop",
        component: () => import(/* webpackChunkName: "home_shop" */ "../views/home/shop/index.vue"),
        meta: {
          keepAlive: true,
          allowShare: true,
          loginRequest: false,
          need3d: true,
          render3d: false,
        },
      },
      {
        path: "ShopDetail",
        name: "mainShopDetail",
        component: () =>
          import(/* webpackChunkName: "main_shop_detail" */ "../views/home/shop/detail.vue"),
        meta: {
          keepAlive: false,
          allowShare: true,
          loginRequest: false,
          need3d: true,
          render3d: false,
        },
      },
      {
        path: "My",
        name: "my",
        component: () => import(/* webpackChunkName: "home_my" */ "../views/home/my/index.vue"),
        meta: {
          keepAlive: true,
          allowShare: false,
          loginRequest: true,
          need3d: true,
          render3d: false,
        },
      },
    ],
  },
  {
    path: "/ThirdMySpace",
    name: "thirdMySpace",
    component: () => import(/* webpackChunkName: "home_my" */ "../views/mySpace/thirdMySpace.vue"),
    meta: {
      keepAlive: true,
      allowShare: false,
      loginRequest: true,
      need3d: true,
      render3d: false,
    },
  },
  {
    path: "/Land",
    component: () => import(/* webpackChunkName: "land" */ "../views/land/index.vue"),
    meta: {
      keepAlive: false,
    },
    children: [
      {
        path: "",
        name: "landHome",
        component: () => import(/* webpackChunkName: "land_home" */ "../views/land/home/index.vue"),
        meta: {
          keepAlive: false,
          allowShare: true,
          loginRequest: true,
          need3d: true,
          render3d: true,
        },
      },
      {
        path: "Shop",
        name: "landShop",
        component: () => import(/* webpackChunkName: "land_shop" */ "../views/land/shop/index.vue"),
        meta: {
          keepAlive: true,
          allowShare: true,
          loginRequest: false,
          need3d: true,
          render3d: false,
        },
      },
      {
        path: "ShopDetail",
        name: "landShopDetail",
        component: () =>
          import(/* webpackChunkName: "land_shop_detail" */ "../views/land/shop/detail.vue"),
        meta: {
          keepAlive: false,
          allowShare: true,
          loginRequest: false,
          need3d: true,
          render3d: false,
        },
      },
      {
        path: "ThirdDetail",
        name: "thirdDetail",
        component: () =>
          import(/* webpackChunkName: "land_shop_detail" */ "../views/land/shop/thirdDetail.vue"),
        meta: {
          keepAlive: false,
          allowShare: true,
          loginRequest: false,
          need3d: true,
          render3d: false,
        },
      },
    ],
  },
  {
    path: "/Login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/index.vue"),
    meta: {
      keepAlive: true,
      loginRequest: false,
      need3d: false,
      render3d: false,
    },
  },
  {
    path: "/autoLogin",
    name: "autologin",
    component: () => import(/* webpackChunkName: "autologin" */ "../views/login/autoLogin.vue"),
    meta: {
      keepAlive: true,
      loginRequest: false,
      need3d: false,
      render3d: false,
    },
  },
  {
    path: "/MySpace",
    name: "mySpace",
    component: () => import(/* webpackChunkName: "mySpace" */ "../views/mySpace/index.vue"),
    meta: {
      keepAlive: true,
      allowShare: true,
      loginRequest: false,
      need3d: true,
      render3d: true,
    },
  },
  {
    path: "/Payment",
    name: "payment",
    component: () => import(/* webpackChunkName: "payment" */ "../views/payment/index.vue"),
    meta: {
      keepAlive: false,
      loginRequest: false,
      need3d: false,
      render3d: false,
    },
  },
  {
    path: "/PaySuccess",
    name: "paySuccess",
    component: () => import(/* webpackChunkName: "paySuccess" */ "../views/paySuccess/index.vue"),
    meta: {
      keepAlive: false,
      loginRequest: false,
      need3d: false,
      render3d: false,
    },
  },
  {
    path: "/clearCookie",
    name: "clearCookie",
    component: () => import(/* webpackChunkName: "clearCookie" */ "../views/clearCookie/index.vue"),
    meta: {
      keepAlive: true,
      loginRequest: false,
      need3d: false,
      render3d: false,
    },
  },
  {
    path: "/ngCollections",
    name: "ngCollections",
    component: () =>
      import(/* webpackChunkName: "ngCollections" */ "../views/expand/ngCollections.vue"),
    meta: {
      keepAlive: true,
      loginRequest: false,
      need3d: false,
      render3d: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

// router.afterEach((to, from) => {
//   let authUrl = `${window.location.origin}${to.fullPath}`;
//   let allowShare = !!to.meta.allowShare;

//   // eslint-disable-next-line no-undef
//   if (device.ios()) {
//     // IOS
//     if (window.entryUrl == "" || window.entryUrl == undefined) {
//       window.entryUrl = authUrl; // 将后面的参数去除
//     }
//     wechatAuth(authUrl, "ios", allowShare);
//   } else {
//     // 安卓
//     setTimeout(function () {
//       wechatAuth(authUrl, "android", allowShare);
//     }, 500);
//   }
// });

export default router;
