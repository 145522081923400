import Confirm from "./confirm";
export default (Vue) => {
  let instClass = Vue.extend(Confirm);
  function showFun(params) {
    return new Promise((resolve, reject) => {
      let instanceDom = new instClass({
        data() {
          return {
            show: true,
            title: params.title || "提示",
            text: params.text || "",
            showCancelButton: params.showCancelButton || "show",
            showConfirmButton: params.showConfirmButton || "show",
            cancelButtonText: params.cancelButtonText || "取消",
            confirmButtonText: params.confirmButtonText || "确定",
          };
        },
        methods: {
          handleCancel() {
            this.show = false;
            reject("cancel");
          },
          handleConfirm() {
            this.show = false;
            resolve("confirm");
          },
        },
      }).$mount();
      document.body.appendChild(instanceDom.$el);
    });
  }
  Vue.prototype.$yaojingConfirm = showFun;
};
