<template>
  <reward-item
    @maskClose="handleCloseTip"
    @close="handleCloseTip"
    @tapBtn="handleCloseTip"
    :title="$getters['change/rewardY']['title']"
    :description="$getters['change/rewardY']['description']"
    :img="require('@/assets/quest/rewardY.svg')"
  ></reward-item>
</template>

<script>
// import { delay } from "@/utils/lib";
import { getPlayerInfo } from "@/api/login";
import RewardItem from "./RewardItem";

export default {
  name: "reward-y",
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/rewardY"]) {
        this.$store.dispatch("change/hidden", "rewardY");
      }
    },
  },
  created() {
    getPlayerInfo();
    this.$makeQuestEvent(
      { type: "collectYC" },
      { numStep: this.$getters["change/rewardY"]["num"] },
    );
    // delay(2).then(() => {
    //   this.handleCloseTip();
    // });
  },
  components: {
    RewardItem,
  },
};
</script>
