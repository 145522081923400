/* jshint esversion: 11 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import secne from "./modules/secne";
import device from "./modules/device";
import change from "./modules/change";
import homeQuest from "./modules/homeQuest";
import landQuest from "./modules/landQuest";
import thirdMy from "./modules/thirdMy";
// 空间
import space from "@/store/modules/space/space";
// 商城
import shop from "@/store/modules/shop/shop";
import message from "@/store/modules/msg/message";
import routerHistory from "./modules/routerHistory";
import landBaseInfo from "@/store/modules/landBaseInfo";
import quest from "@/store/modules/quest";
// 提示
import tips from "@/store/modules/tips";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    secne,
    device,
    change,
    homeQuest,
    landQuest,
    space,
    shop,
    tips,
    message,
    landBaseInfo,
    routerHistory,
    quest,
    thirdMy,
  },
  getters,
});

export default store;
