<template>
  <div
    :class="$style['content-main-layout']"
    data-content-main-layout
    :style="{ 'z-index': isFull ? '201' : '6' }"
  >
    <div
      :class="$style['content-main-mask']"
      v-if="isShowMask"
      data-content-main-mask
      @click="$emit('close')"
    ></div>
    <div
      :class="$style['content-wrapper']"
      data-content-wrapper
      :style="{
        width: contentLandscapeWidth,
        'padding-top': showTopTitle ? '52px' : '0',
        'padding-right': showRightTitle ? '54px' : '0',
        'padding-bottom': isFull || $getters.landscape ? '0' : '92px',
      }"
    >
      <div
        :class="$style['content-layout-title']"
        v-if="showTopTitle"
        :style="{
          'padding-right': showRightTitle ? '54px' : '0',
        }"
      >
        <div style="position: relative; width: 100%; height: 100%">
          <slot v-if="$slots.topTitle" name="topTitle"></slot>
          <template v-else>
            <span
              data-content-layout-title-back
              @click="$emit('back')"
              v-if="isFull && isShowTopBack"
            >
              <van-icon :name="leftIconName" color="white" />
            </span>
            <p data-content-layout-title-text :style="{ color: titleColor }">{{ topTitleText }}</p>
            <van-button
              v-if="topTitleBtn"
              size="small"
              data-content-layout-title-btn
              :color="$global.color_primary_2"
              @click="$emit('confirm')"
              >{{ topTitleBtn }}</van-button
            >
          </template>
        </div>
      </div>

      <div :class="$style['content-layout-landscape-title']" v-if="showRightTitle">
        <slot v-if="$slots.rightTitle" name="rightTitle"></slot>
        <template v-else>
          <span data-content-layout-landscape-title-back @click="$emit('close')">
            <van-icon name="cross" color="white" />
          </span>
          <p data-content-layout-landscape-title-text :style="{ color: titleColor }">
            {{ rightTitleText || topTitleText }}
          </p>
        </template>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content-Layout",
  data() {
    return {};
  },
  props: {
    /**
     * 横屏时的 内容宽度
     */
    contentLandscapeWidth: {
      default: "",
      type: String,
    },
    /**
     * 是否显示顶部标题内容
     * default: 根据横竖屏默认
     * show：一直显示
     * hidden:一直隐藏
     */
    isShowTopTitle: {
      default: "default",
      type: String,
    },
    /**
     * 是否显示右侧标题内容
     * default: 根据横竖屏默认
     * show：一直显示
     * hidden:一直隐藏
     */
    isShowRightTitle: {
      default: "default",
      type: String,
    },

    /**
     * 顶部内容标题
     */
    topTitleText: {
      default: "",
      type: String,
    },

    /**
     * 标题颜色
     */
    titleColor: {
      default: "#ffffff",
      type: String,
    },

    /**
     * 顶部按钮
     */
    topTitleBtn: {
      default: "",
      type: String,
    },

    /**
     * 右侧内容标题
     */
    rightTitleText: {
      default: "",
      type: String,
    },

    /**
     * 是否全屏
     */
    isFull: {
      default: false,
      type: Boolean,
    },
    /**
     * 是否显示标题的左边返回按钮
     */
    isShowTopBack: {
      default: true,
      type: Boolean,
    },
    /**
     * 是否显示遮罩
     */
    isShowMask: {
      default: true,
      type: Boolean,
    },
    // 左边按钮icon name
    leftIconName: {
      type: String,
      default: "arrow-left",
    },
  },
  computed: {
    showTopTitle: function () {
      return this.isShowTopTitle == "default"
        ? this.$getters.portrait
        : this.isShowTopTitle == "show";
    },
    showRightTitle: function () {
      return this.isShowRightTitle == "default"
        ? this.$getters.landscape
        : this.isShowRightTitle == "show";
    },
  },
  methods: {
    refreshScroll() {
      this.$imagesLoaded(this.$refs.contentscrollwrapper, () => {
        this.$refs.contentscrollwrapper.refresh();
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "../assets/themes.scss";
.content-main-layout {
  position: absolute;
  z-index: 6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: $--color_text_1;
}

.content-main-mask {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparentize($--color_bg_line_1, 0.8);
}

.content-wrapper {
  position: absolute;
  z-index: 2;
  height: 100%;
  top: 0;
  right: 0;
  background-color: $--color_bg_line_1;
  pointer-events: auto;
  width: 100%;
}

.content-layout-title {
  height: 52px;
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background-color: $--color_primary_1;
  [data-content-layout-title-back] {
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 52px;
    top: 0;
    left: 6px;
    z-index: 4;
    text-align: center;
    cursor: pointer;
    font-size: $--font_size_44;
  }
  [data-content-layout-title-text] {
    position: absolute;
    line-height: 52px;
    top: 0;
    left: 0px;
    z-index: 2;
    text-align: center;
    width: 100%;
    font-size: $--font_size_4;
  }
  [data-content-layout-title-btn] {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    margin: auto 0;
    height: 28px;
    width: 74px;
    border-radius: 0;
    z-index: 4;
  }
}

.content-layout-landscape-title {
  height: 100%;
  width: 54px;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  background-color: $--color_primary_1;
  [data-content-layout-landscape-title-back] {
    position: absolute;
    width: 54px;
    height: 52px;
    line-height: 52px;
    top: 0;
    right: 0;
    z-index: 4;
    text-align: center;
    cursor: pointer;
    font-size: $--font_size_44;
  }
  [data-content-layout-landscape-title-text] {
    position: absolute;
    height: 100%;
    width: 54px;
    line-height: 54px;
    top: 0;
    left: auto;
    right: 0;
    text-align: center;
    writing-mode: tb-rl;
    letter-spacing: 0.4em;
    font-size: $--font_size_28;
  }
}

html:global(.mobile):global(.landscape) {
  .content-wrapper {
    // width: 404px; // 手机横屏宽度是54 + 350
    width: 54px + 362px; // 手机横屏宽度是54 + 350
  }
}
html:not(:global(.mobile)):global(.landscape) {
  .content-wrapper {
    width: 429px;
  }
}

html:global(.mobile):global(.wechatbrowser):global(.iphonefringe):global(.landscape)[data-direction="-90"]
  .content-wrapper {
  right: 20px;
}
html:global(.mobile):global(.wechatbrowser):global(.iphonefringe):global(.landscape)[data-direction="-90"]
  .content-layout-landscape-title {
  right: -20px;
  width: 74px;
}
html:global(.mobile):global(.wechatbrowser):global(.iphonefringe):global(.landscape)[data-direction="-90"]
  [data-content-layout-landscape-title-back] {
  right: 20px;
}
html:global(.mobile):global(.wechatbrowser):global(.iphonefringe):global(.landscape)[data-direction="-90"]
  [data-content-layout-landscape-title-text] {
  right: 20px;
}
</style>
