<!-- 任务 礼物 -->
<template>
  <reward-item
    @close="handleCloseTip"
    @tapBtn="handleCloseTip"
    :title="$getters['change/rewardEquipsBuy']['title']"
    :description="$getters['change/rewardEquipsBuy']['description']"
    :img="$getters['change/rewardEquipsBuy']['img']"
  >
    <template v-slot:buttonwrapper>
      <van-button
        @click="handleBuyEquip"
        :class="$style['reward-equips-bug-wrapper']"
        size="small"
        color="#665CFF"
      >
        <div :class="$style['reward-equips-bug']">
          <div data-equis-box>
            <p style="width: 13px; font-size: 0">
              <img style="width: 100%" src="@/assets/icon/money2.svg" alt="" />
            </p>
            <span style="color: #ff9624; font-size: 14px; margin-left: 4px">{{
              $getters["change/rewardEquipsBuy"]["unitPrice"]
            }}</span>
          </div>
          <p style="font-size: 14px">获得</p>
        </div>
      </van-button>
    </template>
  </reward-item>
</template>

<script>
// import { delay } from "@/utils/lib";
import RewardItem from "../reward/RewardItem";
import shopApi from "@/api/shop";
import { equipBought } from "@/utils/cocosBridge";
export default {
  name: "reward-equips",
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/rewardEquipsBuy"]) {
        this.$store.dispatch("change/hidden", "rewardEquipsBuy");
      }
    },

    /**
     * 购买装备
     */
    handleBuyEquip() {
      const orderDetails = this.$getters["change/rewardEquipsBuy"]["orderDetails"];
      if (orderDetails && typeof orderDetails == "object") {
        shopApi
          .createOrder(
            0,
            this.$getters.landId,
            this.$getters["change/rewardEquipsBuy"]["orderDetails"],
          )
          .then((orderNo) => {
            shopApi.payOrderY(orderNo).then(() => {
              this.$yaojingToast("购买成功", "success");
              equipBought(orderDetails);
              orderDetails.forEach((element) => {
                this.$makeQuestEvent({
                  type: "gotItem",
                  itemId: element["equipId"],
                });
              });
              this.handleCloseTip();
            });
          });
      }
    },
  },
  created() {
    // delay(2).then(() => {
    //   this.handleCloseTip();
    // });
  },
  components: {
    RewardItem,
  },
};
</script>

<style lang="scss" module>
@import "@/assets/themes.scss";
.reward-equips-bug-wrapper {
  margin-top: 6px;
  width: 120px;
}
.reward-equips-bug {
  width: 100%;
  display: flex;
  justify-content: space-around;
  width: 120px;
  [data-equis-box] {
    display: flex;
    align-items: center;
  }
}
</style>
