<template>
  <reward-item
    @maskClose="handleCloseTip"
    @close="handleCloseTip"
    @tapBtn="handleCloseTip"
    :title="$getters['change/rewardSpace']['title']"
    :description="$getters['change/rewardSpace']['description']"
    :img="$getters['change/rewardSpace']['img']"
  ></reward-item>
</template>

<script>
// import { delay } from "@/utils/lib";
import RewardItem from "./RewardItem";
export default {
  name: "reward-space",
  data() {
    return {};
  },
  methods: {
    handleCloseTip() {
      if (this.$getters["change/rewardSpace"]) {
        this.$store.dispatch("change/hidden", "rewardSpace");
      }
    },
  },
  created() {
    // delay(2).then(() => {
    //   this.handleCloseTip();
    // });
  },
  components: {
    RewardItem,
  },
};
</script>
