/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 6 */

/**
 * 全局窗口状态管理
 */
import { clearCurRewardAction } from "@/utils/taskfactory.js";
import _lang from "lodash/lang";
const state = {
  // 公共参数
  params: {},
  // 加载中...
  loading: false,
  // 加载中...
  landInfo: false,
  // 登录注册
  login: false,
  // Avatar
  avatar: false,
  // 空间搜索
  spaceSearch: false,
  // 空间类型
  spaceType: false,
  // 空间创建
  spaceCreate: false,

  // 3D 场景内打开 NFT
  albumProduct: false,
  // 任务经验
  experienceTips: false,
  // 任务获取的碎片信息
  getFragment: false,
  // 没有收集到任务碎片
  getNullFragment: false,
  // 需要购买的奖励

  rewardCouponBuy: false,
  // 人格结束的弹窗
  personality: false,
  // 任务金币
  moneyTips: false,
  // 实名制 - 填写信息
  realName: false,
  // 商城目录
  shopCatalog: false,
  // 分岛列表
  shopIsland: false,
  // 抽签
  lottery: false,
  // 3d自定义空间 数字藏品 云相册
  chooseSpaceImg: false,

  // 支付
  payment: false,
  // 修改昵称
  mySettingNickName: false,

  // 任务奖励提示 - Y币提示
  rewardY: false,
  // 任务奖励提示 - 经验提示
  rewardExperience: false,
  // 任务奖励提示 - 装备提示
  rewardEquips: false,
  // 获取装备 - 支持2个按钮 【去试穿 开心收下】
  rewardEquipsBuy: false,
  // 任务奖励提示 - 空间解锁提示
  rewardSpace: false,
  // 任务奖励提示 - 功能解锁提示
  rewardFeature: false,
  // 任务奖励提示 - 优惠券奖励提示
  rewardCoupon: false,

  rewardNFT: false,

  // 任务后动作 - 播放视频
  afterActionPlayVideo: false,
  // 任务后动作 - 打开Iframe
  afterActionOpenIframe: false,

  // Land UI 显示状态
  landUiShowStatus: true,

  // 后置动作 - 颁发奖项
  afterPrizeImage: false,

  // 拍照
  takePhoto: false,

  // 虚拟电音节 排行榜
  hydeoutRank: false,

  // hydeout打开 打Call
  hydeoutDacall: false,
  // 微信分享提示
  wxShareTips: false,
  photoShareTips: false,
  buyShareTips: false,
  downloadShareTips: false,

  // NFT iframe view 显示隐藏
  nftViewLoading: false,

  //263直播
  liveStream263: false,

  //图片提示
  imgTipsView: false,
  showPhoto: false,
  nftCoupon: false,

  lotteryGird: false,

  chooseSpaceCover: false,
  openLandImg: false,

  openIframe: false, //打开iframe
  iframeUrl: "", //iframe地址
};

const getters = {
  params: (state) => state.params,
  loading: (state) => state.loading,
  landInfo: (state) => state.landInfo,
  login: (state) => state.login,
  avatar: (state) => state.avatar,
  spaceSearch: (state) => state.spaceSearch,
  spaceType: (state) => state.spaceType,
  spaceCreate: (state) => state.spaceCreate,

  experienceTips: (state) => state.experienceTips,
  getFragment: (state) => state.getFragment,
  getNullFragment: (state) => state.getNullFragment,
  rewardCouponBuy: (state) => state.rewardCouponBuy,
  personality: (state) => state.personality,
  moneyTips: (state) => state.moneyTips,
  realName: (state) => state.realName,
  shopCatalog: (state) => state.shopCatalog,
  shopIsland: (state) => state.shopIsland,
  lottery: (state) => state.lottery,
  chooseSpaceImg: (state) => state.chooseSpaceImg,
  albumProduct: (state) => state.albumProduct,
  payment: (state) => state.payment,
  mySettingNickName: (state) => state.mySettingNickName,

  rewardY: (state) => state.rewardY,
  rewardExperience: (state) => state.rewardExperience,
  rewardEquips: (state) => state.rewardEquips,
  rewardEquipsBuy: (state) => state.rewardEquipsBuy,
  rewardSpace: (state) => state.rewardSpace,
  rewardFeature: (state) => state.rewardFeature,
  rewardCoupon: (state) => state.rewardCoupon,
  rewardNFT: (state) => state.rewardNFT,

  afterActionPlayVideo: (state) => state.afterActionPlayVideo,
  afterActionOpenIframe: (state) => state.afterActionOpenIframe,

  landUiShowStatus: (state) => state.landUiShowStatus,
  takePhoto: (state) => state.takePhoto,
  hydeoutRank: (state) => state.hydeoutRank,

  afterPrizeImage: (state) => state.afterPrizeImage,
  hydeoutDacall: (state) => state.hydeoutDacall,

  wxShareTips: (state) => state.wxShareTips,
  photoShareTips: (state) => state.photoShareTips,
  buyShareTips: (state) => state.buyShareTips,
  downloadShareTips: (state) => state.downloadShareTips,

  nftViewLoading: (state) => state.nftViewLoading,
  liveStream263: (state) => state.liveStream263,
  imgTipsView: (state) => state.imgTipsView,
  showPhoto: (state) => state.showPhoto,
  nftCoupon: (state) => state.nftCoupon,

  chooseSpaceCover: (state) => state.chooseSpaceCover,
  lotteryGird: (state) => state.lotteryGird,
  openLandImg: (state) => state.openLandImg,
  openIframe: (state) => state.openIframe,
  iframeUrl: (state) => state.iframeUrl,
};

const mutations = {
  DELETE_PARAMS: (state, name) => {
    if (name) {
      state.params["name"] = "";
    } else {
      state.params = {};
    }
  },
  TOGGLE: (state, name) => {
    state[name] = !state[name];
  },
  SHOW: (state, data) => {
    if (typeof data == "object") {
      state[data.name] = data.params;
      window.document.documentElement.setAttribute("data-change-state", data.name);
    } else {
      state[data] = true;
      window.document.documentElement.setAttribute("data-change-state", data);
    }
  },
  HIDDEN: (state, name) => {
    if (_lang.isObject(state[name])) {
      state[name]["actionType"] && clearCurRewardAction();
    }

    state[name] = false;
    window.document.documentElement.getAttribute("data-change-state") &&
      window.document.documentElement.removeAttribute("data-change-state");
    delete state.params[name];
  },
  SETIFRAME: (state, data) => {
    console.log("%c ----->-194", "font-size:13px; background:pink; color:#bf2c9f;", state, data);
    state['iframeUrl'] = data;
  },
};

const actions = {
  /**
   * 删除参数
   */
  delete_params({ commit }, name) {
    commit("DELETE_PARAMS", name);
  },
  /**
   * 开关
   */
  toggle({ commit }, name) {
    commit("TOGGLE", name);
  },

  /**
   * 开启
   */
  show({ commit }, data) {
    commit("SHOW", data);
  },

  /**
   * 关闭
   */
  hidden({ commit }, name) {
    commit("HIDDEN", name);
  },
  setiframe({ commit }, data) {
    commit("SETIFRAME", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
