<!-- 空间封面缩略图 -->
<template>
  <div class="space-cover-item-page">
    <div class="cloud-album-item-img" @click="onClick()">
      <img src="@/assets/space/110-110.png" style="width: 100%; height: 100%" alt="" />
      <van-image
        fit="cover"
        :src="url"
        :show-loading="true"
        :style="locked ? { filter: 'brightness(30%)' } : { filter: 'brightness(100%)' }"
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #0d0c1d;
        "
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>

      <div v-if="locked" class="img-lock">
        <p
          style="
            width: 100%;
            height: 20px;
            font-size: 15px;
            color: #cbcbcb;
            line-height: 20px;
            text-align: center;
          "
        >
          已选用
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import { mapGetters } from "vuex";
import PubSub from "pubsub-js";
import { Event } from "@/utils/event";
import spaceApi from "@/api/space";

export default {
  name: "cloudAlbumItemComponent",
  components: {},
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      url: require("@/assets/space/110-110-zw.png"),
      locked: true,
      pubsub: [],
    };
  },
  computed: {
    ...mapGetters({
      mySpaceInfo: "space/mySpaceInfo",
      spaceInfo: "space/spaceInfo",
      isCreate: "space/isCreate",
      createCoverUrl: "space/createCoverUrl",
    }),
  },
  watch: {},
  methods: {
    initPubSub() {
      // 选择照片刷新，其他恢复
      this.pubsub[0] = PubSub.subscribe(Event.REFRESH_MYSPACE_COVER, (msg, data) => {
        // if (this.url === data.cover) {
        //   this.locked = true;
        // } else {
        //   this.locked = false;
        // }
        this.$store.dispatch("change/hidden", "chooseSpaceCover");
      });
    },
    destroyPubSub() {
      this.pubsub.forEach((element) => {
        PubSub.unsubscribe(element);
      });
    },
    async onClick() {
      if (this.locked) {
        this.$yaojingToast("已选用，请重新选择", "warning");
      } else {
        try {
          await this.$yaojingConfirm({
            title: "确认更新？",
            text: "",
            cancelButtonText: "取消",
            confirmButtonText: "确定",
          });
          // 更新
          try {
            // const res = await spaceApi.insertPlayerSpace(
            //   this.mySpaceInfo.id,
            //   this.mySpaceInfo.shopId,
            //   this.mySpaceInfo.templateId,
            //   this.url,
            //   this.mySpaceInfo.spaceName,
            //   this.mySpaceInfo.templateData,
            //   this.mySpaceInfo.isPublic,
            // );
            // this.locked = true;
            // 刷新自定义空间数据
            // const res1 = await spaceApi.getInfo(this.mySpaceInfo.id);
            // this.$store.commit("space/mySpaceInfo", res1);
            PubSub.publish(Event.REFRESH_MYSPACE_COVER, { cover: this.url });
          } catch (error) {
            throw new Error(error);
          }
        } catch (error) {
          console.log("🚀 -> onClick -> error", error);
        }
      }
    },
    spaceIsUsed(url) {
      let coverUrl;
      // 修改空间
      if (this.isCreate) {
        // coverUrl = this.mySpaceInfo.imagePath;
        coverUrl = this.createCoverUrl;
        // console.log("🚀 -> spaceIsUsed -> coverUrl", coverUrl);
      } else {
        coverUrl = this.mySpaceInfo.coverImage;
      }

      if (url === coverUrl) {
        this.locked = true;
      } else {
        this.locked = false;
      }
    },
    // 初始化数据
    initValue(arg) {
      if (arg.imagePath != "" && arg.imagePath != null) this.url = arg.imagePath;
      // this.url = require("@/assets/space/test4.png");
      this.spaceIsUsed(this.url);
    },
  },
  created() {
    this.initPubSub();
  },
  mounted() {
    this.initValue(this.value);
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.destroyPubSub();
  },
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/themes.scss";

.space-cover-item-page {
  position: relative;
  width: 100%;
  height: 100%;
  // padding-bottom: 10px;
}

.cloud-album-item-img {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0;
  background-color: $--color_primary_1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ::v-deep .van-image__loading {
    background-color: $--color_primary_1;
  }
  ::v-deep .van-image__error {
    background-color: $--color_primary_1;
  }
}

.cloud-album-item-title {
  // position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  span:nth-child(1) {
    font-size: $--font_size_28;
    color: $--color_text_2;
  }
}

.img-lock {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
}
</style>
