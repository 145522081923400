/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* jshint esversion: 6 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Global from "@/assets/global";
import "@/assets/global.scss";
import imagesLoaded from "imagesloaded";
import "animate.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "@/utils/vueBridge";
import { makeQuestEvent, clearCurRewardAction } from "@/utils/taskfactory.js";
import vuescroll from "vuescroll/dist/vuescroll-slide";
import { landConfig } from "@/utils/landBaseInfo";
import dispatcher from "@/utils/dispatcher";
import "@vant/touch-emulator";
import md5 from "js-md5";
// import VConsole from "vconsole";
// new VConsole();
// process.env.NODE_ENV === "development" && new VConsole();

import "@/utils/permission.js";

import preview from "vue-photo-preview";
import "vue-photo-preview/dist/skin.css";
var option = {
  maxSpreadZoom: 3, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  fullscreenEl: false, //控制是否显示右上角全屏按钮
  closeEl: false, //控制是否显示右上角关闭按钮
  tapToClose: true, //点击滑动区域应关闭图库
  shareEl: false, //控制是否显示分享按钮
  zoomEl: false, //控制是否显示放大缩小按钮
  counterEl: false, //控制是否显示左上角图片数量按钮
  arrowEl: true, //控制如图的左右箭头（pc浏览器模拟手机时）
  tapToToggleControls: true, //点击应切换控件的可见性
  clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时
};

Vue.prototype.$global = Global;

Vue.prototype.$imagesLoaded = imagesLoaded;
Vue.prototype.$md5 = md5;
import {
  Toast,
  Notify,
  Dialog,
  Button,
  Tabs,
  Tab,
  Icon,
  Steps,
  Step,
  NavBar,
  Popup,
  Divider,
  List,
  Cell,
  Loading,
  Overlay,
  Col,
  Row,
  Swipe,
  SwipeItem,
  Lazyload,
  Field,
  Search,
  Tag,
  Skeleton,
  DropdownMenu,
  DropdownItem,
  RadioGroup,
  Radio,
  Progress,
  Image as VanImage,
  ImagePreview,
} from "vant";
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Icon);
Vue.use(Steps);
Vue.use(Step);
Vue.use(NavBar);
Vue.use(Popup);
Vue.use(Divider);
Vue.use(List);
Vue.use(Cell);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Search);
Vue.use(Tag);
Vue.use(Skeleton);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VanImage);
Vue.use(ImagePreview);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Progress);
Vue.use(preview, option);

import "@/components/global";

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.prototype.$version = process.env.VUE_APP_VERSION;
Vue.prototype.$ossUrl = process.env.VUE_APP_OSS_URL;
Vue.prototype.$nodeEnv = process.env.NODE_ENV;
Vue.prototype.$landConfig = landConfig;

Vue.prototype.$getters = store.getters;
// eslint-disable-next-line no-undef
Vue.prototype.$desktop = device.desktop();
// eslint-disable-next-line no-undef
Vue.prototype.$mobile = device.mobile();
// eslint-disable-next-line no-undef
Vue.prototype.$tablet = device.tablet();

// 任务钩子
Vue.prototype.$makeQuestEvent = makeQuestEvent;
Vue.prototype.$clearCurRewardAction = clearCurRewardAction;

Vue.use(vuescroll, {
  ops: {
    vuescroll: {
      mode: "slide",
      locking: true,
      zooming: false,
      detectResize: true,
      scroller: {
        preventDefault: false,
        preventDefaultOnMove: false,
      },
    },
    scrollPanel: {
      speed: 100,
      scrollingX: false,
    },
    bar: {
      disable: true,
    },
  },
  name: "VueScroll",
});

//font-awesome 图标
// import "font-awesome/css/font-awesome.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false;

// eslint-disable-next-line no-undef
device.onChangeOrientation((newOrientation) => {
  window.document.documentElement.setAttribute("data-direction", window.orientation + "");
  if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
    window.document.documentElement.style.setProperty("--app-height", window.innerHeight + "px");
  } else {
    window.document.documentElement.style.setProperty("--app-height", "100vh");
  }
  setTimeout(() => {
    vuescroll.refreshAll();
    // deviceOrientation(newOrientation);
    window.document.documentElement.setAttribute("data-direction", window.orientation + "");
    if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
      window.document.documentElement.style.setProperty("--app-height", window.innerHeight + "px");
    } else {
      window.document.documentElement.style.setProperty("--app-height", "100vh");
    }
  }, 100);
  store.dispatch("device/set_device_lp", newOrientation);
  dispatcher.$emit("deviceOrientation", newOrientation);
});

Vue.prototype.$trackEvent = (category = "", action = "click", opt_label = "-") => {
  category && _hmt.push(["_trackEvent", category, action, opt_label]);
};

Vue.prototype.$trackPageview = (pageURL) => {
  _hmt.push(["_trackPageview", pageURL]);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
