import store from "../../store";
const targetUrl = {
  video: "https://obs-helf.cucloud.cn/bucket/assets/cgi_20230824_2.mp4", // 视频地址
  img: "https://obs-helf.cucloud.cn/bucket/assets/cgi_20230824_1.pic.jpg", // 图片地址
  live: "https://live.vhall.com/v3/lives/watch/185308543", // 直播地址
};
export default (type) => {
  switch (type) {
    case "video": // 打开视频
      store.dispatch("change/show", {
        name: "afterActionPlayVideo",
        params: { url: targetUrl.video },
      });
      break;
    case "live": // 打开直播
      window.open(targetUrl.live, "_blank"); // 有左右返回图标
      // location.replace("https://live.vhall.com/v3/lives/watch/650130572"); // 回不去元宇宙
      break;
    case "img": // 打开图片（长图）
      store.dispatch("change/show", {
        name: "openLandImg",
        params: { imgUrl: targetUrl.img },
      });
      break;
    default:
      console.log("CGI活动类型错误");
      break;
  }
};
