/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* jshint esversion: 11 */
import { shopQuestList, everydayAndNewbee, receiveQuest } from "@/api/quest";
import _array from "lodash/array";
/**
 * 所有提示
 */
const state = {
  everydayQuests: [],
  doNullNumber: 0,
};

const getters = {
  everydayQuests: (state) => state.everydayQuests,
  doNullNumber: (state) => state.doNullNumber,
};

const mutations = {
  GET_HOME_QUESTS: (state, params) => {
    state.everydayQuests = params.everydayQuests || [];
    state.doNullNumber = params.everydayQuests.filter((item) => {
      return item.done == 0;
    }).length;
  },
};

const actions = {
  async get_home_quests({ commit }, data) {
    try {
      const everydays = [];
      const homeQuests = (await shopQuestList(1)) || [];
      const homeQuestLists = _array.concat(everydays, homeQuests);
      commit("GET_HOME_QUESTS", { everydayQuests: homeQuests });
      return { homeQuests: homeQuests };
    } catch (error) {
      console.error(error);
      commit("GET_HOME_QUESTS", { everydayQuests: [] });
      throw "error";
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
